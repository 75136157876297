import React from 'react';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import {Box} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import HighlightBox from '../../chart-types/highlight-box/HighlightBox';
import {ReactComponent as ThumbUp} from '../../svg/thumbs-up.svg';
import {ReactComponent as SpeechBubble} from '../../svg/speech-bubble-circle.svg';
import {ReactComponent as Share} from '../../svg/share.svg';
import {ReactComponent as User} from '../../svg/user.svg';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {SenderHighlights, WorkspaceHighlights} from '../../schema';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import moment from 'moment-timezone';
import {useWorkspaceHighlightsQuery} from './WorkspaceHighlightsQuery';
import {WorkspaceHighlightsReport} from '../../report-generation/reports/WorkspaceHighlightsReport';
import {generateNumericDates} from '../DateUtils';

interface Props {
  senderId: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    values: {
      display: 'flex',
    },
    highlightBox: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    }
  }));

export default function WorkspaceHighlightsCard({senderId}: Props) {
  const classes = useStyles();
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = useWorkspaceHighlightsQuery(senderId, fromDay, toDay, true);
  const {reportData} = useReportDataContext();
  const senderHighlights: SenderHighlights | undefined = data?.senderHighlights;
  const workspaceHighlights: WorkspaceHighlights | undefined = data?.workspaceHighlights;

  const helpText = {
    what: `Shows the number of reactions, comments and shares, which happened to content related to this community in the configured time range.`,
    how: 'All reactions, comments and shares which happened in the configured time range are counted. Important is not, when the content was created, but when the reaction, comment or share was made.',
  };

  const startDateFormatted = startDate.format(moment.localeData().longDateFormat('LL'));
  const endDateFormatted = endDate.format(moment.localeData().longDateFormat('LL'));

  const helpTextLikes = `Content related to this community has been reacted to ${data?.senderHighlights?.likes.total} times
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const helpTextComments = `Content related to this community was commented ${data?.senderHighlights?.comments.total} times
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const helpTextShares = `Content related to this community was shared ${data?.senderHighlights?.shares.total} times
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const helpTextMembers = `Active members in this community.`;

  reportData.senderHighlights = senderHighlights && workspaceHighlights ? {
    likes: senderHighlights.likes,
    comments: senderHighlights.comments,
    shares: senderHighlights.shares,
    members: workspaceHighlights.members,
  } as WorkspaceHighlightsReport : undefined;
  reportData.senderHighlightsLoading = loading;

  return (
    <CoyoCard id={'sender-highlights-card'} helpText={helpText} title={'Highlights'} loading={loading} reload={refetch} filterTimeRange={generateNumericDates(startDate, endDate)}>
      <Box className={classes.values}>
        <HighlightBox loading={loading} title={'Reactions'} data={senderHighlights?.likes} icon={<ThumbUp/>}
                      valueType={'number'} helpText={helpTextLikes} className={classes.highlightBox}/>
        <HighlightBox loading={loading} title={'Comments'} data={senderHighlights?.comments} icon={<SpeechBubble/>}
                      valueType={'number'} helpText={helpTextComments} className={classes.highlightBox}/>
        <HighlightBox loading={loading} title={'Shares'} data={senderHighlights?.shares} icon={<Share/>}
                      valueType={'number'} helpText={helpTextShares} className={classes.highlightBox}/>
        <HighlightBox loading={loading} title={'Members'} data={workspaceHighlights?.members} icon={<User/>}
                      valueType={'number'} helpText={helpTextMembers} className={classes.highlightBox}/>
      </Box>
    </CoyoCard>
  );
}
