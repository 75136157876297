import React, {useContext} from 'react';
import {
  Adoption,
  Audience,
  ContentInsightsHistogram,
  ContentInsightsMetadata,
  ContentPerformance,
  CreatedContent,
  CurrentUserActivity,
  Highlights,
  Histogram,
  LandingPage,
  SearchAnalytics,
  Sender,
  SenderActivity,
  SenderHighlights,
  SenderInfo,
  UserActivityMap,
} from '../schema';
import {WorkspaceHighlightsReport} from '../report-generation/reports/WorkspaceHighlightsReport';
import {VisitReport} from '../report-generation/reports/VisitsReport';

export interface IReportDataContext {
  reportData: ReportData;
}

export interface ReportData {
  adoption?: Adoption;
  adoptionLoading: boolean;
  audience?: Audience;
  audienceLoading: boolean;
  contentInsightsHistogram?: ContentInsightsHistogram;
  contentInsightsHistogramLoading: boolean;
  contentInsightsMetadata?: ContentInsightsMetadata;
  contentInsightsMetadataLoading: boolean;
  contentPerformance?: ContentPerformance[];
  contentPerformanceLoading: boolean;
  consumingUsers?: Histogram;
  createdContent?: CreatedContent;
  createdContentLoading: boolean;
  currentUserActivity?: CurrentUserActivity;
  currentUserActivityLoading: boolean;
  highlights?: Highlights;
  highlightsLoading: boolean;
  interactingUsers?: Histogram;
  senderActivity?: SenderActivity;
  senderActivityLoading: boolean;
  senderHighlights?: SenderHighlights | WorkspaceHighlightsReport;
  senderHighlightsLoading: boolean;
  sender?: Sender[];
  senderLoading: boolean;
  landingPage?: LandingPage[];
  landingPageLoading: boolean;
  searchAnalytics?: SearchAnalytics;
  searchAnalyticsLoading: boolean;
  senderInfo: SenderInfo;
  senderInfoLoading: boolean;
  userActivityLoading: boolean;
  userActivityMap?: UserActivityMap;
  userActivityMapLoading: boolean;
  visits?: VisitReport[];
  visitsLoading: boolean;
}

export const REPORT_DATA_DEFAULT_VALUE: IReportDataContext = {
  reportData: {
    adoption: {} as Adoption,
    adoptionLoading: false,
    audience: {} as Audience,
    contentInsightsHistogram: {} as ContentInsightsHistogram,
    contentInsightsHistogramLoading: false,
    contentInsightsMetadata: {} as ContentInsightsMetadata,
    contentInsightsMetadataLoading: false,
    audienceLoading: false,
    consumingUsers: {} as Histogram,
    contentPerformance: [] as ContentPerformance[],
    contentPerformanceLoading: false,
    createdContent: {} as CreatedContent,
    createdContentLoading: false,
    currentUserActivity: {} as CurrentUserActivity,
    currentUserActivityLoading: false,
    highlights: {} as Highlights,
    highlightsLoading: false,
    interactingUsers: {} as Histogram,
    senderActivity: {} as SenderActivity,
    senderActivityLoading: false,
    senderHighlights: {} as SenderHighlights | WorkspaceHighlightsReport,
    senderHighlightsLoading: false,
    sender: [] as Sender[],
    senderLoading: false,
    landingPage: [] as LandingPage[],
    landingPageLoading: false,
    searchAnalytics: {} as SearchAnalytics,
    searchAnalyticsLoading: false,
    senderInfo: {} as SenderInfo,
    senderInfoLoading: false,
    userActivityLoading: false,
    userActivityMap: {} as UserActivityMap,
    userActivityMapLoading: false,
    visits: [] as VisitReport[],
    visitsLoading: false,
  },
};

export const ReportDataContext = React.createContext<IReportDataContext>(REPORT_DATA_DEFAULT_VALUE);

export const useReportDataContext = () => useContext(ReportDataContext);
