import React, {ReactChild, ReactElement} from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import CoyoTableShowMoreButton from '../table-show-more-button/CoyoTableShowMoreButton';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
      fontSize: 15,
      padding: theme.spacing(2, 2),

      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,

      '&:first-child': {
        paddingLeft: theme.spacing(3),
      },

      '&:last-child': {
        paddingRight: theme.spacing(3),
      },
    },

    '& .MuiTableRow-root': {
      '@media print': {
        pageBreakInside: 'avoid',
        pageBreakAfter: 'auto',
      },
      '&:last-child': {
        borderBottomStyle: 'none',
      },
    },

    '& .MuiTableCell-body': {
      fontSize: 15,
      padding: theme.spacing(2),

      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,

      '&:first-child': {
        paddingLeft: theme.spacing(3),
      },

      '&:last-child': {
        borderRightStyle: 'none',
        paddingRight: theme.spacing(3),
      },
    },
  },
  emptyRow: {
    textAlign: 'center',
    padding: theme.spacing(3),

    '@media print': {
      display: 'none',
    },
  },
  root: {},
}));

interface Props {
  headerCells: ReactElement[],
  children: ReactChild,
  loading: boolean,
  skeletonRow: ReactElement,
  skeletons: number,
  isEmpty: boolean,
  showShowMoreButton: boolean,
  onShowMoreClick: () => void,
}

export default function CoyoTable({
                                    headerCells,
                                    children,
                                    loading,
                                    skeletonRow,
                                    skeletons,
                                    isEmpty,
                                    showShowMoreButton,
                                    onShowMoreClick,
                                  }: Props) {
  const classes = useStyles();

  function renderShowMoreButton() {
    return (
      <CoyoTableShowMoreButton onClick={onShowMoreClick}/>
    );
  }

  function renderPlaceholder(message: string, id: string) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={headerCells.length}>
            <Typography className={classes.emptyRow} data-testid={id}>
              {message}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>);
  }

  function renderLoadingRows(rows: number) {
    return (
      <TableBody>
        {new Array(rows).fill(null).map((_, i) => (
          <React.Fragment key={i}>
            {skeletonRow}
          </React.Fragment>
        ))}
      </TableBody>);
  }

  return (
    <Box className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerCells}
          </TableRow>
        </TableHead>
        {!loading && isEmpty && renderPlaceholder('Sorry, no content matches your filter', 'empty-message')}
        {(!loading && !isEmpty) && children}
        {loading && renderLoadingRows(skeletons)}
      </Table>
      {showShowMoreButton && renderShowMoreButton()}
    </Box>
  );
}
