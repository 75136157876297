import React, {useEffect} from 'react';
import {Box, IconButton, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useContentInsightsHistogramQuery} from '../ContentInsightsHistogramQuery';
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom';
import PlaceholderText from '../../placeholder/PlaceholderText';
import {useSideNavContext} from '../../side-navigation/SideNavContext';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useLayoutContext} from '../../layout/LayoutContext';
import {SenderTypeFrontend} from '../../types';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import ContentInsightsGraphTemplate from '../../charts/content-insights-graph/ContentInsightsGraphTemplate';
import {useContentInsightsMetadataQuery} from '../ContentInsightsMetadataQuery';
import HighlightBox from '../../chart-types/highlight-box/HighlightBox';
import {HighlightValues} from '../../schema';
import {toLocaleDateTime} from '../../charts/DateUtils';
import {ApiKeyAuthorization, useAuthContext} from '../../auth/AuthContext';
import {ReactComponent as LinkIcon} from '../../svg/media-external-link.svg';
import {HelpText} from '../../coyo-components/help-box/HelpText';
import {useFeatureToggleContext} from '../../feature-toggle/FeatureToggleContext';
import {FeatureToggle} from '../../feature-toggle/feature';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 40,

      '&:last-child': {
        marginBottom: 0,
      },

      '& > *': {
        flex: 1,
        marginRight: 40,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    highlights: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(3, 0, 5),
    },
    highlightBoxes: {
      textAlign: 'left',
      padding: '24px',
      margin: theme.spacing(0, 1),
      borderRadius: 8,
      border: 'solid 1px',
      borderColor: theme.palette.background.paper,
      backgroundColor: theme.palette.type === 'light' ? '#FFFFFF' : theme.palette.background.paper,
      '&:first-child': {
        marginLeft: 0,
      },

      '&:last-child': {
        marginRight: 0,
      },
    },
    timestamps: {
      marginBottom: 10,
    },
    timestamp: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      padding: '4px 8px',
      color: theme.palette.text.secondary,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      textTransform: 'uppercase',
      '&:first-child': {
        marginLeft: 0,
      },
    },
    visitsCard: {
      flex: 1,
    },
    audienceCard: {
      flex: 1,
    },
    titleLink: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
      '&:link': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
      '&:visited': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
      '&:active': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },
    },
    svgIcon: {
      width: 20,
      height: 20,
    },
    '@media print': {
      overviewRedirect: {
        display: 'none',
      },
    },
    '@media only screen and (max-width: 1000px)': {
      visitsCard: {
        flexBasis: '100%',
        marginRight: 0,
        marginBottom: theme.spacing(5),
      },
    },
  }),
);

export default function ContentInsightsDashboard() {
  const {
    senderId,
    senderType,
    contentId,
  } = useParams<{ senderId: string, senderType: SenderTypeFrontend | 'sender', contentId: string }>();
  const {search} = useLocation();
  const {setSenderId} = useAuthContext() as ApiKeyAuthorization;
  const {isDisabledFeature} = useFeatureToggleContext();
  useEffect(() => {
    if (!!senderId && !!setSenderId)
      setSenderId(senderId);
  }, [setSenderId, senderId]);
  const classes = useStyles();
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {
    loading: metadataLoading,
    data: metadata,
    refetch: refetchMetadata,
  } = useContentInsightsMetadataQuery(contentId, senderId);
  const {
    loading: histogramLoading,
    data: histogram,
    refetch: refetchHistogram,
  } = useContentInsightsHistogramQuery(contentId, fromDay, toDay, senderId);

  const title = metadata?.contentInsights?.metadata?.title;
  const creationTime = metadata?.contentInsights?.metadata?.creationTime;
  const publicationTime = metadata?.contentInsights?.metadata?.publicationTime;
  const updatedTime = metadata?.contentInsights?.metadata?.updatedTime;
  const link = metadata?.contentInsights?.metadata?.link;
  const type = metadata?.contentInsights?.metadata?.type;
  const likesMetadata: HighlightValues = {total: metadata?.contentInsights?.metadata?.likes || 0};
  const commentsMetadata: HighlightValues = {total: metadata?.contentInsights?.metadata?.comments || 0};
  const sharesMetadata: HighlightValues = {total: metadata?.contentInsights?.metadata?.shares || 0};
  const engagementRateMetadata: HighlightValues = {total: metadata?.contentInsights?.metadata?.engagementRate || 0};
  const reachMetadata: HighlightValues = {total: metadata?.contentInsights?.metadata?.reach || 0};
  const viewsHistogram = histogram?.contentInsights?.histogram?.views;
  const uniqueViewsHistogram = histogram?.contentInsights?.histogram?.uniqueViews;
  const newUniqueViewsHistogram = histogram?.contentInsights?.histogram?.newUniqueViews;
  const viewsShareHistogram = histogram?.contentInsights?.histogram?.viewsShare;
  const uniqueViewsShareHistogram = histogram?.contentInsights?.histogram?.uniqueViewsShare;
  const newUniqueViewsShareHistogram = histogram?.contentInsights?.histogram?.newUniqueViewsShare;
  const viewsTotalHistogram = histogram?.contentInsights?.histogram?.viewsTotal;
  const uniqueViewsTotalHistogram = histogram?.contentInsights?.histogram?.uniqueViewsTotal;
  const newUniqueViewsTotalHistogram = histogram?.contentInsights?.histogram?.newUniqueViewsTotal;

  const {reportData} = useReportDataContext();
  useEffect(() => {
    reportData.contentInsightsHistogramLoading = histogramLoading;
    reportData.contentInsightsHistogram = histogram?.contentInsights?.histogram;
    reportData.contentInsightsMetadataLoading = metadataLoading;
    reportData.contentInsightsMetadata = metadata?.contentInsights?.metadata;
    // eslint-disable-next-line
  }, [histogram, metadata, histogramLoading, metadataLoading]);

  const {setMinimalisticView} = useLayoutContext();
  const {setActiveSideNavOption} = useSideNavContext();

  useEffect(() => {
    document.title = `Haiilo Intranet Analytics | ${!!title ? truncate(title, 10) : 'Content Insights'}`;

    if (senderType === 'page') {
      setActiveSideNavOption('pages');
    }
    if (senderType === 'my-page') {
      setActiveSideNavOption('my-pages');
    }
    if (senderType === 'community') {
      setActiveSideNavOption('communities');
    }
    if (senderType === 'my-community') {
      setActiveSideNavOption('my-communities');
    }
    if (senderType === 'sender') {
      setMinimalisticView(true);
    }

    // eslint-disable-next-line
  }, [setMinimalisticView, setActiveSideNavOption, senderType]);

  const interactionData = [
    {name: 'Reactions', dataByRange: histogram?.contentInsights?.histogram?.likes},
    {name: 'Comments', dataByRange: histogram?.contentInsights?.histogram?.comments},
  ];

  const viewsDataMainContent = [
    {name: 'Views', dataByRange: viewsHistogram},
    {name: 'Unique Views', dataByRange: uniqueViewsHistogram},
    {name: 'New Unique Views', dataByRange: newUniqueViewsHistogram},
  ];

  const viewsDataShare = [
    {name: 'Views', dataByRange: viewsShareHistogram},
    {name: 'Unique Views', dataByRange: uniqueViewsShareHistogram},
    {name: 'New Unique Views', dataByRange: newUniqueViewsShareHistogram},
  ];

  const viewsDataTotal = [
    {name: 'Views', dataByRange: viewsTotalHistogram},
    {name: 'Unique Views', dataByRange: uniqueViewsTotalHistogram},
    {name: 'New Unique Views', dataByRange: newUniqueViewsTotalHistogram},
  ];

  const contentTypeBeautified = type?.replace('-', ' ');

  const helpTextHighlightsLikes = `Total number of reactions for this ${contentTypeBeautified}.
  Date range and global filters do not affect this number.`;

  const helpTextHighlightsComments = `Total number of comments for this ${contentTypeBeautified}.
  Date range and global filters do not affect this number.`;

  const helpTextHighlightsShares = `Total number of shares for this ${contentTypeBeautified}.
  Date range and global filters do not affect this number.`;

  const helpTextHighlightsUnqiueViews = `Total number of different users, who have seen this ${contentTypeBeautified}
  ${type === 'timeline-item' ? '' : 'or the share of it'}.
  Date range and global filters do not affect this number.`;

  const helpTextHighlightsEngagementRate = `Average engagement rate. It is calculated with the following formula: (Reactions + (2,5 * Comments) + (5 * Shares)) / Unique Views * 100.
  Date range and global filters do not affect this number.`;

  const helpTextInteractions: HelpText = {
    what: `This histogram shows the interactions (reactions and comments) for this ${contentTypeBeautified}.`,
    how: <React.Fragment>
      <ul style={{marginTop: 0}}>
        <li>{`Reactions is the number of reactions for the content at a given time.`}</li>
        <li>{`Comments is the number of comments for the content at a given time.`}</li>
      </ul>
    </React.Fragment>,
  };

  const helpTextContent: HelpText = {
    what: `This histogram shows an overview of different types of views for this ${contentTypeBeautified}.`,
    how: <React.Fragment>
      <ul style={{marginTop: 0}}>
        <li>{`Views is the number of times this ${contentTypeBeautified} was ${type === 'timeline-item' ? 'seen on a timeline' : 'opened'}.
        Every view is counted.`}
        </li>
        <li>{`Unique Views is the number of different users who have ${type === 'timeline-item' ? 'seen' : 'opened'} this ${contentTypeBeautified}.
          Each person is counted only once.`}
        </li>
        <li>{`New Unique Views is the number of different users who have ${type === 'timeline-item' ? 'seen' : 'opened'} this ${contentTypeBeautified} for the first time at a given time.
        If they have ${type === 'timeline-item' ? 'seen' : 'opened'} this ${contentTypeBeautified} before, they are not counted.`}
        </li>
      </ul>
    </React.Fragment>,
  };

  const helpTextShare: HelpText = {
    what: `This histogram shows the Views, Unique Views and New Unique Views for the share of this ${contentTypeBeautified}.`,
    how: <React.Fragment>
      <ul style={{marginTop: 0}}>
        <li>{`Views is the number of times the share of this ${contentTypeBeautified} was seen on a timeline. Every view is counted.`}
        </li>
        <li>{`Unique Views is the number of different users who have seen the share of this ${contentTypeBeautified}. Each user is counted only once.`}
        </li>
        <li>{`New Unique Views is the number of different users who have seen the share of this ${contentTypeBeautified} for the first time at a given time.
        If they have seen this share before, they are not counted.`}
        </li>
      </ul>
    </React.Fragment>,
  };

  const helpTextCombined: HelpText = {
    what: `This histogram combines the numbers of the Views (Content) and the Views (Share) histograms.`,
    how: <React.Fragment>
      <ul style={{marginTop: 0}}>
        <li>{`Views is the sum of the views on the ${contentTypeBeautified} and the share.`}
        </li>
        <li>{`Unique Views is the number of different users who have seen either the ${contentTypeBeautified} or the share.
        Each person is counted only once.`}
        </li>
        <li>{`New Unique Views is the number of different users who have seen either the ${contentTypeBeautified} or the share for the first time at a given time.
        If they have seen either the ${contentTypeBeautified} or this share before, they are not counted.`}
        </li>
      </ul>
    </React.Fragment>,
  };

  const BackLink = ({ path, display, type }: { path: string, display: string, type: string }) => {
    return (
      <Link component={RouterLink} to={`/${path}${search}`}
        variant={'body2'}
        aria-label={`back to ${display}`}>
        ← Back to {display}
      </Link>
    )
  }

  return (
    <div data-testid="content-insights-dashboard">
      {!!senderId && !!senderType
        ? <BackLink path={`${senderType}/${senderId}`} display={senderType.replace('my-', '')} type="sender" />
        : <BackLink path="dashboard" display="dashboard" type="dashboard" />}

      <Typography variant="h1" data-testid={`title`}>
        {!!link
          ? (
            <React.Fragment>
              <Link
                className={classes.titleLink}
                tabIndex={-1}
                title={`View ${type} in COYO`}
                href={link}
                target={'_blank'}
                rel={'noopener noreferrer'}
                data-testid={'content-link'}>
                <PlaceholderText loading={metadataLoading}>
                  <span>
                    {!!title ? truncate(title, 80) : 'Content Insights'}
                  </span>
                </PlaceholderText>
              </Link>
              <IconButton aria-label={!!title ? `open ${truncate(title, 80)} in new tab` : 'open content in new tab'}
                          size={'small'}
                          title={`View ${title} in COYO`}
                          href={link}
                          target={'_blank'}
                          rel={'noopener noreferrer'}
                          data-testid={'link-button'}>
                <LinkIcon className={`${classes.svgIcon}`}/>
              </IconButton>
            </React.Fragment>)
          : (
            <PlaceholderText loading={metadataLoading}>
              {!!title ? truncate(title, 80) : 'Content Insights'}
            </PlaceholderText>
          )}
      </Typography>

      <Box className={classes.timestamps}>
        {creationTime && (
          <span data-testid={'creation-time'} className={classes.timestamp}>
          <PlaceholderText loading={metadataLoading}>Created: {toLocaleDateTime(creationTime)}</PlaceholderText>
        </span>
        )}
        {publicationTime && (
          <span data-testid={'published-time'} className={classes.timestamp}>
            <PlaceholderText loading={metadataLoading}>Published: {toLocaleDateTime(publicationTime)}</PlaceholderText>
          </span>
        )}
        {updatedTime && (
          <span data-testid={'updated-time'} className={classes.timestamp}>
          <PlaceholderText loading={metadataLoading}>Last Updated: {toLocaleDateTime(updatedTime)}</PlaceholderText>
          </span>
        )}
      </Box>

      <Box className={classes.highlights}>
        <HighlightBox data-testid={'likes'} loading={metadataLoading} title={'Reactions (total)'}
                      data={likesMetadata} className={classes.highlightBoxes}
                      valueType={'number'} helpText={helpTextHighlightsLikes}
                      reload={refetchMetadata}/>
        <HighlightBox data-testid={'comments'} loading={metadataLoading} title={'Comments (total)'}
                      data={commentsMetadata} className={classes.highlightBoxes}
                      valueType={'number'} helpText={helpTextHighlightsComments}
                      reload={refetchMetadata}/>
        <HighlightBox data-testid={'shares'} loading={metadataLoading} title={'Shares (total)'}
                      data={sharesMetadata} className={classes.highlightBoxes}
                      valueType={'number'} helpText={helpTextHighlightsShares}
                      reload={refetchMetadata}/>
        <HighlightBox data-testid={'unique-views'} loading={metadataLoading} title={'Unique views (total)'}
                      data={reachMetadata} className={classes.highlightBoxes}
                      valueType={'number'} helpText={helpTextHighlightsUnqiueViews}
                      reload={refetchMetadata}/>
        <HighlightBox data-testid={'average-engagement-rate'} loading={metadataLoading}
                      title={'Average Engagement Rate'}
                      data={engagementRateMetadata} className={classes.highlightBoxes}
                      valueType={'percent'} helpText={helpTextHighlightsEngagementRate}
                      reload={refetchMetadata}/>
      </Box>

      <Box className={classes.row}>
        <ContentInsightsGraphTemplate id={'interactions-card'} loading={histogramLoading}
                                      title={'Interactions'} data={interactionData}
                                      reload={refetchHistogram}
                                      helpText={helpTextInteractions}
                                      threshold={false}/>
      </Box>

      <Box className={classes.row}>
        <ContentInsightsGraphTemplate id={'content-views-card'} loading={histogramLoading}
                                      title={type === 'timeline-item' ? 'Views' : `Views (Article)`}
                                      data={viewsDataMainContent}
                                      reload={refetchHistogram}
                                      helpText={helpTextContent}
                                      threshold={!isDisabledFeature(FeatureToggle.SenderMinGroupSize)}/>
      </Box>

      {(!!viewsShareHistogram || !!uniqueViewsShareHistogram || !!newUniqueViewsShareHistogram) &&
      (<Box className={classes.row}>
        <ContentInsightsGraphTemplate id={'share-views-card'} loading={histogramLoading}
                                      title={'Views (Share on Timeline)'} data={viewsDataShare}
                                      reload={refetchHistogram}
                                      helpText={helpTextShare}
                                      threshold={!isDisabledFeature(FeatureToggle.SenderMinGroupSize)}/>
      </Box>)}

      {(!!viewsTotalHistogram || !!uniqueViewsTotalHistogram || !!newUniqueViewsTotalHistogram) &&
      (<Box className={classes.row}>
        <ContentInsightsGraphTemplate id={'total-views-card'} loading={histogramLoading}
                                      title={'Views (Combined)'} data={viewsDataTotal}
                                      reload={refetchHistogram}
                                      helpText={helpTextCombined}
                                      threshold={!isDisabledFeature(FeatureToggle.SenderMinGroupSize)}/>
      </Box>)}

    </div>);
}

function truncate(text: string, characters: number) {
  return (text.length > characters) ? text.substr(0, characters - 1) + '...' : text;
}
