import React from 'react';
import {Box, Button} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  bottomControls: {
    display: 'flex',
  },
  showMoreButton: {
    flex: 1,
    margin: theme.spacing(1, 3),
  },
  '@media print': {
    bottomControls: {
      height: '10px',
      display: 'none',
    },
  }
}));

interface Props {
  onClick: () => void
}

export default function CoyoTableShowMoreButton({onClick}: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.bottomControls}>
      <Button className={classes.showMoreButton}
              onClick={onClick}
              data-testid={'show-more-button'}>
        Show more
      </Button>
    </Box>);
}
