import { Box, IconButton, TableCell } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { ReactComponentElement, useState } from 'react';
import PlaceholderText from '../../placeholder/PlaceholderText';
import { ReactComponent as SortingIconDesc } from '../../svg/chevron-down.svg';
import { ReactComponent as SortingIconAsc } from '../../svg/chevron-up.svg';
import CoyoSearch from '../search/CoyoSearch';
import CoyoTooltip from '../tooltip/CoyoTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  table: {},
  headerContent: {
    display: 'flex',
    '&:hover .icon-button': {
      opacity: 1,
    },
  },
  headerTitle: {
    alignSelf: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  headerControls: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignSelf: 'center',
  },
  alignRight: {
    marginLeft: 'auto',
  },
  alignLeft: {
    marginLeft: 8,
  },
  helpIcon: {
    marginLeft: 8,
  },
  focused: {
    color: '#0073e6',
  },

  '@media print': {
    headerControls: {
      display: 'none',
    },
  },
  svgIcon: {
    width: 20,
    height: 20,
  },
  svgIconActive: {
    fill: theme.palette.action.focus,
  },
  svgIconInactive: {
    opacity: 0,
  },
}));

interface Props {
  title: string,
  sorting?: {
    column: string,
    active: boolean
    onClick: (sorting: any) => void
    defaultOrder?: SortOrder
  }
  helpText?: string | undefined,
  controls?: Controls,
  width?: number | string,
  focused?: boolean,
  search?: Search,
  loading: boolean
}

export default function CoyoTableHeaderCell({
  title,
  helpText,
  controls,
  width,
  focused,
  sorting,
  search,
  loading,
}: Props) {
  const classes = useStyles();
  const [order, setOrder] = useState<SortOrder>(sorting?.defaultOrder || 'desc');

  function renderTitle() {
    return !search && (helpText
      ? (
        <Box onClick={() => updateSortingColumn()} aria-hidden={true}>
          <CoyoTooltip title={<React.Fragment>{helpText}</React.Fragment>} placement={'top-start'}>
            <Box className={`${classes.headerTitle} ${sorting && !loading ? classes.pointer : ''}`}>
              <PlaceholderText loading={loading}>{title}</PlaceholderText>
            </Box>
          </CoyoTooltip>
        </Box>
      ) : (
        <Box className={`${classes.headerTitle} ${sorting && !loading ? classes.pointer : ''}`}
          onClick={() => updateSortingColumn()} aria-hidden={true}>
          <PlaceholderText loading={loading}>{title}</PlaceholderText>
        </Box>
      ));
  }

  const updateSortingColumn = () => {
    if (loading)
      return;

    if (sorting) {
      if (!sorting.active) {
        setOrder(order);

        sorting.onClick({
          column: sorting.column,
          order: order,
        });
      } else {
        const reversedOrder = order === 'asc' ? 'desc' : 'asc';
        setOrder(reversedOrder);

        sorting.onClick({
          column: sorting.column,
          order: reversedOrder,
        });
      }
    }
  };

  return (
    <TableCell width={width} aria-label={title} sortDirection={sorting?.active ? order : false} >
      <Box className={focused === true ? `${classes.headerContent} ${classes.focused}` : classes.headerContent}>
        {renderTitle()}
        <Box className={`${classes.headerControls}`}>
          {controls?.left}
          {!!search && (
            <CoyoSearch key={'search'}
              onChange={(newSearchTerm) => search.setSearchTerm(newSearchTerm)}
              text={search.text}
              focused={search.isFocused}
              loading={loading}
              searchExecutionOffset={search.executionOffset}
            />
          )}
        </Box>
        <Box className={`${classes.headerControls}`} style={{ visibility: loading ? 'hidden' : 'visible' }} aria-label={''}>
          {controls?.right}
          {sorting && (
            <IconButton
              className={`icon-button ${!sorting?.active && classes.svgIconInactive}`}
              key={'icon-button-sort'}
              size={'small'}
              onClick={updateSortingColumn}
              data-testid={`sort-button-${sorting.column}`}
              aria-label={sorting.active ?
                `Sort ${title} in ${order === 'asc' ? 'desc' : 'asc'}ending order` :
                `Sort ${title} in ${order}ending order`}>
              {sorting.active && order === 'asc' &&
                <SortingIconAsc className={`${classes.svgIcon} ${classes.svgIconActive}`} />}
              {sorting.active && order === 'desc' &&
                <SortingIconDesc className={`${classes.svgIcon} ${classes.svgIconActive}`} />}
              {!sorting.active && order === 'desc' &&
                <SortingIconDesc className={`${classes.svgIcon}`} />}
              {!sorting.active && order === 'asc' &&
                <SortingIconAsc className={`${classes.svgIcon}`} />}
            </IconButton>
          )}
        </Box>
      </Box>
    </TableCell>
  );
}

interface Controls {
  left?: ReactComponentElement<any>[],
  right?: ReactComponentElement<any>[]
}

interface Search {
  text: string;
  isFocused: boolean;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  executionOffset?: number;
}

export type SortOrder = 'asc' | 'desc';
