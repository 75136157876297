
export enum FeatureToggle {
  Search = 'search',
  LandingPages = 'landing_pages',
  ConsolidatedDashboard = 'consolidated_dashboard',
  SenderMinGroupSize = 'sender_min_group_size',
}

export const FEATURES = Object.values(FeatureToggle);

export type Feature = typeof FEATURES[number];
