import {Bar} from './Bars';
import {secondsToHms} from '../../charts/DateUtils';

export function formatBarValue(bar: Bar): string {
  if (bar.dataType === 'percent')
    return `${bar.value.toLocaleString()}%`;

  if (bar.dataType === 'seconds-to-duration')
    return secondsToHms(bar.value);

  if (bar.dataType === 'amount')
    return `${bar.value.toLocaleString()}x`;

  return bar.value?.toLocaleString() || '-';
}

export function isBarFocusedOrDefaultStyle(value: boolean | undefined) {
  return value || value === undefined;
}