import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import {ActivityValue, MapSeries, UserActivityMap} from '../../schema';
import {Box} from '@material-ui/core';
import {HelpText} from '../../coyo-components/help-box/HelpText';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useUserActivityMapQuery} from './UserActivityMapQuery';
import Heatmap, {HeatmapDatapoint, HeatmapSeries} from '../../chart-types/heatmap/Heatmap';
import moment from 'moment-timezone';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {ChartOptions} from 'chart.js';
import {generateNumericDates} from '../DateUtils';
import {useFeatureToggleContext} from '../../feature-toggle/FeatureToggleContext';
import { FeatureToggle } from '../../feature-toggle/feature';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      flex: 3,
    },
    chart: {
      padding: theme.spacing(1, 2, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flex: 10,
    },
    tooltip: {
      textAlign: 'center',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: 'none',
    },
  }));

interface Props {
  className?: string;
}

const helpText: HelpText = {
  what: 'The user activity map is a graphical overview that shows how many unique users are active.',
  how: `A user is counted as active, if he/she/they used COYO for at least once in a specific hour for
  more than 3 seconds. Color shades visualise the user activity.`,
  important: `For time spans exceeding one week, average values are displayed. Days that have not yet been completed are
   also included in the calculation of the average. This results in a distorted representation of the current day.`,
};

export default function UserActivityMapCard({className}: Props): ReactElement {
  const classes = useStyles();
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = useUserActivityMapQuery(fromDay, toDay);
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {reportData} = useReportDataContext();
  const userActivityMap: UserActivityMap | undefined = data?.userActivityMap;
  const title = 'User Activity Map';
  const {isDisabledFeature} = useFeatureToggleContext();

  reportData.userActivityMap = userActivityMap;
  reportData.userActivityMapLoading = loading;

  // [...userActivityMap.data] needs the spread syntax to prevent a type error in sortDays()
  const userActivityMapData: MapSeries[] = !!userActivityMap?.data && userActivityMap?.data?.length > 0 ? [...userActivityMap.data] : initEmptyUserActivityMap().data;
  sortDays(userActivityMapData);
  const heatmapData: HeatmapSeries[] = getHeatmapData(userActivityMapData);

  const maxValue = userActivityMap ?
    Math.max(...heatmapData?.map(data => data.v)) : 1;

  return (
    <CoyoCard id={'user-activity-map-card'}
              title={title}
              className={className}
              helpText={helpText}
              loading={loading}
              filtered={appliedGlobalFilters.length > 0}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              reload={refetch}>
      <Box className={classes.chart}>
        <Heatmap series={heatmapData}
                 range={25}
                 maxValue={maxValue}
                 height={350}
                 width={'100%'}
                 customTooltip={generateTooltip}
                 customXLabels={getTimeLabel}
                 customYLabels={getDayname}
                 loading={loading}
                 threshold={!isDisabledFeature(FeatureToggle.SenderMinGroupSize)}/>
      </Box>
    </CoyoCard>
  );

  function initEmptyUserActivityMap(): UserActivityMap {
    let userActivityMap = {data: [] as MapSeries[]} as UserActivityMap;
    const firstDayOfWeek = moment.localeData().firstDayOfWeek();
    for (let weekday = firstDayOfWeek; weekday < firstDayOfWeek + 7; weekday++) {
      let hours = [] as ActivityValue[];
      for (let hour = 0; hour < 24; hour++) {
        hours.push({
          time: hour,
          averageUsers: 0,
        });
      }
      userActivityMap.data.push({
        weekday: (weekday % 7),
        data: hours,
      });
    }

    return userActivityMap;
  }

  function generateTooltip(): ChartOptions {
    const ROUND_FACTOR = 10;
    return {
      plugins: {
        tooltip: {
          enabled: true,
          usePointStyle: true,
          callbacks: {
            title: (tooltipItem) => `${getTimeLabel(+tooltipItem[0].label)} ${getDayname(+tooltipItem[0].formattedValue, true)}`,
            label: tooltipItem => {
              return `∅ active users: ${Math.round((tooltipItem.raw as HeatmapSeries).v * ROUND_FACTOR) / ROUND_FACTOR}`;
            },
            labelPointStyle: (tooltipItem) => {
              const rectangle = document.createElement('canvas');
              const ctx = rectangle.getContext('2d');

              if (!!ctx) {
                ctx.canvas.width = 12;
                ctx.canvas.height = 12;
                ctx.fillStyle = (tooltipItem.raw as HeatmapDatapoint).backgroundColor;
                ctx.fillRect(0, 0, 12, 12);
              }

              return {
                pointStyle: rectangle,
                rotation: 0,
              }
            },
          },
        },
      },
    };
  }

  function sortDays(userActivityMapData: MapSeries[]): MapSeries[] {
    const firstDayOfWeek = moment.localeData().firstDayOfWeek();
    while (userActivityMapData[0].weekday !== firstDayOfWeek) {
      const firstElement: MapSeries | undefined = userActivityMapData.shift();
      if (firstElement) userActivityMapData.push(firstElement);
    }
    return userActivityMapData;
  }
}

export function getHeatmapData(data: MapSeries[]): HeatmapSeries[] {
  return data.flatMap((value, index) => {
    return value.data.map(data => {
      return {
        x: data.time,
        y: index,
        v: data.averageUsers,
      };
    });
  });
}

export function getTimeLabel(hour: number): string {
  return moment.tz().hour(hour).minute(0).format('LT');
}

export function getDayname(weekdayNumber: number, long?: boolean ): string {
  return long
    ? moment.tz().weekday(weekdayNumber).format('dddd')
    : moment.tz().weekday(weekdayNumber).format('ddd');
}
