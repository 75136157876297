import React, { useState } from 'react';
import LineChart, { Group, Series } from '../../chart-types/line-chart/LineChart';
import { useDateRangeContext } from '../../date-range-selection/DateRangeContext';
import { usePageActivityQuery } from './SenderActivityQuery';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import { Box, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useReportDataContext } from '../../report-data/ReportDataContext';
import { SenderTypeFrontend } from '../../types';
import { useGlobalFiltersContext } from '../../global-filters/GlobalFiltersContext';
import { generateNumericDates } from '../DateUtils';
import { useFeatureToggleContext } from '../../feature-toggle/FeatureToggleContext';
import { FeatureToggle } from '../../feature-toggle/feature';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&:focus': {
        background: theme.palette.action.hover,
      },
    },
    chart: {
      padding: '8px',
    },
  }));

interface Props {
  senderId: string,
  senderType: SenderTypeFrontend,
}

export default function SenderActivityCard({ senderId, senderType }: Props) {
  const classes = useStyles();
  const [group, setGroup] = useState<Group>('day');
  const { startDate, endDate } = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const { loading, data, refetch } = usePageActivityQuery(senderId, fromDay, toDay, true);
  const { appliedGlobalFilters } = useGlobalFiltersContext();
  const { reportData } = useReportDataContext();
  const { isDisabledFeature } = useFeatureToggleContext();
  const { senderActivity } = data;
  const title = senderType === 'page' ? 'Page Activity' : 'Community Activity';

  const helpText = {
    what: 'Shows the number of visitors, consuming users and interacting users in the configured time range.',
    how: <React.Fragment>
      {`Visitors is the total number of unique users, who have opened the ${senderType} in the time range.`}<br />
      {`Visits is the total number of times the ${senderType} has been opened by the visitors in the time range. `}<br />
      {`Consuming users is the number of unique users, who have seen content related to the ${senderType} in the configured date range.`}<br />
      {`Interacting users is the number of consuming users, who have been interacting with content related to the ${senderType} at
      least once.`}<br />
      The chart can be configured to group the activity by days, weeks or months.
    </React.Fragment>,
    important: 'The number of consuming and interacting users can be higher than the number of visitors. ' +
      `The reason for this is that ${senderType} content is often not consumed on the page itself but somewhere else, such as timelines of other users, pages or communities.`,
  };

  reportData.senderActivity = senderActivity;
  reportData.senderActivityLoading = loading;

  const series: Series[] = [{
    name: 'Consuming users',
    dataByRange: senderActivity.consumingUsers,
  }, {
    name: 'Interacting users',
    dataByRange: senderActivity.interactingUsers,
  }, {
    name: 'Visitors',
    dataByRange: senderActivity.visitors,
  }, {
    name: 'Visits',
    dataByRange: senderActivity.visits,
  }];

  return (
    <CoyoCard id={'page-activity-card'}
      title={title}
      loading={loading}
      helpText={helpText}
      filtered={appliedGlobalFilters.length > 0}
      filterTimeRange={generateNumericDates(startDate, endDate)}
      controls={(
        <CoyoSelect SelectProps={{
          value: group,
          onChange: ({ target }) => setGroup(target.value as Group),
        }}
          label={`Select ${title} in time range`}>
          <MenuItem className={classes.menuItem} value={'day'}
            disabled={group === 'day'}><span>Day</span></MenuItem>
          <MenuItem className={classes.menuItem} value={'week'}
            disabled={group === 'week'}><span>Week</span></MenuItem>
          <MenuItem className={classes.menuItem} value={'month'} disabled={group === 'month'}><span>Month</span></MenuItem>
        </CoyoSelect>
      )}
      reload={refetch}>
      <Box className={classes.chart}>
        <LineChart series={series} group={group} loading={loading} threshold={!isDisabledFeature(FeatureToggle.SenderMinGroupSize)} />
      </Box>
    </CoyoCard>
  );
}
