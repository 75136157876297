import {CognitoUser, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Auth} from '@aws-amplify/auth';

Auth.configure({
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  authenticationFlowType: (process.env.REACT_APP_IS_MFA_ACTIVE || 'false').toLowerCase() === 'true' ? 'CUSTOM_AUTH' : 'USER_SRP_AUTH',
});

export type SuccessfulLoginResult = { result: 'SUCCESS', session: CognitoUserSession };
export type ForceChangePasswordLoginResult = { result: 'PASSWORD_CHANGE_REQUIRED', cognitoUser: CognitoUser };
export type CustomChallenge = { result: 'CUSTOM_CHALLENGE', cognitoUser: CognitoUser };
export type LoginResult = SuccessfulLoginResult | ForceChangePasswordLoginResult | CustomChallenge;

export class CognitoClient {
  private readonly userPool: CognitoUserPool;

  constructor() {
    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_CLIENT_ID || '',
    });
  }

  async login(username: string, password: string): Promise<LoginResult> {
    const cognitoUser = await Auth.signIn({username: username, password});

    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED')
      return {result: 'PASSWORD_CHANGE_REQUIRED', cognitoUser: cognitoUser} as ForceChangePasswordLoginResult;

    if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE')
      return {result: 'CUSTOM_CHALLENGE', cognitoUser: cognitoUser} as CustomChallenge;

    return {result: 'SUCCESS', session: cognitoUser.Session};
  }

  changePassword(cognitoUser: CognitoUser, newPassword: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, null, {
        onSuccess: () => resolve(),
        onFailure: err => reject(err),
      });
    });
  }

  logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser();
      if (!currentUser)
        return reject();

      currentUser.getSession(() => {
        currentUser.globalSignOut({
          onSuccess: () => {
            resolve();
          },
          onFailure: () => {
            currentUser.signOut();
            reject();
          },
        });
      });
    });
  }

  async getOrRefreshSession(): Promise<CognitoUserSession> {
    const session = await this.getSession();
    if (session.isValid())
      return session;

    return this.refreshSession(session);
  }

  private getSession(): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser();
      if (!currentUser)
        return reject();

      currentUser.getSession(async (err: any, session: CognitoUserSession) => {
        if (err)
          return reject();

        resolve(session);
      });
    });
  }

  private refreshSession(session: CognitoUserSession): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser();
      if (!currentUser)
        return reject();

      const refreshToken = session.getRefreshToken();
      currentUser.refreshSession(refreshToken, (err, session) => {
        if (err)
          return reject();

        return resolve(session);
      });
    });
  }
}
