import {Workbook} from 'exceljs';
import {CreatedContent, Histogram} from '../../schema';
import {generateIsoDates, toLocaleDate} from '../../charts/DateUtils';
import {capitalizeFirstLetter, getValueFromTimeValues} from './ReportUtils';
import {Moment} from 'moment-timezone';

export function addCreatedContentReport(workbook: Workbook, createdContentData: CreatedContent, startDate: Moment, endDate: Moment) {
  addCreatedContentHighlightsSheet(workbook, createdContentData);
  addCreatedContentHistogramSheet(workbook, 'day', createdContentData, startDate, endDate);
  addCreatedContentHistogramSheet(workbook, 'week', createdContentData, startDate, endDate);
  addCreatedContentHistogramSheet(workbook, 'month', createdContentData, startDate, endDate);
}

function addCreatedContentHighlightsSheet(workbook: Workbook, createdContentData: CreatedContent) {
  const worksheet = workbook.addWorksheet('Created Content (Highlights)');
  worksheet.columns = [
    {header: 'Content Type', width: 20},
    {header: 'Value', width: 10},
    {header: 'Trend', width: 10, style: {numFmt: '0.00%'}},
  ];
  const {blogArticles, timelineItems, wikiArticles} = createdContentData.highlights;
  worksheet.addRow(['Timeline Items', timelineItems.total, timelineItems.trendPercent ? timelineItems.trendPercent / 100 : ""]);
  worksheet.addRow(['Blog Articles', blogArticles.total, blogArticles.trendPercent ? blogArticles.trendPercent / 100 : ""]);
  worksheet.addRow(['Wiki Articles', wikiArticles.total, wikiArticles.trendPercent ? wikiArticles.trendPercent / 100 : ""]);

  worksheet.autoFilter = 'A1:C1';
}

function addCreatedContentHistogramSheet(workbook: Workbook, grouping: keyof Histogram, createdContentData: CreatedContent, startDate: Moment, endDate: Moment) {
  const groupingFormatted = capitalizeFirstLetter(grouping);
  const worksheet = workbook.addWorksheet(`Created Content (Histogram ${groupingFormatted})`);
  worksheet.columns = [
    {header: 'Date', width: 20},
    {header: 'Text', width: 10},
    {header: 'Images', width: 10},
    {header: 'Videos', width: 10},
    {header: 'Files', width: 10},
  ];
  const isoDates = generateIsoDates(startDate, endDate, grouping);
  const {text, images, videos, files} = createdContentData.histogram;
  isoDates.forEach(isoDate => {
    worksheet.addRow([
      toLocaleDate(isoDate),
      getValueFromTimeValues(text, grouping, isoDate),
      getValueFromTimeValues(images, grouping, isoDate),
      getValueFromTimeValues(videos, grouping, isoDate),
      getValueFromTimeValues(files, grouping, isoDate)
    ]);
  });
  worksheet.autoFilter = 'A1:E1';
}
