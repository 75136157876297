import React, {useContext, Dispatch, SetStateAction} from 'react';
import {LoginResult} from './CognitoClient';
import {CognitoUser} from 'amazon-cognito-identity-js';

export interface CognitoAuthorization {
  type: 'cognito';
  isAuthorized: boolean;

  refreshSessionIfNeeded(): Promise<void>;

  login(username: string, password: string): Promise<LoginResult>;

  enterMfa(user: CognitoUser, code: string): Promise<LoginResult>;

  logout(): Promise<void>;

  changePassword(cognitoUser: CognitoUser, newPassword: string): Promise<void>;

  getJwtToken(): string | null;
}

export interface ApiKeyAuthorization {
  type: 'api-key';
  apiKey: string | null;
  dataAccessKey: string | null;
  isAuthorized: boolean;
  senderId?: string;
  setSenderId?: Dispatch<SetStateAction<string | undefined>>;
}

export interface UndefinedAuthorization {
  type: 'not-defined';
  isAuthorized: false;
}

export type Authorization = CognitoAuthorization | ApiKeyAuthorization | UndefinedAuthorization;

const AUTH_DEFAULT_VALUE: Authorization = {
  type: 'not-defined',
  isAuthorized: false,
};

export const AuthContext = React.createContext<Authorization>(AUTH_DEFAULT_VALUE);

export const useAuthContext = () => useContext(AuthContext);
