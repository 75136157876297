import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import './animation.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholderText: {
      backgroundColor: theme.palette.type === 'dark'
        ? 'rgba(255, 255, 255, 0.13)'
        : 'rgba(0, 0, 0, 0.11)',
      borderRadius: 4,
      color: 'transparent',
      animation: '1.5s ease-in-out 0.5s infinite normal none running skeleton-animation',
      userSelect: 'none',
    },
  }));

export interface Props {
  className?: string;
  loading: boolean;
  children: any;
}

export default function PlaceholderText({className, loading, children}: Props): ReactElement {
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        loading
          ? <span className={`${classes.placeholderText} ${className}`}>
              {children}
            </span>
          : children
      }
    </React.Fragment>
  );
}
