import React, {useEffect} from 'react';
import {Box, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ContentPerformanceCard from '../../charts/content-performance/ContentPerformanceCard';
import {useSenderInfoQuery} from '../SenderInfoQuery';
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom';
import AudienceCard from '../../charts/audience/AudienceCard';
import PlaceholderText from '../../placeholder/PlaceholderText';
import VisitsCard from '../../charts/visits/VisitsCard';
import SenderHighlightsCard from '../../charts/sender-highlights/SenderHighlightsCard';
import SenderActivityCard from '../../charts/sender-activity/SenderActivityCard';
import {useSideNavContext} from '../../side-navigation/SideNavContext';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useLayoutContext} from '../../layout/LayoutContext';
import {SenderTypeFrontend} from '../../types';
import WorkspaceHighlightsCard from '../../charts/workspace-highlights/WorkspaceHighlightsCard';
import {SenderInfo} from '../../schema';
import {ApiKeyAuthorization, useAuthContext} from '../../auth/AuthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 40,

      '&:last-child': {
        marginBottom: 0,
      },

      '& > *': {
        flex: 1,
        marginRight: 40,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    visitsCard: {
      flex: 1,
    },
    audienceCard: {
      flex: 1,
    },
    overviewRedirect: {},
    '@media print': {
      overviewRedirect: {
        display: 'none',
      },
    },
    '@media only screen and (max-width: 1000px)': {
      visitsCard: {
        flexBasis: '100%',
        marginRight: 0,
        marginBottom: theme.spacing(5),
      },
    },
  }),
);

export default function SenderDashboard() {
  const {senderId, senderType} = useParams<{ senderId: string, senderType: SenderTypeFrontend | 'sender' }>();
  const {setSenderId} = useAuthContext() as ApiKeyAuthorization;
  const {search} = useLocation();
  useEffect(() => {
    if (!!senderId && !!setSenderId)
      setSenderId(senderId);
  }, [setSenderId, senderId]);
  const classes = useStyles();
  const {loading, data} = useSenderInfoQuery(senderId);
  const {reportData} = useReportDataContext();
  const {isMinimalisticView, setMinimalisticView} = useLayoutContext();
  const {activeSideNavOption, setActiveSideNavOption} = useSideNavContext();
  const senderName = data?.senderInfo?.name || 'Dashboard';
  const querySenderType = data?.senderInfo?.type;

  reportData.senderInfo = data?.senderInfo || {} as SenderInfo;
  reportData.senderInfoLoading = loading;

  useEffect(() => {
    document.title = `Haiilo Intranet Analytics | ${senderType.charAt(0).toUpperCase() + senderType.slice(1)}: ${senderName}`;

    if (senderType === 'page') {
      setActiveSideNavOption('pages');
    }
    if (senderType === 'my-page') {
      setActiveSideNavOption('my-pages');
    }
    if (senderType === 'community') {
      setActiveSideNavOption('communities');
    }
    if (senderType === 'my-community') {
      setActiveSideNavOption('my-communities');
    }
    if (senderType === 'sender') {
      setMinimalisticView(true);
    }
  }, [setMinimalisticView, setActiveSideNavOption, senderType, senderName]);

  return (
    <React.Fragment>
      {!isMinimalisticView && (
        <Link className={classes.overviewRedirect} component={RouterLink} to={`/${activeSideNavOption}${search}`}
              variant={'body2'}
              data-testid={'back-to-overview-link'}>
          ← Back to overview
        </Link>
      )}
      <Typography variant="h1" data-testid={`sender-name`}>
        <PlaceholderText loading={loading}>{senderName || '0000'}</PlaceholderText>
      </Typography>

      <Box className={classes.row}>
        {querySenderType === 'workspace'
          ? (<WorkspaceHighlightsCard senderId={senderId}/>)
          : (<SenderHighlightsCard senderId={senderId} senderType={querySenderType as SenderTypeFrontend}/>)}
      </Box>

      <Box className={classes.row}>
        <SenderActivityCard senderId={senderId} senderType={querySenderType as SenderTypeFrontend}/>
      </Box>

      <Box className={classes.row}>
        <ContentPerformanceCard senderType={senderType} senderId={senderId}/>
      </Box>

      <Box className={classes.row}>
        <VisitsCard senderId={senderId} className={classes.visitsCard}/>
        <AudienceCard senderId={senderId} className={classes.audienceCard}/>
      </Box>

    </React.Fragment>);
}
