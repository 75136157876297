import { gql } from '@apollo/client';
import { useApiQuery } from '../apollo/ApiQuery';
import { GlobalFilter } from '../schema';

export const useAvailableGlobalFiltersQuery = (globalFilters: GlobalFilter[]) => {
    return useApiQuery(gql`
        query availableGlobalFilters($globalFilters: [GlobalFilter!]!) {
            availableGlobalFilters(globalFilters: $globalFilters){
                company {
                    value
                    amount
                    selected
                }
                location {
                    value
                    amount
                    selected
                }
                department {
                    value
                    amount
                    selected
                }
                country {
                    value
                    amount
                    selected
                }
                grade {
                    value
                    amount
                    selected
                }
                platform {
                    value
                    amount
                    selected
                }
                bereich {
                    value
                    amount
                    selected
                }
            }
        }
    `, { globalFilters });
};
