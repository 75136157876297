import { ChartData, ChartOptions, ScriptableLineSegmentContext } from 'chart.js';
import { DEFAULT_TIMEZONE } from '../../settings/SettingsContext';
import { ExtendedChartDataset, ExtendedLineDataPoint, Group } from './LineChart';
import { getChartOptions } from '../ChartOptions';
import { Theme } from '@material-ui/core';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { merge } from 'lodash';

export function getLineChartOptions(theme: Theme, threshold: boolean | undefined, group: Group, minGroupSize: number, minGroupSizeConfig: AnnotationOptions, notAvailablePlaceHolder: string, onAnimationComplete: () => void): ChartOptions<'line'> {
  const chartOptions = getChartOptions(theme);
  const skipped = <T>(ctx: ScriptableLineSegmentContext, value1: T, value2: T | undefined = undefined): T | undefined => (threshold && (ctx.p0.parsed.y === 0 || ctx.p1.parsed.y === 0)) ? value1 : value2;

  const lineChartOptions: ChartOptions<'line'> = {
    elements: {
      line: {
        spanGaps: threshold,
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: group,
          displayFormats: {
            day: "d LLL yy",
            week: "'Week' W yy",
            month: "LLL yy",
          },
        },
        adapters: {
          date: {
            setZone: true,
            zone: DEFAULT_TIMEZONE,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          precision: 0,
        },
        suggestedMin: 0,
        suggestedMax: minGroupSize,
      },
    },
    plugins: {
      annotation: {
        annotations: {
          minGroupSize: threshold ? minGroupSizeConfig : undefined,
        },
      },
      legend: {
        labels: {
          pointStyle: 'rect',
          usePointStyle: true,
          filter(item, data: ChartData<'line', ExtendedLineDataPoint[]>) {
            const dataset = data.datasets[item.datasetIndex ?? -1] as ExtendedChartDataset;
            if (dataset.loading) {
              item.fontColor = 'lightgray';
            }
            return true;
          },
        },
      },
      tooltip: {
        position: 'top',
        usePointStyle: true,
        callbacks: {
          title: context => (context[0].dataset.data[context[0].dataIndex] as ExtendedLineDataPoint).dateLong,
          labelPointStyle: () => ({ pointStyle: 'rect', rotation: 0 }),
          label: (tooltipItem) => {
            const value = threshold && ((tooltipItem.raw as ExtendedLineDataPoint).y < minGroupSize) ? notAvailablePlaceHolder : tooltipItem.formattedValue;
            return `${tooltipItem.dataset.label ?? notAvailablePlaceHolder}: ${value}`;
          },
        },
      },
    },
    animation: {
      onComplete: onAnimationComplete,
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    datasets: {
      line: {
        segment: {
          borderColor: ctx => skipped(ctx, theme.palette.secondary.main, ctx.p0.options.borderColor),
          // @ts-ignore
          borderDash: ctx => skipped(ctx, [5, 3]) || (ctx.p1.raw as ExtendedLineDataPoint).dotted ? [5, 3] : [0, 0],
        },
      },
    },
  }

  return merge(chartOptions, lineChartOptions);
}
