import React from 'react';
import {createStyles} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment, {Moment} from 'moment-timezone';
import CoyoFormControl from '../form-control/CoyoFormControl';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ReactComponent as CoyoCalendarIcon} from '../../svg/calendar.svg';
import MomentUtils from '@date-io/moment';
import {DateType} from '@date-io/type';
import {useSettingsContext} from '../../settings/SettingsContext';
import {v4 as uuid} from 'uuid';

interface Props {
  value: Moment
  min?: Moment,
  max?: Moment,
  onChange: (date: Moment) => void
  label?: string
  labelledBy?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 150,

      '& svg': {
        height: 20,
        width: 20,
      },
      '& input': {
        fontSize: 15,
        marginRight: theme.spacing(2),
      },
      '& .MuiIconButton-root': {
        padding: 0,
        height: 20,
        width: 20,
      },
    },
    iconStyle: {
      position: 'absolute',
      right: 0,
      top: 0,
      pointerEvents: 'none',
      height: 20,
      width: 20,
    },
  }));

export default function CoyoDatePicker({label, value, min, max, onChange, labelledBy}: Props) {
  const classes = useStyles();
  const localDateFormat = moment.localeData().longDateFormat('L');
  const {timezone} = useSettingsContext();
  const errorMessageId = `min-date-message-${uuid()}`;

  function handleChange(date: DateType | null) {
    if (!date || !timezone) return;
    onChange(moment.tz(date.clone(), timezone));
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
      <CoyoFormControl className={classes.root}>
        <KeyboardDatePicker
          value={value}
          minDate={min}
          maxDate={max}
          minDateMessage={<span role={'alert'} id={errorMessageId}>{label} should not be before minimal date</span>}
          maxDateMessage={<span role={'alert'} id={errorMessageId}>{label} should not be after maximal date</span>}
          onChange={handleChange}
          autoOk
          variant='inline'
          inputVariant={'filled'}
          format={localDateFormat}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            'aria-describedby': errorMessageId
          }}
          keyboardIcon={<CoyoCalendarIcon className={classes.iconStyle} aria-label={label || ''} aria-labelledby={labelledBy || ''}/>}
          data-testid={'keyboard-datepicker'}
          aria-label={label || ''}
          aria-labelledby={labelledBy || ''}
        />
      </CoyoFormControl>
    </MuiPickersUtilsProvider>
  );
}
