import {Theme, withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React, {ReactElement} from 'react';
import {useAppContext} from '../../CoyoAnalyticsTheme';
import {ClickAwayListener} from '@material-ui/core';

interface CommonProps {
  children: ReactElement,
  title: JSX.Element,
  placement: 'right-start' | 'bottom-end' | 'top-start' | 'bottom-start',
  clickable?: boolean
}

interface ClickableProps extends CommonProps {
  open: boolean,
  onClose: () => void
}

const DarkModeTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#101d30',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(1),
    maxWidth: 400,
  },
}))(Tooltip);

const LightModeTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#101d30',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(1),
    maxWidth: 400,
  },
}))(Tooltip);

export default function CoyoTooltip(props: CommonProps | ClickableProps): ReactElement {
  const {darkMode} = useAppContext();

  const clickableProps = props as ClickableProps;
  const isPressingCloseKey = (key: string) => key === 'Escape' || key === 'Tab';

  return (
    props.clickable ? (
      <ClickAwayListener onClickAway={() => clickableProps.onClose()}>
        <div>
          {!darkMode ? (
            <LightModeTooltip
              title={clickableProps.title}
              placement={clickableProps.placement}
              open={clickableProps.open}
              onClose={clickableProps.onClose}
              onKeyDown={(e) => isPressingCloseKey(e.key) ? clickableProps.onClose() : null}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              interactive
              data-testid={'light-tooltip'}>
              {clickableProps.children}
            </LightModeTooltip>
          ) : (
            <DarkModeTooltip
              title={clickableProps.title}
              placement={clickableProps.placement}
              open={clickableProps.open}
              onClose={clickableProps.onClose}
              onKeyDown={(e) => isPressingCloseKey(e.key) ? clickableProps.onClose() : null}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              interactive
              data-testid={'dark-tooltip'}>
              {clickableProps.children}
            </DarkModeTooltip>
          )}
        </div>
      </ClickAwayListener>) : (
      !darkMode ? (
        <LightModeTooltip
          title={props.title}
          placement={props.placement}
          interactive
          data-testid={'light-tooltip'}>
          {props.children}
        </LightModeTooltip>
      ) : (
        <DarkModeTooltip
          title={props.title}
          placement={props.placement}
          interactive
          data-testid={'dark-tooltip'}>
          {props.children}
        </DarkModeTooltip>
      ))
  );
}
