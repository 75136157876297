import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';

export const useWorkspaceHighlightsQuery = (senderId: string, from: string, to: string, includeUserGeneratedContent: boolean) => {
    return useApiQuery(gql`
        query workspaceHighlights($senderId: String!, $from: String!, $to: String!, $includeUserGeneratedContent: Boolean) {
            senderHighlights(senderId: $senderId, from: $from, to: $to, includeUserGeneratedContent: $includeUserGeneratedContent) {
                likes {
                    total
                    trendPercent
                }
                comments {
                    total
                    trendPercent
                }
                shares {
                    total
                    trendPercent
                }
            }
            workspaceHighlights(senderId: $senderId, from: $from, to: $to) {
                members {
                    total
                    trendPercent
                }
            }
        }
    `, {senderId, from, to, includeUserGeneratedContent});
};

