import React, {useState} from 'react';
import LineChart, {EMPTY_SERIES, Group, Series} from '../../chart-types/line-chart/LineChart';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {usePageActivityQuery} from './SenderActivityQuery';
import {SenderActivity} from '../../schema';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {Box, MenuItem} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';
import {useFeatureToggleContext} from '../../feature-toggle/FeatureToggleContext';
import { FeatureToggle } from '../../feature-toggle/feature';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&:focus': {
        background: theme.palette.action.hover,
      },
    },
    chart: {
      padding: '8px',
    },
  }));

interface Props {
  homepageId: string,
}

export default function HomepageActivityCard({homepageId}: Props) {
  const classes = useStyles();
  const [group, setGroup] = useState<Group>('day');
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = usePageActivityQuery(homepageId, fromDay, toDay);
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {isDisabledFeature} = useFeatureToggleContext();
  const {reportData} = useReportDataContext();
  const senderActivity: SenderActivity | undefined = data?.senderActivity;

  const helpText = {
    what: 'Shows the number of visitors of the configured time range.',
    how: <React.Fragment>
      {`Visitors is the total number of unique users, who have opened the homepage in the time range.`}<br/>
    </React.Fragment>,
  };

  reportData.senderActivity = senderActivity;
  reportData.senderActivityLoading = loading;

  const series: Series[] = [{
    name: 'Visits',
    dataByRange: !!senderActivity ? senderActivity.visits : EMPTY_SERIES,
  }, {
    name: 'Visitors',
    dataByRange: !!senderActivity ? senderActivity.visitors : EMPTY_SERIES,
  }];

  return (
    <CoyoCard id={'page-activity-card'}
              title={'Homepage Activity'}
              loading={loading}
              helpText={helpText}
              filtered={appliedGlobalFilters.length > 0}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              controls={(
                <CoyoSelect SelectProps={{
                  value: group,
                  onChange: ({target}) => setGroup(target.value as Group),
                }}>
                  <MenuItem className={classes.menuItem} value={'day'}
                            disabled={group === 'day'}><span>Day</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'week'}
                            disabled={group === 'week'}><span>Week</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'month'}
                            disabled={group === 'month'}><span>Month</span></MenuItem>
                </CoyoSelect>
              )}
              reload={refetch}>
      <Box className={classes.chart}>
        <LineChart series={series} group={group} loading={loading} threshold={!isDisabledFeature(FeatureToggle.SenderMinGroupSize)}/>
      </Box>
    </CoyoCard>
  );
}
