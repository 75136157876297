import { gql, useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LandingPage, WebApiResponse } from '../schema';
import { SortColumn } from './overview/HomepagesOverview';
import { SortOrder } from '../coyo-components/table-header-cell/CoyoTableHeaderCell';
import { useList } from '../utils/lists';

const SubTypes = ['visits', 'visitors', 'avgVisitSeconds'] as const;

type Data = {
  landingPages: LandingPage[],
  requestId?: string,
  visitsLoaded?: boolean,
  visitorsLoaded?: boolean,
  avgVisitSecondsLoaded?: boolean
};

export const useHomepagesQuery = (
  from: string,
  to: string,
  sortColumn: SortColumn,
  sortOrder: SortOrder,
  nameFilter?: string | null
) => {
  const [data, setData] = useState<Data>({ landingPages: [] });
  const [isMounted, setIsMounted] = useState(false);
  const client = useApolloClient();
  const { sort, filter } = useList<LandingPage>(sortColumn, sortOrder, nameFilter);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const buildQuery = (type: string) => ({
    query: gql`
      query ${type}($from: String!, $to: String!) {
        landingPages(from: $from, to: $to, type: ${type} )
        { id, name, ${type} }
      }
    `,
    variables: { from, to }
  });

  useEffect(() => {
    if (!isMounted) {
      return
    }
    const currentRequestId = Math.random().toString(36).substring(2);

    setData(data => ({ landingPages: [], requestId: currentRequestId }));

    const mergeLandingPages = (landingPages: LandingPage[], existingLandingPages: LandingPage[]) => {
      if (!landingPages) {
        return existingLandingPages;
      }

      const map = existingLandingPages.reduce((all, current) => {
        all[current.id] = current;
        return all;
      }, {} as { [key: string]: LandingPage });

      landingPages.forEach(sender => {
        const existing = map[sender.id];
        map[sender.id] = existing ? { ...existing, ...sender } : sender;
      });

      return Object.values(map);
    }

    Promise.all(
      SubTypes.map(async (type: string) => {
        const result = await client.query<WebApiResponse>(buildQuery(type));

        setData(data => {
          if (currentRequestId !== data.requestId) {
            return data;
          }
          if (!result.data.landingPages) {
            return data;
          }
          return {
            ...data,
            landingPages: mergeLandingPages(result.data.landingPages, data.landingPages ?? []),
            [`${type}Loaded`]: true,
          }
        });
      })
    );
  }, [isMounted, from, to]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: {
      data: !data.visitsLoaded || !data.visitorsLoaded || !data.avgVisitSecondsLoaded,
      visits: !data.visitsLoaded,
      visitors: !data.visitorsLoaded,
      avgVisitSeconds: !data.avgVisitSecondsLoaded,
    },
    data: { landingPages: sort(filter(data.landingPages)) }
  };
};
