import {IconButton, TableBody, TableCell, TableRow} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useState} from 'react';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoTableCellBar from '../../coyo-components/table-cell-bar/CoyoTableCellBar';
import CoyoTableHeaderCell, {SortOrder} from '../../coyo-components/table-header-cell/CoyoTableHeaderCell';
import CoyoTable from '../../coyo-components/table/CoyoTable';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import PlaceholderText from '../../placeholder/PlaceholderText';
import {generateNumericDates} from '../DateUtils';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {ReactComponent as FilterIcon} from '../../svg/filter.svg';
import {ReactComponent as LinkIcon} from '../../svg/media-external-link.svg';
import {useContentPerformanceQuery} from './ContentPerformanceQuery';
import TypeFilterModal from './TypeFilterModal';
import {useHistory, useLocation} from 'react-router-dom';
import {ReactComponent as ChevronRight} from '../../svg/chevron-right.svg';
import {ContentPerformance} from '../../schema';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'scroll',

    '@media only screen and (min-width: 1200px)': {
      overflow: 'hidden',
    },
  },
  svgIcon: {
    width: 20,
    height: 20,
  },
  svgIconActive: {
    fill: theme.palette.action.focus,
  },
  titleCell: {
    maxWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconButton: {
    marginLeft: 8,
  },
  noTitle: {
    color: theme.palette.text.secondary,
    margin: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export const CONTENT_TYPE_NAMES = {
  'blog-article': 'Blog Article',
  'timeline-item': 'Timeline Post',
  'wiki-article': 'Wiki Article',
};
const warningText = `This value might be inaccurate because this content has been created before the reach tracking was available`;

const helpText = {
  what: 'Content performance shows how COYO users are interacting with timeline posts, blog articles and wiki articles. ' +
    'Only content created in the selected date range are shown.',
  how: 'The performance numbers are calculated over the entire time and are not adjusted by the date range selection. ' +
    'Reactions, comments, shares and reach is the total number summed up. ' +
    'Reach is showing the number of unique users, who this content was displayed at. ' +
    'Views is the number of times the content was displayed to users. ' +
    'Engagement rate is calculated as follows: Engagement Rate = (Reactions + (2,5 * Comments) + (5 * Shares)) / Reach * 100.',
  important: 'The reach measurement and therefore also the engagement rate can only be calculated correctly for content newer ' +
    'than 1st of March 2021. Reach measurements for content created earlier are not accurate. ' +
    'Reach numbers COYO mobile app users are depending on a specific version of the app.',
};

interface Props {
  senderId?: string;
  senderType?: string;
}

export default function ContentPerformanceCard({senderId, senderType}: Props) {
  const classes = useStyles();
  const {search} = useLocation();
  const pageRowAmount = 5;
  const [visibleRowsAmount, setVisibleRowsAmount] = useState<number>(pageRowAmount);
  const [hiddenTypes, setHiddenTypes] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState<SortColumn>({column: 'likes', order: 'desc'});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showTypeFilterModal, setShowTypeFilterModal] = useState<boolean>(false);
  const {startDate, endDate} = useDateRangeContext();
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {reportData} = useReportDataContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const history = useHistory();

  const REQUEST_SIZE_MULTIPLIER = 1000;
  const {loading, data, refetch} = useContentPerformanceQuery(
    fromDay,
    toDay,
    searchTerm,
    Object.keys(CONTENT_TYPE_NAMES).filter(type => !hiddenTypes.includes(type)),
    sortColumn.column,
    sortColumn.order,
    Math.ceil(visibleRowsAmount / REQUEST_SIZE_MULTIPLIER) * REQUEST_SIZE_MULTIPLIER + 1,
    senderId || null,
    true);

  const contentPerformance = data?.contentPerformance || [];

  reportData.contentPerformance = data?.contentPerformance;
  reportData.contentPerformanceLoading = loading;

  function renderFilterButton() {
    return (
      <IconButton key={'icon-button-filter'} size={'small'}
                  onClick={() => setShowTypeFilterModal(true)}
                  data-testid={'filter-button'}
                  disabled={loading}
                  aria-label={'Filter type'}>
        <FilterIcon className={`${classes.svgIcon} ${hiddenTypes.length > 0 ? classes.svgIconActive : ''}`}/>
      </IconButton>
    );
  }

  function renderTypeFilterModal() {
    return (
      <TypeFilterModal hiddenTypes={hiddenTypes}
                       open={showTypeFilterModal}
                       onClose={newHiddenTypes => {
                         setHiddenTypes(newHiddenTypes);
                         setShowTypeFilterModal(false);
                       }}/>
    );
  }

  function isFocused(key: string): boolean {
    return key === sortColumn.column;
  }

  function renderTitle(title: string) {
    if (title)
      return title;

    return (
      <p className={classes.noTitle}>No title available</p>
    );
  }

  const headerCells =
    [
      <CoyoTableHeaderCell key={'title'} title={'Title'}
                           search={{text: 'Title', isFocused: isFocused('title'), setSearchTerm: setSearchTerm}}
                           width={'27%'}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'link'} title={''} loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'type'} title={'Type'} controls={{right: [renderFilterButton()]}} width={'10%'}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'likes'} title={'Reactions'}
                           sorting={{
                             onClick: setSortColumn,
                             column: 'likes',
                             active: sortColumn.column === 'likes',
                           }}
                           width={'10%'}
                           focused={isFocused('likes')}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'comments'} title={'Comments'}
                           sorting={{
                             onClick: setSortColumn,
                             column: 'comments',
                             active: sortColumn.column === 'comments',
                           }}
                           width={'10%'}
                           focused={isFocused('comments')}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'shares'} title={'Shares'}
                           sorting={{
                             onClick: setSortColumn,
                             column: 'shares',
                             active: sortColumn.column === 'shares',
                           }}
                           width={'10%'}
                           focused={isFocused('shares')}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'reach'} title={'Reach'}
                           sorting={{
                             onClick: setSortColumn,
                             column: 'reach',
                             active: sortColumn.column === 'reach',
                           }}
                           width={'10%'}
                           focused={isFocused('reach')}
                           helpText={'Number of how many individual users have actually seen the content.'}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'views'} title={'Views'}
                           sorting={{
                               onClick: setSortColumn,
                               column: 'views',
                               active: sortColumn.column === 'views',
                           }}
                           width={'10%'}
                           focused={isFocused('views')}
                           helpText={'Number of views the content has.'}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'engagement'} title={'Engagement rate'}
                           sorting={{
                             onClick: setSortColumn,
                             column: 'engagementRate',
                             active: sortColumn.column === 'engagementRate',
                           }}
                           width={'13%'}
                           focused={isFocused('engagement')}
                           helpText={'Ratio of users reached to interactions with the respective post. Note: A reaction is weighted less than a comment or share.'}
                           loading={loading && contentPerformance.length === 0}/>,
      <CoyoTableHeaderCell key={'content-insights-link'} title={''} loading={loading && contentPerformance.length === 0}/>,
    ];

  return (
    <CoyoCard id={'content-performance-card'}
              title={'Content performance'}
              loading={loading && contentPerformance.length === 0}
              helpText={helpText}
              filtered={appliedGlobalFilters.length > 0}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              className={classes.card}
              reload={refetch}>
      <CoyoTable headerCells={headerCells}
                 loading={loading}
                 skeletonRow={<TableRow>
                   <TableCell colSpan={1}><PlaceholderText loading={true}>{'X'.repeat(25)}</PlaceholderText></TableCell>
                   <TableCell colSpan={1}><PlaceholderText loading={true}>{'X'.repeat(3)}</PlaceholderText></TableCell>
                   <TableCell><PlaceholderText loading={true}>{'X'.repeat(10)}</PlaceholderText></TableCell>
                   <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true}/>
                   <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true}/>
                   <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true}/>
                   <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true}/>
                   <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true}/>
                   <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true}/>
                 </TableRow>}
                 skeletons={visibleRowsAmount}
                 isEmpty={contentPerformance.length === 0}
                 showShowMoreButton={visibleRowsAmount < contentPerformance.length}
                 onShowMoreClick={() => setVisibleRowsAmount(visibleRowsAmount + pageRowAmount)}>
        <TableBody>
          {contentPerformance.slice(0, visibleRowsAmount).map((row) => {
            const contentInsightsLink = (!!senderId && !!senderType) ? `/${senderType}/${senderId}/content/${row.id}${search}` : `/content/${row.id}${search}`;
            return (
              <TableRow key={row.id}>
                <TableCell className={`${classes.titleCell} ${classes.pointer}`} title={row.title}
                           data-testid={'title'}
                           onClick={() => history.push(contentInsightsLink)}>{renderTitle(row.title)}</TableCell>
                <TableCell>
                  {row.link && <IconButton aria-label="open content in new tab"
                                           size={'small'}
                                           title={`View ${row.title} in COYO`}
                                           href={row.link}
                                           target={'_blank'}
                                           rel={'noopener noreferrer'}
                                           data-testid={'link-button'}>
                      <LinkIcon className={`${classes.svgIcon}`}/>
                  </IconButton>}
                </TableCell>
                <TableCell>{CONTENT_TYPE_NAMES[row.type]}</TableCell>
                <CoyoTableCellBar value={row.likes}
                                  maxValue={Math.max(...contentPerformance.map(({likes}) => likes))}
                                  displayType="raw"
                                  focused={isFocused('likes')}/>
                <CoyoTableCellBar value={row.comments}
                                  maxValue={Math.max(...contentPerformance.map(({comments}) => comments))}
                                  displayType="raw"
                                  focused={isFocused('comments')}/>
                <CoyoTableCellBar value={row.shares}
                                  maxValue={Math.max(...contentPerformance.map(({shares}) => shares))}
                                  displayType="raw"
                                  focused={isFocused('shares')}/>
                <CoyoTableCellBar value={row.reach}
                                  maxValue={Math.max(...contentPerformance.map(({reach}) => reach))}
                                  displayType="raw"
                                  focused={isFocused('reach')}/>
                <CoyoTableCellBar value={row.views}
                                  maxValue={Math.max(...contentPerformance.map(({views}) => views))}
                                  displayType="raw"
                                  focused={isFocused('views')}/>
                <CoyoTableCellBar value={Math.round(row.engagementRate)}
                                  maxValue={Math.max(...contentPerformance.map(({engagementRate}) => engagementRate))}
                                  showWarning={!row.reachTrackedSinceCreation && row.engagementRate > 0}
                                  displayType="percent"
                                  focused={isFocused('engagementRate')}
                                  warningText={warningText}/>
                <TableCell>
                  <IconButton aria-label="open content insights"
                              size={'small'}
                              title={`View content insights for ${row.title}`}
                              onClick={() => history.push(contentInsightsLink)}
                              data-testid={'content-insights-link-button'}>
                    <ChevronRight className={`${classes.svgIcon}`}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CoyoTable>
      {renderTypeFilterModal()}
    </CoyoCard>
  );
}

interface SortColumn {
  column: keyof ContentPerformance,
  order: SortOrder
}
