import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import HighlightBox from '../../chart-types/highlight-box/HighlightBox';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import {useCurrentUserActivityQuery} from './CurrentUserActivityQuery';
import {CurrentUserActivity} from '../../schema';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    values: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flex: 10,
    },
    card: {
      flex: 3,
    },
    highlightBox: {
      alignItems: 'flex-start',
    },
  }));

export interface Props {
  className?: string;
}

const helpText = {
  what: 'Active unique users is the number users, who have been active in the given time range (last 24h, last 7 days, last 28 days).',
  how: 'A user is counted as active, if he/she/they used COYO for at least once in the specific time range for more than 3 seconds.',
  important: 'The time ranges used for the calculation are fixed based on the current date and time and do ' +
    'not change based on the selected date.',
};

export default function CurrentUserActivityCard({className}: Props): ReactElement {
  const classes = useStyles();

  const {startDate, endDate} = useDateRangeContext();
  const {loading, data, refetch} = useCurrentUserActivityQuery();
  const {reportData} = useReportDataContext();
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const currentUserActivity: CurrentUserActivity | undefined = data?.currentUserActivity;

  reportData.currentUserActivity = currentUserActivity;
  reportData.currentUserActivityLoading = loading;

  const helpTextDay = currentUserActivity ? `${currentUserActivity.day} different users were active in the last 24 hours.` : '';
  const helpTextWeek = currentUserActivity ? `${currentUserActivity.week} different users were active in the last 7 days.` : '';
  const helpTextMonth = currentUserActivity ? `${currentUserActivity.month} different users were active in the last 28 days.` : '';

  return (
    <CoyoCard id={'current-user-activity-card'}
              title={'Active unique users'}
              loading={loading}
              helpText={helpText}
              className={className}
              filtered={appliedGlobalFilters.length > 0}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              reload={refetch}>
      <Box className={classes.values}>
        <HighlightBox loading={loading} title={'Over the last 24 hours'}
                      data={!currentUserActivity ? undefined : {total: currentUserActivity.day}}
                      valueType={'number'}
                      className={classes.highlightBox}
                      helpText={helpTextDay}/>
        <HighlightBox loading={loading} title={'Last 7 days'}
                      data={!currentUserActivity ? undefined : {total: currentUserActivity.week}}
                      valueType={'number'}
                      className={classes.highlightBox}
                      helpText={helpTextWeek}/>
        <HighlightBox loading={loading} title={'Last 28 days'}
                      data={!currentUserActivity ? undefined : {total: currentUserActivity.month}}
                      valueType={'number'}
                      className={classes.highlightBox}
                      helpText={helpTextMonth}/>
      </Box>
    </CoyoCard>
  );
}
