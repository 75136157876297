import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';

export const useSearchAnalyticsQuery = (from: string, to: string, limit: number) => {
    const {appliedGlobalFilters} = useGlobalFiltersContext();
    return useApiQuery(gql`
        query searchAnalytics($from: String!, $to: String!, $limit: Int!, $globalFilters: [GlobalFilter!]) {
            searchAnalytics(from: $from, to: $to, limit: $limit, globalFilters: $globalFilters) {
                phrases {
                    term
                    total
                }
                terms {
                    term
                    total
                }
            }
        }
    `, {from, to, limit, globalFilters: appliedGlobalFilters});
};
