import {Workbook} from 'exceljs';
import {LandingPage} from '../../schema';

export function addHomepagesOverviewReport(workbook: Workbook, senderOverviewData: LandingPage[]) {
  const worksheet = workbook.addWorksheet(`Homepages Overview`);
  worksheet.columns = [
    {header: 'Name', width: 65},
    {header: 'Visits', width: 15},
    {header: 'Visitors', width: 15},
    {header: 'Visit duration (avg)', width: 15},
  ];

  senderOverviewData.forEach((sender) => {
    worksheet.addRow([sender.name, sender.visits, sender.visitors, `${sender.avgVisitSeconds} seconds`]);
  });

  worksheet.autoFilter = 'A1:D1';
}
