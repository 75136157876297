import React, {useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, Typography} from '@material-ui/core';
import CoyoModal from '../../coyo-controls/modal/CoyoModal';
import {CONTENT_TYPE_NAMES} from './ContentPerformanceCard';

interface Props {
  hiddenTypes: string[];
  open: boolean;
  onClose: (hiddenTypes: string[]) => void;
}

export default function TypeFilterModal({hiddenTypes, open, onClose}: Props) {
  const [newHiddenTypes, setNewHiddenTypes] = useState<string[]>(hiddenTypes);

  function renderCheckbox(type: string) {
    const handleCheckboxChange = (type: string, checked: boolean) => {
      setNewHiddenTypes(checked
        ? newHiddenTypes.filter(hiddenType => hiddenType !== type)
        : [...newHiddenTypes, type],
      );
    };

    return (
      <Checkbox color="primary"
                checked={!newHiddenTypes.includes(type)}
                onChange={(event: any, checked: boolean) => handleCheckboxChange(type, checked)}/>
    );
  }

  return (
    <CoyoModal open={open} onClose={() => onClose(hiddenTypes)}>
      <Typography variant={'h3'}>Show</Typography>

      {
        Object.entries(CONTENT_TYPE_NAMES).map(([type, name]) => (
          <Box key={type}>
            <FormControlLabel control={renderCheckbox(type)} label={name}/>
          </Box>
        ))
      }

      <Button variant={'contained'}
              color={'primary'}
              onClick={() => onClose(newHiddenTypes)}
              data-testid={'apply-button'}>
        Apply filter
      </Button>
    </CoyoModal>
  );
}
