import React, {createContext, useContext, useEffect, useState} from 'react';
import {createTheme, Theme, ThemeProvider} from '@material-ui/core/styles';
import '@fontsource/source-sans-pro';

const N0 = '#ffffff';
const N35 = '#f2f4f7';
const N50 = '#ebecf0';
const N75 = '#d7dbe0';
const N200 = '#697687';
const N300 = '#313d4d';
const N400 = '#202d40';
const N500 = '#192638';
const N600 = '#101d30';
const B300 = '#2693FF';
const B400 = '#0073e6';

interface Context {
  darkMode: boolean;
  toggleDarkMode: () => void;
  fontsLoaded?: boolean;
}

export const AppContext = createContext<Context>({
  darkMode: false,
  toggleDarkMode: () => null,
  fontsLoaded: false,
});

export const useAppContext = () => useContext(AppContext);

export const CoyoAnalyticsTheme = ({children}: any) => {
  const defaultDarkMode = localStorage.getItem('darkMode') === 'true';
  const [darkMode, setDarkMode] = useState<boolean>(defaultDarkMode);
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  useEffect(() => localStorage.setItem('darkMode', `${darkMode}`), [darkMode]);

  useEffect(() => {
    (document as any).fonts.ready.then(() => setFontsLoaded(true));
  }, []);

  const context: Context = {
    darkMode,
    toggleDarkMode: () => setDarkMode(!darkMode),
    fontsLoaded,
  };

  return (
    <AppContext.Provider value={context}>
      <ThemeProvider theme={getTheme(darkMode)}>
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};

function getTheme(darkModeActive: boolean = false): Theme {
  return createTheme({
    typography: {
      fontFamily: 'Source Sans Pro, serif',
      fontSize: 14,
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 15,
        fontWeight: 400,
      },
      h1: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.334,
        paddingBottom: '16px'
      },
      h2: {
        fontSize: 15,
        fontWeight: 600,
        lineHeight: 1
      },
      h3: {
        fontSize: 15,
        fontWeight: 600,
        lineHeight: 1
      }
    },
    palette: {
      type: darkModeActive ? 'dark' : 'light',
      primary: {
        main: darkModeActive ? B300 : B400,
      },
      secondary: {
        main: darkModeActive ? N300 : N75,
      },
      background: {
        default: darkModeActive ? N500 : N50,
        paper: darkModeActive ? N600 : N0,
      },
      divider: darkModeActive ? N500 : N50,
      text: {
        primary: darkModeActive ? N0 : N600,
        secondary: N200,
        disabled: darkModeActive ? N400 : N35,
      },
      action: {
        disabled: darkModeActive ? N400 : N35,
        selected: undefined,
        focus: B400,
        hover: darkModeActive ? N400 : N35,
      },
    },
    overrides: {
      MuiButton: {
        'root': {
          textTransform: 'none',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            bottom: -3,
            borderBottomColor: 'inherit',
            transition: '1s cubic-bezier(0.07, 0.54, 0.54, 0.6) 0ms',
          },
          '&:hover:not($disabled):before': {
            transition: 'none',
            borderBottomWidth: 0.5,
            borderBottomColor: 'inherit',
          },
          '&:after': {
            bottom: -3,
            borderBottomColor: 'inherit',
            transition: '200ms cubic-bezier(0.07, 0.54, 0.54, 0.6) 0ms',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            '-webkit-font-smoothing': 'unset',
            '@media print': {
              width: '210mm',
            },
          },
          body: {
            '& svg': {
              fill: darkModeActive ? N0 : N200,
            },
          },
        },
      },
    },
  });
}

