import React, {useContext} from 'react';

export interface ISideNavContext {
  activeSideNavOption: ActiveSideNavOption;
  setActiveSideNavOption: (option: ActiveSideNavOption) => void;
}

const DEFAULT_VALUE: ISideNavContext = {
  activeSideNavOption: 'dashboard' as ActiveSideNavOption,
  setActiveSideNavOption: () => null,
};

export const SideNavContext = React.createContext<ISideNavContext>(DEFAULT_VALUE);

export const useSideNavContext = () => useContext(SideNavContext);

export type ActiveSideNavOption = 'dashboard' | 'pages' | 'communities' | 'homepages' | 'my-pages' | 'my-communities'

