import LineChart, {Group, Series} from '../../chart-types/line-chart/LineChart';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {Box, Button, MenuItem} from '@material-ui/core';
import React, {useState} from 'react';
import CoyoModal from '../../coyo-controls/modal/CoyoModal';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&:focus': {
        background: theme.palette.action.hover,
      },
    },
    root: {
      width: '60vw',
    },
    header: {
      display: 'flex',
      paddingBottom: theme.spacing(2),
    },
    title: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    controls: {
      marginLeft: 'auto',
    },
    closeButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

interface Props {
  title: string;
  series: Series[];
  loading: boolean;
  open: boolean;
  onClose: () => void;
}

export default function HighlightChartModal({title, series, loading, open, onClose}: Props) {
  const classes = useStyles();
  const [group, setGroup] = useState<Group>('day');

  return (
    <CoyoModal keepMounted={true} open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Box className={classes.header} data-testid={'header'}>
          <Box className={classes.title}>
            <Typography variant="h2" data-testid={'chart-header'}>{title}</Typography>
          </Box>
          <CoyoSelect SelectProps={{
            value: group,
            onChange: ({target}) => setGroup(target.value as Group),
          }}>
            <MenuItem className={classes.menuItem} value={'day'} disabled={group === 'day'}><span>Day</span></MenuItem>
            <MenuItem className={classes.menuItem} value={'week'} disabled={group === 'week'}><span>Week</span></MenuItem>
            <MenuItem className={classes.menuItem} value={'month'} disabled={group === 'month'}><span>Month</span></MenuItem>
          </CoyoSelect>
        </Box>
        <LineChart series={series} group={group} loading={loading}/>
        <Box className={classes.closeButton}>
          <Button variant={'contained'}
                  color={'secondary'}
                  onClick={onClose}
                  data-testid={'apply-button'}>
            Close
          </Button>
        </Box>
      </Box>
    </CoyoModal>
  );
}
