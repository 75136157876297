import {Workbook} from 'exceljs';
import {SenderHighlights} from '../../schema';
import {SenderTypeFrontend} from '../../types';
import {capitalizeFirstLetter} from './ReportUtils';

export function addSenderHighlightsReport(type: SenderTypeFrontend, workbook: Workbook, senderHighlightsData: SenderHighlights) {
  const worksheet = workbook.addWorksheet(capitalizeFirstLetter(type) + ' Highlights');
  worksheet.columns = [
    {header: 'Highlights Type', width: 20},
    {header: 'Value', width: 10},
    {header: 'Trend', width: 10, style: {numFmt: '0%'}},
  ];

  const {likes, comments, shares} = senderHighlightsData;
  worksheet.addRow(['Reactions', likes?.total, likes?.trendPercent ? likes?.trendPercent / 100 : '']);
  worksheet.addRow(['Comments', comments?.total, comments?.trendPercent ? comments?.trendPercent / 100 : '']);
  worksheet.addRow(['Shares', shares?.total, shares?.trendPercent ? shares?.trendPercent / 100 : '']);

  worksheet.autoFilter = 'A1:C1';
}
