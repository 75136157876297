import React, {ReactElement} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CoyoAnalyticsLogo} from '../../svg/analytics-logo.svg';
import {Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

interface Props {
  iconSize: number
  envName?: string
  className?: any
}

export default function CoyoLogoHeader({iconSize, envName, className}: Props): ReactElement {

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
      },
      title : {
        fontSize: '16px'
      },
      envName: {
        marginTop: 12,
      }
    }),
  );

  const classes = useStyles();

  return (
    <Box className={className ? `${classes.root} ${className}` : classes.root}>
      <CoyoAnalyticsLogo height={iconSize} width={iconSize}/>
      <Typography variant="h3" className={classes.title} noWrap>
        Haiilo Intranet Analytics
      </Typography>
      {envName && (
        <Typography variant="body1" className={classes.envName} noWrap data-testid={'env-name'}>
          {envName}
        </Typography>
      )}
    </Box>
  );
}
