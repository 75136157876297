import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {useSettingsContext} from '../../settings/SettingsContext';

export const useUserActivityMapQuery = (from: string, to: string) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {timezone} = useSettingsContext();
  return useApiQuery(gql`
      query userActivityMap($from: String!, $to: String!, $timezone: String!, $globalFilters: [GlobalFilter!]) {
          userActivityMap(from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters) {
              data {
                  data {
                      averageUsers
                      time
                  }
                  weekday
              }
          }
      }
  `, {from, to, timezone, globalFilters: appliedGlobalFilters});
};
