import {createStyles, FormControl, FormControlProps, Theme} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      '& .MuiFilledInput-root': {
        borderRadius: 4,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        transition: 'background-color .1s',
      },
      '& .MuiInputBase-input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
      '& .MuiInputLabel-filled': {
        position: 'relative',
        transform: 'none',
        paddingRight: 12,
      },
      '& .MuiInputLabel-formControl': {
        position: 'relative',
        transform: 'none',
        paddingRight: 12,
      },
    },
    '@media print': {
      root: {
        '& .MuiInputBase-input': {
          padding: '12px 0 12px 12px',
        },
      },
    },
  }),
);

export default function CoyoFormControl(props: FormControlProps) {
  const classes = useStyles();
  return <FormControl {...props} classes={{root: classes.root}}/>;
}
