import {Workbook} from 'exceljs';
import {Histogram} from '../../schema';
import {generateIsoDates, toLocaleDate} from '../../charts/DateUtils';
import {capitalizeFirstLetter, getValueFromTimeValues} from './ReportUtils';
import {Moment} from 'moment-timezone';

export function addUserActivityReport(workbook: Workbook, consumingUsersData: Histogram, interactingUsersData: Histogram, startDate: Moment, endDate: Moment) {
  addUserActivityHistogramSheet(workbook, 'day', consumingUsersData, interactingUsersData, startDate, endDate);
  addUserActivityHistogramSheet(workbook, 'week', consumingUsersData, interactingUsersData, startDate, endDate);
  addUserActivityHistogramSheet(workbook, 'month', consumingUsersData, interactingUsersData, startDate, endDate);
}

function addUserActivityHistogramSheet(workbook: Workbook, grouping: keyof Histogram, consumingUsersData: Histogram, interactingUsersData: Histogram, startDate: Moment, endDate: Moment) {
  const groupingFormatted = capitalizeFirstLetter(grouping);
  const worksheet = workbook.addWorksheet(`User Activity (Histogram ${groupingFormatted})`);
  worksheet.columns = [
    {header: 'Date', width: 20},
    {header: 'Consuming Users', width: 20},
    {header: 'Interacting Users', width: 20},
  ];
  const isoDates = generateIsoDates(startDate, endDate, grouping);
  isoDates.forEach(isoDate => {
    worksheet.addRow([
      toLocaleDate(isoDate),
      getValueFromTimeValues(consumingUsersData, grouping, isoDate),
      getValueFromTimeValues(interactingUsersData, grouping, isoDate),
    ]);
  });
  worksheet.autoFilter = 'A1:C1';
}
