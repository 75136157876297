import React from 'react';
import {createStyles, MenuProps, Select, Theme} from '@material-ui/core';
import {SelectProps} from '@material-ui/core/Select/Select';
import CoyoFormControl from '../form-control/CoyoFormControl';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CoyoChevronDown} from '../../svg/chevron-down.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      paddingLeft: 12,

      '&:focus': {
        borderRadius: 4,
      },

      '& span': {
        fontSize: 15,
        marginRight: theme.spacing(2),
      },
    },
    iconStyle: {
      position: 'absolute',
      right: 0,
      top: 0,
      pointerEvents : 'none',
      height: 20,
      width: 20,
      margin: '10px 12px',
    },
    '@media print': {
      iconStyle: {
        display: 'none',
      }
    }
  }),
);

interface Props extends SelectProps {
  className?: string
  variant?: 'standard' | 'outlined' | 'filled'
  SelectProps: SelectProps
  label?: string
}

export default function CoyoSelect({className, variant, SelectProps, children, label}: Props) {
  const classes = useStyles();
  const selectElementId = `coyo-select-${Date.now()}`;

  const menuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    getContentAnchorEl: null,
    elevation: 3
  };

  return (
    <CoyoFormControl aria-label={label ? label : ''}>
      <Select
        {...(SelectProps || {})}
        classes={{root: classes.select}}
        className={className}
        id={selectElementId}
        disableUnderline
        variant = {variant ? variant : 'filled'}
        MenuProps={menuProps}
        IconComponent={() => <CoyoChevronDown className={classes.iconStyle}/>}>
        {children}
      </Select>
    </CoyoFormControl>
  );
}
