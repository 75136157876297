import React, {createContext, useContext, useState} from 'react';
import {useAvailableGlobalFiltersQuery} from './AvailableGlobalFiltersQuery';
import {AvailableGlobalFilters, GlobalFilter} from '../schema';
import {GlobalFilterColumn} from '../coyo-components/global-filters/GlobalFilterColumn';

export interface IGlobalFiltersContext {
  loadingAvailableGlobalFilters: boolean;
  availableGlobalFilters: AvailableGlobalFilters;
  selectGlobalFilter: (column: GlobalFilterColumn, value: string) => void;
  unselectGlobalFilter: (column: GlobalFilterColumn, value: string) => void;
  selectedGlobalFilters: GlobalFilter[];
  applySelectedGlobalFilters: () => void;
  appliedGlobalFilters: GlobalFilter[];
  needToBeApplied: boolean;
}

export const GlobalFiltersContext = createContext<IGlobalFiltersContext>({
  loadingAvailableGlobalFilters: false,
  availableGlobalFilters: {},
  selectGlobalFilter: () => null,
  unselectGlobalFilter: () => null,
  selectedGlobalFilters: [],
  applySelectedGlobalFilters: () => null,
  appliedGlobalFilters: [],
  needToBeApplied: false,
});

export const useGlobalFiltersContext = () => useContext(GlobalFiltersContext);

interface Props {
  children: any;
}

export const GlobalFilterProvider = ({children}: Props) => {
  const [selectedGlobalFilters, setSelectedGlobalFilters] = useState<GlobalFilter[]>([]);
  const [appliedGlobalFilters, setAppliedGlobalFilters] = useState<GlobalFilter[]>([]);
  const {data, loading} = useAvailableGlobalFiltersQuery(selectedGlobalFilters);

  const context: IGlobalFiltersContext = {
    loadingAvailableGlobalFilters: loading,
    availableGlobalFilters: data?.availableGlobalFilters || {},
    selectGlobalFilter: (column: GlobalFilterColumn, value: string) => {
      setSelectedGlobalFilters([...selectedGlobalFilters, {column, value}]);
    },
    unselectGlobalFilter: (column: GlobalFilterColumn, value: string) => {
      setSelectedGlobalFilters(selectedGlobalFilters.filter(filter => !(filter.column === column && filter.value === value)));
    },
    selectedGlobalFilters: selectedGlobalFilters,
    applySelectedGlobalFilters: () => {
      const _availableGlobalFilters = data?.availableGlobalFilters || {};
      setSelectedGlobalFilters(selectedGlobalFilters
        .filter(filter => (_availableGlobalFilters[filter.column as keyof AvailableGlobalFilters] || [])
          .some(availableFilter => availableFilter.value === filter.value)));
      setAppliedGlobalFilters(selectedGlobalFilters);
    },
    appliedGlobalFilters: appliedGlobalFilters,
    needToBeApplied: !(
      selectedGlobalFilters.length === appliedGlobalFilters.length &&
      selectedGlobalFilters.every((selectedFilter) =>
        appliedGlobalFilters.some(
          (appliedFilter) =>
            selectedFilter.column === appliedFilter.column &&
            selectedFilter.value === appliedFilter.value,
        ),
      )
    ),
  };

  return (
    <GlobalFiltersContext.Provider value={context}>
      {children}
    </GlobalFiltersContext.Provider>
  );
};
