import React, {createRef, ReactElement, ReactInstance} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box, Paper} from '@material-ui/core';
import CoyoHelpBox from '../help-box/CoyoHelpBox';
import {HelpText} from '../help-box/HelpText';
import PlaceholderText from '../../placeholder/PlaceholderText';
import {ReactComponent as RefreshIcon} from '../../svg/refresh.svg';
import {ReactComponent as DownloadIcon} from '../../svg/download.svg';
import CoyoBasicMenu, {BasicMenuItem} from '../basic-menu/CoyoBasicMenu';
import {exportComponentAsPNG} from 'react-component-export-image';
import {v4 as uuid} from 'uuid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: 8,
    },
    header: {
      display: 'flex',
      flex: 1,
      margin: theme.spacing(2, 3, 0),
      minHeight: '40px',
      alignItems: 'center',
    },
    title: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: 0,
      height: '20px',
      width: '100%',
      justifyContent: 'space-between'
    },
    demoFlag: {
      fontSize: 15,
      fontWeight: 600,
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.secondary,
    },
    filteredFlag: {
      fontSize: 12,
      marginLeft: theme.spacing(1),
      padding: '4px 8px',
      color: theme.palette.text.secondary,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
      borderRadius: 4,
      textTransform: 'uppercase',
    },
    controls: {
      marginLeft: 'auto',
      paddingLeft: '8px' // TODO: Remove on integration into CoyoBasicMenu
    },
    '@media print': {
      paper: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.grey,
      },
    },
    svgIcon: {
      width: 20,
      height: 20,
    },
  }));

export interface Props {
  id: string;
  title?: string;
  loading: boolean;
  demoMode?: boolean;
  controls?: any;
  helpText?: HelpText;
  children: any;
  className?: string;
  filtered?: boolean;
  reload?: () => void;
  filterTimeRange?: string[];
}

export default function CoyoCard({
                                   id,
                                   title,
                                   loading,
                                   demoMode,
                                   helpText,
                                   controls,
                                   children,
                                   className,
                                   filtered,
                                   reload,
                                   filterTimeRange,
                                 }: Props): ReactElement {
  const classes = useStyles();
  const componentReference = createRef<ReactInstance>() as React.RefObject<React.ReactInstance>;
  const menuId = `menu-${uuid()}`;

  return (
    <Paper elevation={0} className={`${classes.paper} ${className}`} data-testid={id} ref={componentReference}>
      {title && <Box className={classes.header} data-testid={'header'}>
          <Box className={classes.title}>
              <Typography variant="h2" data-testid={'chart-header'}>
                  <PlaceholderText loading={loading}>{title}</PlaceholderText>
              </Typography>
            {demoMode && (
              <Typography variant="h3" className={classes.demoFlag} data-testid={'demo-flag'}>
                Demo data
              </Typography>
            )}
            {filtered && (
              <Box className={classes.filteredFlag} data-testid={'filtered-flag'}>
                Filtered
              </Box>
            )}
              <Box display={'inherit'} id={menuId}>
                {helpText && !loading && (<CoyoHelpBox helpText={helpText}/>)}
                {!loading && <CoyoBasicMenu items={getMenuItems(title, reload, filterTimeRange, classes, componentReference, menuId)} parentTitle={title}/>}
              </Box>
          </Box>
        {!!controls && (
          <Box className={classes.controls} style={{visibility: loading ? 'hidden' : 'visible'}}>
            {controls}
          </Box>
        )}
      </Box>}
      {children}
    </Paper>
  );
}

export function getMenuItems(title: string | undefined,
                             reload: (() => void) | undefined,
                             filterTimeRange: string[] | undefined,
                             classes: Record<any, string>,
                             componentReference: React.RefObject<React.ReactInstance>,
                             menuId: string): BasicMenuItem[] {
  const refreshItem = !!reload
    ? [{
      title: 'Refresh data',
      icon: <RefreshIcon className={`${classes.svgIcon}`}/>,
      onClick: () => !!reload ? reload() : undefined,
      divider: true,
    }]
    : [];

  return [
    {
      title: 'Save as .png',
      onClick: async () => {
        await exportComponentAsPNG(componentReference, {
          fileName: `${title?.toLowerCase().replace(' ', '-')}${!!filterTimeRange ? '_' + filterTimeRange[0] + '_' + filterTimeRange[1] : ''}.png`,
          html2CanvasOptions: {
            onclone: (clonedDoc) => {
              const element = clonedDoc.getElementById(menuId);
              if (!!element)
                element.style.display = 'none';
            },
          },
        });
      },
      icon: <DownloadIcon className={`${classes.svgIcon}`}/>,
      divider: true,
    },
    ...refreshItem,
  ];
}
