import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {CoyoAnalyticsTheme} from './CoyoAnalyticsTheme';
import {AuthProvider} from './auth/AuthProvider';
import {Apollo} from './apollo/Apollo';
import 'moment/min/locales';
import {DateRange} from './date-range-selection/DateRangeContext';
import CoyoSnackbarProvider from './coyo-components/snackbar-provider/CoyoSnackbarProvider';
import {ReportDataProvider} from './report-data/ReportDataProvider';
import {FeatureToggleProvider} from './feature-toggle/FeatureToggleProvider';
import {GlobalFilterProvider} from './global-filters/GlobalFiltersContext';
import {SettingsProvider} from './settings/SettingsContext';
import Retently from './retently/Retently';
import Routes from './Routes';

export default function App() {
  return (
    <CoyoAnalyticsTheme>
      <Router>
        <AuthProvider>
          <CoyoSnackbarProvider>
            <ReportDataProvider>
              <Apollo>
                <SettingsProvider>
                  <Retently/>
                  <FeatureToggleProvider>
                    <GlobalFilterProvider>
                      <DateRange>
                        <Routes/>
                      </DateRange>
                    </GlobalFilterProvider>
                  </FeatureToggleProvider>
                </SettingsProvider>
              </Apollo>
            </ReportDataProvider>
          </CoyoSnackbarProvider>
        </AuthProvider>
      </Router>
    </CoyoAnalyticsTheme>
  );
}
