import React from 'react';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import PlaceholderText from '../../placeholder/PlaceholderText';
import CoyoWarningBox from '../../coyo-components/warning/CoyoWarningBox';
import {formatBarValue, isBarFocusedOrDefaultStyle} from './BarsUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontSize: 15,
      width: '100%',
    },
    header: {
      '& *': {
        padding: theme.spacing(2, 0, 0, 1),
      },
      display: 'flex',
      flexDirection: 'row',
    },
    value: {
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    title: {
      fontWeight: 'normal'
    },
    warning: {
      textAlign: 'right',
    },
    progress: {
      borderRadius: 10,
      overflow: 'hidden',
    },
    emptyBar: {
      paddingBottom: theme.spacing(2),
      textAlign: 'center',
    },
    colorPrimary: {
      background: theme.palette.primary.main,
    },
    colorSecondary: {
      background: theme.palette.background.paper,
    },
    colorSecondaryFocused: {
      background: theme.palette.divider,
    },
  }),
);

export interface Bar {
  title?: string,
  value: number,
  maxValue: number,
  dataType: DisplayType,
  focused?: boolean,
}

export type DisplayType = 'percent' | 'raw' | 'seconds-to-duration' | 'amount';

interface Props {
  bars: Bar[],
  loading: boolean,
  skeletonBarAmount: number,
  classNames?: string,
  showWarning?: boolean,
  warningText?: string | undefined
  hideTitle?: boolean
}

export default function Bars({
                               bars,
                               loading,
                               skeletonBarAmount,
                               classNames,
                               showWarning,
                               warningText,
                               hideTitle,
                             }: Props) {
  const classes = useStyles();

  const skeletonBars: Bar[] = new Array(skeletonBarAmount).fill({
    title: hideTitle ? undefined : 'Placeholder',
    percent: 0,
  });

  const barsToRender = !loading ? bars : skeletonBars;
  return (
    <Box className={classNames}>
      <Box className={classes.container}>
        {barsToRender.length > 0 && barsToRender.map((bar, i) => (
          <React.Fragment key={i}>
            <Box className={classes.header} data-testid={'header'}>
              <Typography variant={'body1'}
                          className={isBarFocusedOrDefaultStyle(bar.focused) ? classes.value : classes.title}>
                <PlaceholderText loading={loading}>
                  {!loading ? formatBarValue(bar) : '000'}
                </PlaceholderText>
              </Typography>
              <Typography variant={'body1'} className={classes.title}>
                {!!bar.title && (<PlaceholderText loading={loading}>{bar.title}</PlaceholderText>)}
              </Typography>
              <Box className={classes.warning}>
                {showWarning && (<CoyoWarningBox warningText={warningText}/>)}
              </Box>
            </Box>
            <Box className={classes.progress}>
              <LinearProgress variant="determinate"
                              aria-hidden={true}
                              value={!bar.value ? 0 : Math.ceil(bar.value / bar.maxValue * 100)}
                              classes={{
                                barColorPrimary: isBarFocusedOrDefaultStyle(bar.focused)
                                  ? classes.colorPrimary
                                  : classes.colorSecondaryFocused,
                                colorPrimary: bar.focused === undefined
                                  ? classes.colorSecondaryFocused
                                  : classes.colorSecondary,
                              }}
                              data-testid={'progress'}/>
            </Box>
          </React.Fragment>
        ))}

        {barsToRender.length <= 0 && (
          <Box className={classes.emptyBar} data-testid={'empty-bar'}>
            <Typography variant={'body1'} >
              Sorry, no data available for your selection
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
