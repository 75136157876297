import React, {useState} from 'react';
import {CognitoUser, CognitoUserPool, ICognitoUserData} from 'amazon-cognito-identity-js';
import Typography from '@material-ui/core/Typography';
import {Button, FormControl} from '@material-ui/core';
import CoyoInputLabel from '../../coyo-controls/input/CoyoInputLabel';
import CoyoInputField from '../../coyo-controls/input/CoyoInputField';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import ChangePasswordForm from './ChangePasswordForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center'
    },
    item: {
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      margin: theme.spacing(5),
    },
    textField: {
      margin: theme.spacing(1, 0),
      '& .MuiInputBase-root': {
        height: 40,
        width: '100%',
        alignSelf: 'stretch',
        flexGrow: 0,
        padding: '10px 12px',
        borderRadius: 4,
        border: 'solid 1px #d7dbe0',
      },
      '& .MuiInputLabel-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 0.71,
        transform: 'none',
      },
    },
    button: {
      width: '100%',
      height: theme.spacing(5),
      margin: theme.spacing(7, 0),
      padding: '10px 16px',
      boxShadow: 'none',
      fontSize: 15,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    hint: {
      color: '#FF5630',
      margin: theme.spacing(1, 0),
      textAlign: 'center'
    },
  }),
);

interface Props {
  username: string
}

export default function EnterCodeForm({username}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();

  const [code, setCode] = useState('');
  const [codeEntered, setCodeEntered] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  function onFailure(error: any): void {
    if (error && error.message) {
      if (error.code === 'InvalidParameterException')
        setError('Password does not match the password policy');
      else
        setError(error.message);
    }
  }

  function onSuccess(): void {
    setError(null);
    enqueueSnackbar('Password has been successfully changed', {variant: 'success'});
    history.push('/login');
  }

  function confirmPassword() {
    const userPool: CognitoUserPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_CLIENT_ID || ''
    });
    const userData: ICognitoUserData = {Pool: userPool, Username: username};
    const user: CognitoUser = new CognitoUser(userData);
    if (user) {
      user.confirmPassword(code, newPassword, {onSuccess: onSuccess, onFailure: onFailure});
    }
  }

  function submitEnterCode(event: any) {
    setError(null);
    event.preventDefault();
    if (code.length === 6)
      setCodeEntered(true);
    else
      setShowHint(true);
  }

  return (
    !codeEntered
      ? <form onSubmit={submitEnterCode} noValidate autoComplete="off" data-testid={'enter-code-form'}>
        <Typography variant="h5" className={`${classes.title} ${classes.item}`}>
          Enter the code you received via email
        </Typography>

        <FormControl fullWidth className={`${classes.textField} ${classes.item}`} variant="outlined">
          <CoyoInputLabel htmlFor="standard-adornment-code">Code</CoyoInputLabel>
          <CoyoInputField fullWidth
                          type="text"
                          inputProps={{maxLength: 6}}
                          value={code}
                          onChange={change => setCode(change.target.value)}
                          id="code"
                          data-testid={'code-input'}
                          labelWidth={60}/>
        </FormControl>

        <Button type="submit" className={`${classes.button} ${classes.item}`} variant="contained"
                color="primary">Submit code</Button>

        {showHint && <Typography className={`${classes.hint} ${classes.item}`} data-testid={'error-message'}>
          The code needs to consist of 6 numbers
        </Typography>}
      </form>
      : <ChangePasswordForm submit={confirmPassword}
                            newPassword={newPassword}
                            setNewPassword={setNewPassword}
                            error={error}
                            goBack={() => setCodeEntered(false)}/>
  );
}
