import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useAdoptionQuery} from './AdoptionQuery';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import ColumnWithDataLabels from '../../chart-types/column-chart/column-with-data-labels/ColumnWithDataLabels';
import {Adoption} from '../../schema';
import {Box} from '@material-ui/core';
import {HelpText} from '../../coyo-components/help-box/HelpText';
import {useReportDataContext} from '../../report-data/ReportDataContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      flex: 3,
    },
    chart: {
      padding: theme.spacing(1, 2, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flex: 10,
    },
  }));

interface Props {
  className?: string;
}

const helpText: HelpText = {
  what: 'Adoption is the relationship between licences, user accounts and active users.',
  how: 'Licenses are the number of total available licenses that have been bought. User accounts is the total number ' +
    'of active user accounts. Active users is the number of users, who ' +
    'have been active at least once up until today.',
};

export default function AdoptionCard({className}: Props): ReactElement {
  const classes = useStyles();
  const {loading, data, refetch} = useAdoptionQuery();
  const {reportData} = useReportDataContext();
  const adoption: Adoption | undefined = data?.adoption;

  reportData.adoption = adoption;
  reportData.adoptionLoading = loading;

  const values: number[] = adoption ? [
    adoption?.licenses,
    adoption?.currentActivatedUsers,
    adoption?.uniqueActiveUsers,
  ] : [0, 0, 0];
  const categories = [
    `${(Math.round(values[1] / values[0] * 100)).toString()}%`,
    `${(Math.round(values[2] / values[1] * 100)).toString()}%`,
  ];

  return (
    <CoyoCard id={'adoption-card'}
              title={'Adoption'}
              loading={loading}
              className={className}
              helpText={helpText}
              reload={refetch}>
      <Box className={classes.chart}>
        <ColumnWithDataLabels
          series={{
            name: 'Adoption',
            data: values,
          }}
          loading={loading}
          categories={categories}
          suffixes={[
            'Licenses',
            'User Accounts',
            'Active Users',
          ]}
          helpTexts={[
            `${categories[0]} of licenses are currently in use.`,
            `${categories[1]} of all users were active once up until today.`,
          ]}
          height={220}
          width={'100%'}/>
      </Box>
    </CoyoCard>
  );
}
