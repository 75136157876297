import {Workbook} from 'exceljs';
import {ContentInsightsHistogram, Histogram} from '../../schema';
import {generateIsoDates, toLocaleDate} from '../../charts/DateUtils';
import {Moment} from 'moment-timezone';
import {capitalizeFirstLetter, getValueFromTimeValues} from './ReportUtils';

export function addContentInsightsHistogramReport(workbook: Workbook, contentInsightsHistogram: ContentInsightsHistogram, startDate: Moment, endDate: Moment) {
  addContentInsightsHistogramSheet(workbook, contentInsightsHistogram, 'day', startDate, endDate);
  addContentInsightsHistogramSheet(workbook, contentInsightsHistogram, 'week', startDate, endDate);
  addContentInsightsHistogramSheet(workbook, contentInsightsHistogram, 'month', startDate, endDate);
}

export function addContentInsightsHistogramSheet(workbook: Workbook, contentInsightsHistogram: ContentInsightsHistogram, grouping: keyof Histogram, startDate: Moment, endDate: Moment) {
  const groupingFormatted = capitalizeFirstLetter(grouping);
  const worksheet = workbook.addWorksheet(`Content Insights (Views ${groupingFormatted})`);

  const isoDates = generateIsoDates(startDate, endDate, grouping);
  const {
    likes,
    comments,
    views,
    uniqueViews,
    newUniqueViews,
    viewsShare,
    uniqueViewsShare,
    newUniqueViewsShare,
    viewsTotal,
    uniqueViewsTotal,
    newUniqueViewsTotal,
  } = contentInsightsHistogram;

  worksheet.columns = [
    {header: 'Date', width: 20},
    {header: 'Reactions', width: 10},
    {header: 'Comments', width: 10},
    {header: 'Views', width: 10},
    {header: 'Unique Views', width: 10},
    {header: 'New Unique Views', width: 10},
    ...(viewsShare && uniqueViewsShare && newUniqueViewsShare
      ? [
        {header: 'Views (share)', width: 10},
        {header: 'Unique Views (share)', width: 10},
        {header: 'New Unique Views (share)', width: 10},
      ]
      : []),
    ...(viewsTotal && uniqueViewsTotal && newUniqueViewsTotal
      ? [
        {header: 'Views (total)', width: 10},
        {header: 'Unique Views (total)', width: 10},
        {header: 'New Unique Views (total)', width: 10},
      ]
      : []),
  ];

  isoDates.forEach(isoDate => {
    worksheet.addRow([
      toLocaleDate(isoDate),
      getValueFromTimeValues(likes, grouping, isoDate),
      getValueFromTimeValues(comments, grouping, isoDate),
      getValueFromTimeValues(views, grouping, isoDate),
      getValueFromTimeValues(uniqueViews, grouping, isoDate),
      getValueFromTimeValues(newUniqueViews, grouping, isoDate),
      ...(viewsShare && uniqueViewsShare && newUniqueViewsShare
        ? [
          getValueFromTimeValues(viewsShare, grouping, isoDate),
          getValueFromTimeValues(uniqueViewsShare, grouping, isoDate),
          getValueFromTimeValues(newUniqueViewsShare, grouping, isoDate),
        ]
        : []),
      ...(viewsTotal && uniqueViewsTotal && newUniqueViewsTotal
        ? [
          getValueFromTimeValues(viewsTotal, grouping, isoDate),
          getValueFromTimeValues(uniqueViewsTotal, grouping, isoDate),
          getValueFromTimeValues(newUniqueViewsTotal, grouping, isoDate),
        ]
        : []),
    ]);
  });

  worksheet.autoFilter = `A1:${String.fromCharCode(97 + (worksheet.columns.length - 1))}1`;
}
