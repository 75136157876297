import {Box, IconButton} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, {createRef, ReactElement, useState} from 'react';
import {secondsToHms} from '../../charts/DateUtils';
import HighlightChartModal from '../../charts/highlight-chart-modal/HighlightChartModal';
import CoyoTooltip from '../../coyo-components/tooltip/CoyoTooltip';
import PlaceholderText from '../../placeholder/PlaceholderText';
import {HighlightValues, Histogram} from '../../schema';
import {ReactComponent as NegativeTrend} from '../../svg/chevron-down.svg';
import {ReactComponent as NeutralTrend} from '../../svg/chevron-right.svg';
import {ReactComponent as PositiveTrend} from '../../svg/chevron-up.svg';
import {ReactComponent as FullscreenMaximize} from '../../svg/fullscreen-maximize.svg';
import {EMPTY_SERIES, Series} from '../line-chart/LineChart';
import {v4 as uuid} from 'uuid';
import CoyoBasicMenu from '../../coyo-components/basic-menu/CoyoBasicMenu';
import {getMenuItems} from '../../coyo-components/card/CoyoCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlightBox: {
      flex: 1,
      minWidth: theme.spacing(20),
      textAlign: 'left',
      padding: theme.spacing(2, 3, 2),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    main: {
      display: 'flex',
      justifyContent: 'flex-start',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menu: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    },
    number: {
      lineHeight: 1,
      fontSize: 28,
      height: 28,
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    chartModalButton: {
      marginLeft: 5,
    },
    title: {
      paddingTop: 8,
      fontSize: 13,
      fontWeight: 600,
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    trend: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      lineHeight: '16px',
      fontWeight: 600,
    },
    positiveTrend: {
      color: theme.palette.success.main,
    },
    svgFillPositiveTrend: {
      fill: theme.palette.success.main,
    },
    negativeTrend: {
      color: theme.palette.error.main,
    },
    svgFillNegativeTrend: {
      fill: theme.palette.error.main,
    },
    neutralTrend: {
      color: theme.palette.warning.main,
    },
    svgFillNeutralTrend: {
      fill: theme.palette.warning.main,
    },
    svg: {
      height: '16px',
      verticalAlign: 'bottom',
    },
    icon: {
      fill: theme.palette.text.secondary,
      marginBottom: 10,
    },
    '@media print': {
      chartModalButton: {
        display: 'none',
      },
    },
    svgIcon: {
      width: 20,
      height: 20,
    },
  }));

export interface Props {
  loading: boolean,
  title: string,
  data?: HighlightValues;
  histogramData?: () => Promise<Histogram>;
  icon?: ReactElement;
  className?: string;
  valueType: ValueType;
  helpText: string;
  reload?: () => void;
  filterTimeRange?: string[];
}

export type ValueType = 'number' | 'seconds' | 'percent';

export default function HighlightBox({
                                       loading,
                                       title,
                                       data,
                                       histogramData,
                                       icon,
                                       className,
                                       valueType,
                                       helpText,
                                       reload,
                                       filterTimeRange,
                                     }: Props): ReactElement {
  const classes = useStyles();
  const [chartModalOpen, setChartModalOpen] = useState<boolean>(false);
  const [loadingModalSeries, setLoadingModalSeries] = useState<boolean>(true);
  const [series, setSeries] = useState<Series[]>([]);
  const componentReference = createRef<HTMLDivElement>();
  const menuId = `menu-${uuid()}`;
  const [modalMounted, setModalMounted] = useState<boolean>(false);

  function renderValue(value?: number) {
    let displayText;
    if (loading) {
      displayText = <PlaceholderText loading={true}>0000</PlaceholderText>;
    } else if (!value && value !== 0) {
      displayText = '-';
    } else if (valueType === 'number') {
      displayText = value.toLocaleString();
    } else if (valueType === 'seconds') {
      displayText = secondsToHms(value);
    } else if (valueType === 'percent') {
      displayText = `${value} %`;
    }

    return <Typography className={classes.number}>{displayText}</Typography>;
  }

  function renderTrend(trendPercent?: number) {
    if (!trendPercent && trendPercent !== 0) return null;

    const roundedTrendPercent = Math.abs(trendPercent || 0) < 1
      ? Math.round(trendPercent * 100) / 100
      : Math.round(trendPercent);

    if (roundedTrendPercent > 0) return (
      <span className={[classes.trend, classes.positiveTrend].join(' ')}>
        <PositiveTrend className={`${classes.svg} ${classes.svgFillPositiveTrend}`}
                       data-testid={'positive-trend'}/> {roundedTrendPercent}%</span>
    );

    if (roundedTrendPercent < 0) return (
      <span className={[classes.trend, classes.negativeTrend].join(' ')}>
        <NegativeTrend className={`${classes.svg} ${classes.svgFillNegativeTrend}`}
                       data-testid={'negative-trend'}/> {Math.abs(roundedTrendPercent)}%
      </span>);

    if (roundedTrendPercent === 0) return (
      <span className={[classes.trend, classes.neutralTrend].join(' ')}>
        <NeutralTrend className={`${classes.svg} ${classes.svgFillNeutralTrend}`}
                      data-testid={'neutral-trend'}/> {Math.abs(roundedTrendPercent)}%
      </span>);
  }

  async function openModal() {
    if (!histogramData) return;
    setChartModalOpen(true);

    if (modalMounted) return;
    setLoadingModalSeries(true);
    setModalMounted(true);
    try {
      const data = await histogramData();
      const series: Series[] = [{
        name: title,
        dataByRange: data || EMPTY_SERIES,
      }];
      setSeries(series);
    } finally {
      setLoadingModalSeries(false);
    }
  }

  function renderHighlightValueBox() {
    return <Box className={classes.main} data-testid={'highlight-value'}>
      {renderValue(data?.total)}
    </Box>;
  }

  return (
    <Box {...{ ref: componentReference }} className={[classes.highlightBox, className].join(' ')} data-testid={'highlight-box'}>
      {!loading && renderTrend(data?.trendPercent)}
      {icon &&
      <Box className={classes.icon}>
        {icon}
      </Box>}
      {!loading &&
      <CoyoTooltip title={<React.Fragment>{helpText}</React.Fragment>}
                    placement={'top-start'} data-testid={'highlight-helptext-tooltip'}>
        {renderHighlightValueBox()}
      </CoyoTooltip>}
      {loading && renderHighlightValueBox()}
      <Typography className={classes.title}>
        <PlaceholderText loading={loading}>{title}</PlaceholderText>
      </Typography>

      <Box display={'inherit'} id={menuId} className={classes.menu}>
        {!loading && histogramData &&
        (<IconButton size={'small'}
                      className={classes.chartModalButton}
                      onClick={() => openModal()}
                      data-testid={'highlight-modal-button'}
                      aria-label={`Show ${title} in fullscreen`}>
            <FullscreenMaximize fontSize="small"/>
          </IconButton>
        )}
        {!loading && !!reload && <CoyoBasicMenu items={getMenuItems(title, reload, filterTimeRange, classes, componentReference, menuId)} parentTitle={title}/>}
      </Box>
      <HighlightChartModal title={title} series={series} loading={loadingModalSeries}
                            open={chartModalOpen} onClose={() => setChartModalOpen(false)}/>
    </Box>
  );
}
