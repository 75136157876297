import React, {useState} from 'react';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import Bars, {Bar} from '../../chart-types/bars/Bars';
import {AvgSessionLength, Client, Visit} from '../../schema';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {useVisitsQuery} from './VisitsQuery';
import {makeStyles} from '@material-ui/core/styles';
import {Box, MenuItem, Theme, Typography} from '@material-ui/core';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '&:focus': {
      background: theme.palette.action.hover,
    },
  },
  visitsCard: {
    height: '100%',
    display: 'grid',
  },
  barStyling: {
    position: 'relative',
    padding: theme.spacing(2, 3),
  },
}));

const helpText = {
  what: <React.Fragment>
    {`Visits show how users access COYO and how long the respective accesses were used on average per user.`}
    <Typography component={'span'}>
      <br/>
      <br/>
      {`We distinguish between the following access points:`}
      <ul style={{marginTop: 0}}>
        <li>Web (Desktop)</li>
        <li>Web (Mobile)</li>
        <li>COYO Classic App</li>
        <li>COYO App (iOS)</li>
        <li>COYO App (Android)</li>
      </ul>
    </Typography>
  </React.Fragment>,
  how: 'Each time a user uses COYO for at least 3 seconds, a visit is counted. ' +
    'To count as an active visit, a window must be actively in the foreground or the app must be actively open. ' +
    'When indicating the average duration of use, all session lengths are added and averaged by the number of active users.',
  important: 'Currently, both old and new device information is being collected until COYO version 36 is released. ' +
    'Therefore, during the transition period, information on visits may be available according to the old scheme ' +
    '(distinction between Engage and Web & COYO Classic App) and according to the new scheme (see above). The new information cannot be split retroactively.',
};

interface Props {
  senderId?: string;
  className?: string;
}

export type Tab = 'amount' | 'duration'
export default function VisitsCard({senderId, className}: Props) {
  const classes = useStyles();

  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = useVisitsQuery(fromDay, toDay, senderId || null);
  const [selectedTab, setSelectedTab] = useState<Tab>('amount');
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {reportData} = useReportDataContext();
  const visits: Visit[] = data?.visits || [];
  const sessions: AvgSessionLength[] = data?.avgSessionLength || [];

  const clientNames: { [client in Client]: string } = {
    'combined': 'Combined',
    'web-desktop': 'Web (Desktop)',
    'mobile-browser': 'Web (Mobile)',
    'mobile-app-classic': 'COYO Classic App',
    'mobile-app-ios': 'COYO App (iOS)',
    'mobile-app-android': 'COYO App (Android)',
    'web': 'Web & COYO Classic App',
    'engage': 'COYO App',
  };

  const combined = visits.find(visit => visit.client === 'combined');
  let amountBars: Bar[] = [];
  if (combined) {
    amountBars = visits
      .filter(visit => visit.client !== 'combined')
      .sort((a, b) => b.total - a.total)
      .map(visit => {
        const ROUND_FACTOR = 10;
        return ({
          title: clientNames[visit.client],
          value: Math.round(((visit.total / combined.total) * 100) * ROUND_FACTOR) / ROUND_FACTOR || 0,
          maxValue: 100,
          dataType: 'percent',
        });
      });
  }


  const maxAvgSessionLength = Math.max(0, ...sessions.map(session => session.seconds));
  const durationBars: Bar[] = sessions
    .filter(visit => visit.client !== 'combined')
    .sort((a, b) => b.seconds - a.seconds)
    .map(session => {
      return ({
        title: clientNames[session.client],
        value: session.seconds,
        maxValue: maxAvgSessionLength,
        dataType: 'seconds-to-duration',
      });
    });

  let bars: Bar[] = [];
  if (selectedTab === 'amount')
    bars = amountBars;
  if (selectedTab === 'duration')
    bars = durationBars;


  reportData.visits = Object
    .values(clientNames)
    .map(clientName => ({
      title: clientName,
      amount: amountBars.find(bar => bar.title === clientName)?.value || 0,
      durationSeconds: durationBars.find(bar => bar.title === clientName)?.value || 0,
    })).filter(({amount, durationSeconds}) => amount > 0 || durationSeconds > 0);
  reportData.visitsLoading = loading;

  return (
    <Box className={`${classes.visitsCard} ${className}`}>
      <CoyoCard id={'visits-card'}
                title={'Visits'}
                loading={loading}
                helpText={helpText}
                filtered={appliedGlobalFilters.length > 0}
                filterTimeRange={generateNumericDates(startDate, endDate)}
                controls={(
                  <CoyoSelect data-testid={'visits-selection'}
                              SelectProps={{
                                value: selectedTab,
                                onChange: ({target}) => setSelectedTab(target.value as Tab),
                              }}
                              label={'Filter visits'}>
                    <MenuItem className={classes.menuItem} value={'amount'} disabled={selectedTab === 'amount'}><span>Amount</span></MenuItem>
                    {!senderId && <MenuItem className={classes.menuItem} value={'duration'} disabled={selectedTab === 'duration'}><span>∅ Duration</span></MenuItem>}
                  </CoyoSelect>
                )}
                reload={refetch}>
        <Bars bars={bars} loading={loading} skeletonBarAmount={3} classNames={classes.barStyling}/>
      </CoyoCard>
    </Box>
  );
}
