import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {useSettingsContext} from '../../settings/SettingsContext';

export const useAudienceQuery = (from: string, to: string, senderId: string | null) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {timezone} = useSettingsContext();

  return useApiQuery(gql`
      query audience($from: String!, $to: String!, $timezone: String!, $senderId: String, $globalFilters: [GlobalFilter!]) {
          audience(from: $from, to: $to, timezone: $timezone, senderId: $senderId, globalFilters: $globalFilters) {
              department {
                  name
                  total
              }
              location {
                  name
                  total
              }
              company {
                  name
                  total
              }
              country {
                  name
                  total
              }
              grade {
                  name
                  total
              }
              platform {
                  name
                  total
              }
              bereich {
                  name
                  total
              }
          }
      }
  `, {from, to, senderId, timezone, globalFilters: appliedGlobalFilters});
};

