import {Workbook} from 'exceljs';
import {Adoption} from '../../schema';

export function addAdoptionReport(workbook: Workbook, adoptionData: Adoption) {
  const worksheet = workbook.addWorksheet(`Adoption`);
  worksheet.columns = [
    {header: 'Licenses', width: 20},
    {header: 'Licenses -> User Accounts', width: 20, style: {numFmt: '0.00%'}},
    {header: 'User Accounts', width: 20},
    {header: 'User Accounts -> Active Users', width: 20, style: {numFmt: '0.00%'}},
    {header: 'Active Users', width: 20},
  ];
  const licensesToUserAccounts = Math.round(adoptionData.currentActivatedUsers / adoptionData.licenses * 100) / 100;
  const userAccountsToUniqueActiveUsers = Math.round(adoptionData.uniqueActiveUsers / adoptionData.currentActivatedUsers * 100) / 100;

  worksheet.addRow([adoptionData.licenses, licensesToUserAccounts, adoptionData.currentActivatedUsers, userAccountsToUniqueActiveUsers, adoptionData.uniqueActiveUsers]);

  worksheet.autoFilter = 'A1:E1';
}
