import { gql } from '@apollo/client';
import { useApiQuery } from '../apollo/ApiQuery';

export const useSettingsQuery = () => {
    return useApiQuery(gql`
                query settings {
                    settings {
                        disabledFeatures
                        availableTimezones
                        tenantId
                        minGroupSize
                        globalFilters
                        accessType
                    }
                },
      `);
};
