import React, {useMemo} from 'react';
import {Box, useTheme} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import ChartComponent, {Chart} from 'react-chartjs-2';
import {ChartData} from 'chart.js';
import {MatrixController, MatrixElement} from 'chartjs-chart-matrix';
import {merge} from 'lodash';
import {FallbackTable} from '../fallback-table/FallbackTable';
import {getHeatmapChartOptions} from './HeatmapChartOptions';
import {useSettingsContext} from '../../settings/SettingsContext';
import {notAvailablePlaceHolder} from '../line-chart/LineChart';

interface Props {
  series: HeatmapSeries[];
  height?: number | string;
  width?: number | string;
  maxValue: number;
  range: number;
  customTooltip?: Function;
  customXLabels?: (x: number) => string;
  customYLabels?: (y: number) => string;
  loading: boolean;
  threshold?: boolean;
}

export interface Labels {
  x: string[];
  y: string[];
}

export interface HeatmapSeries {
  x: number,
  y: number,
  v: number
}

export interface HeatmapDatapoint extends HeatmapSeries {
  backgroundColor: string;
  label: string;
}

export default function Heatmap({
                                  series,
                                  height,
                                  width,
                                  maxValue,
                                  range,
                                  customTooltip,
                                  customXLabels,
                                  customYLabels,
                                  loading,
                                  threshold,
                                }: Props) {
  Chart.register(MatrixController, MatrixElement);
  const theme: Theme = useTheme();
  const minColor = theme.palette.type === 'dark' ? '#0E2642' : '#F2F8FE';
  const maxColor = theme.palette.type === 'dark' ? '#0073E6' : '#0073E6';
  const colors: string[] = useMemo(() => {
    return interpolateColors(minColor, maxColor, range);
  }, [range, minColor, maxColor]);
  maxValue = maxValue === 0 ? 1 : maxValue;
  const {minGroupSize} = useSettingsContext();

  const labels: Labels = {
    x: customXLabels ?
      Array.from(Array(Math.max(...series.map(series => series.x)) + 1).keys()).map(label => customXLabels(label)) :
      Array.from(Array(Math.max(...series.map(series => series.x)) + 1).keys()).map(String),
    y: customYLabels ?
      Array.from(Array(Math.max(...series.map(series => series.y)) + 1).keys()).map(label => customYLabels(label)) :
      Array.from(Array(Math.max(...series.map(series => series.y)) + 1).keys()).map(String),
  };

  const fallbackContent = <FallbackTable
    headerRowCount={2}
    headerRow={labels.x}
    contentRows={labels.y.map((label, indexY) =>
      [label, ...labels.x.map((label, indexX) => {
        const value = series.filter(series => series.y === indexY).find(series => series.x === indexX)?.v || 0;
        return threshold && value < minGroupSize ? notAvailablePlaceHolder : value.toString();
      })],
    )}/>;

  const heatmapDatapoints: HeatmapDatapoint[] = series.map(series =>
    Object.assign(series, {
      backgroundColor: colors[Math.round(series.v / maxValue * (range - 1))],
      label: threshold && series.v < minGroupSize ? notAvailablePlaceHolder : series.v.toString(),
    })
  );

  const data: ChartData<'matrix'> = {
    datasets: [
      {
        label: '',
        data: heatmapDatapoints,
        width: ({chart}) => (chart.chartArea || {}).width / labels.x.length - 1,
        height: ({chart}) => (chart.chartArea || {}).height / labels.y.length - 1,
        backgroundColor: heatmapDatapoints.map(datapoint => datapoint.backgroundColor),
        hoverBackgroundColor: heatmapDatapoints.map(datapoint => datapoint.backgroundColor),
      },
    ],
  };

  const options = getHeatmapChartOptions(theme, labels);

  customTooltip && merge(options, customTooltip());

  return (
    <Box width={width || 'auto'}>
      <Box height={height || '100%'} width={width || '100%'}>
        <ChartComponent
          type={'matrix'}
          data={loading ? {labels: [], datasets: []} : data}
          options={options}
          fallbackContent={fallbackContent}
          aria-hidden={true}
          data-testid={'matrix-chart'}/>
        {fallbackContent}
      </Box>
    </Box>
  );
}


function rgbArrayToHex(rgb: number[]): string {
  return '#' + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

function hexToRgbArray(hex: string): number[] | null {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ] : null;
}

function _interpolateColor(color1: number[], color2: number[], factor: number) {
  if (arguments.length < 3)
    factor = 0.5;
  const rgb = color1.map((colorNumber, index) =>
    Math.round(colorNumber + factor * (color2[index] - colorNumber)));
  return rgbArrayToHex(rgb);
}

function interpolateColors(from: string, to: string, steps: number) {
  const stepFactor = 1 / (steps - 1);
  const color1: number[] = hexToRgbArray(from) || [];
  const color2: number[] = hexToRgbArray(to) || [];

  return Array(steps).fill(null).map((element, index) => _interpolateColor(color1, color2, stepFactor * index));
}
