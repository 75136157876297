import {Workbook} from 'exceljs';
import {HighlightValues, SenderHighlights} from '../../schema';

export interface WorkspaceHighlightsReport extends SenderHighlights {
  members: HighlightValues
}

export function addWorkspaceHighlightsReport(workbook: Workbook, workspaceHighlightsData: WorkspaceHighlightsReport) {
  const worksheet = workbook.addWorksheet('Community Highlights');
  worksheet.columns = [
    {header: 'Highlights Type', width: 20},
    {header: 'Value', width: 10},
    {header: 'Trend', width: 10, style: {numFmt: '0%'}},
  ];

  let {likes, comments, shares, members} = workspaceHighlightsData;
  worksheet.addRow([
    'Reactions',
    likes?.total,
    likes?.trendPercent ? likes?.trendPercent / 100 : ''
  ]);
  worksheet.addRow([
    'Comments',
    comments?.total,
    comments?.trendPercent ? comments?.trendPercent / 100 : ''
  ]);
  worksheet.addRow(['Shares',
    shares?.total,
    shares?.trendPercent ? shares?.trendPercent / 100 : ''
  ]);
  worksheet.addRow(['Members',
    members?.total,
    members?.trendPercent ? members?.trendPercent / 100 : ''
  ]);

  worksheet.autoFilter = 'A1:D1';
}
