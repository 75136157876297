import {DocumentNode, QueryResult, ServerError, useQuery} from '@apollo/client';
import {WebApiResponse} from '../schema';
import {useSnackbar} from 'notistack';
import {useAuthContext} from '../auth/AuthContext';

export const useApiQuery = (query: DocumentNode, variables?: object): QueryResult<WebApiResponse, any> => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthorized } = useAuthContext();

  return useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: !isAuthorized,
    onError: error => {
      if (error.networkError && (error.networkError as ServerError).statusCode !== 401)
        enqueueSnackbar(error.message, { variant: 'error', persist: true });
    },
  });
};
