import React, { ReactChild, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderToolbar from '../header-toolbar/HeaderToolbar';
import SideNavigation from '../side-navigation/SideNavigation';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LayoutContext } from './LayoutContext';
import CoyoLoginWarningBox from '../coyo-components/login-warning/CoyoLoginWarningBox';
import { useAuthContext } from '../auth/AuthContext';

const drawerWidth = 280;
const toolbarHeight = 100;


interface Props {
  children: ReactChild;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    coyocontent: {
      overflow: 'hidden',
      width: '100%',
      position: 'relative',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    coyocontentInner: {
      maxWidth: '1275px',
      margin: 'auto',
      padding: theme.spacing(2, 6),
    },
    loginWarning: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.disabled,
      padding: theme.spacing(2, 2.5),
      '& a' : {
        color: theme.palette.text.disabled,
        textDecoration: 'underline'
      }
    },
    '@media print': {
      root: {
        width: '290mm',
      },
      coyocontent: {
        padding: theme.spacing(3),
        width: '290mm',
      },
    },
  }),
);

export default function Layout({ children }: Props) {
  const [isMinimalisticView, setMinimalisticView] = useState<boolean>(false);
  const { type } = useAuthContext();

  const showLoginWarning = type === 'cognito';

  const classes = useStyles();
  return (
    <LayoutContext.Provider value={{ isMinimalisticView, setMinimalisticView }}>
      <Box className={classes.root}>
        <CssBaseline />

        <SideNavigation width={drawerWidth} />
        <Box className={classes.wrapper}>
          {showLoginWarning && <CoyoLoginWarningBox className={classes.loginWarning} closeable />}
          <HeaderToolbar height={toolbarHeight} drawerWidth={drawerWidth} />
          <main className={classes.coyocontent}>
            <Box className={classes.coyocontentInner}>
              {children}
            </Box>
          </main>
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
}
