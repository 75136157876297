import {Button, FormControl, Link} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {CognitoUser} from 'amazon-cognito-identity-js';
import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useAuthContext} from '../../auth/AuthContext';
import CoyoInputField from '../../coyo-controls/input/CoyoInputField';
import CoyoInputLabel from '../../coyo-controls/input/CoyoInputLabel';
import {CoyoPasswordInput} from '../../coyo-controls/password-input/CoyoPasswordInput';
import CoyoLoginWarningBox from '../../coyo-components/login-warning/CoyoLoginWarningBox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
    },
    item: {
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      margin: theme.spacing(6.75, 7, 5),
      fontSize: 24,
      fontWeight: 600,
      paddingBottom: 0,
    },
    loginWarning: {
      backgroundColor: theme.palette.text.disabled,
      borderRadius: 4,
      marginBottom: theme.spacing(5),
      padding: theme.spacing(2, 2.5),
    },
    link: {
      alignSelf: 'flex-start',
    },
    textField: {
      margin: theme.spacing(1, 0),

      '& .MuiInputBase-root': {
        height: 40,
        width: '100%',
        alignSelf: 'stretch',
        flexGrow: 0,
        padding: '10px 12px',
        borderRadius: 4,
        border: 'solid 1px #d7dbe0',
      },
      '& .MuiInputLabel-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 0.71,
        transform: 'none',
      },
    },
    button: {
      width: '100%',
      height: theme.spacing(5),
      margin: theme.spacing(4, 0, 0),
      padding: '10px 16px',
      boxShadow: 'none',
      fontSize: 15,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    errorText: {
      color: '#FF5630',
      margin: theme.spacing(1),
      textAlign: 'center',
    },
  }),
);

interface Props {
  onSuccess: () => void;
  onNewPasswordRequired: (cognitoUser: CognitoUser) => void;
  onMfaRequired: (cognitoUser: CognitoUser) => void;
}

export default function LoginForm({onSuccess, onNewPasswordRequired, onMfaRequired}: Props) {
  const classes = useStyles();
  const authentication = useAuthContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  async function submit(event: any) {
    event.preventDefault();
    setError(null);

    if (authentication.type === 'cognito') {
      try {
        const loginResult = await authentication.login(username, password);
        if (loginResult?.result === 'PASSWORD_CHANGE_REQUIRED') {
          onNewPasswordRequired(loginResult.cognitoUser);
          return;
        }
        if (loginResult?.result === 'CUSTOM_CHALLENGE') {
          onMfaRequired(loginResult.cognitoUser);
          return;
        }

        onSuccess();
      } catch (error) {
        setError((error as Record<string, string>)?.message);
      }
    }
  }

  return (
    <form onSubmit={submit} noValidate autoComplete="off" data-testid={'login-form'} className={classes.form}>
      <Typography variant="h1" className={`${classes.title} ${classes.item}`}>Sign into your account</Typography>

      <CoyoLoginWarningBox className={classes.loginWarning}/>

      <FormControl fullWidth className={`${classes.textField} ${classes.item}`}>
        <CoyoInputLabel shrink htmlFor="standard-adornment-username">Username</CoyoInputLabel>
        <CoyoInputField fullWidth
                        type="text"
                        value={username}
                        onChange={change => setUsername(change.target.value)}
                        id="username"
                        autoComplete={'username'}
                        inputProps={{
                          'aria-label': 'username',
                        }}
                        data-testid={'username-input'}/>
      </FormControl>

      <CoyoPasswordInput
        id={'current-password'}
        name={'current-password'}
        className={`${classes.textField} ${classes.item}`}
        value={password}
        onChange={change => setPassword(change.target.value)}/>

      <Link component={RouterLink}
            className={`${classes.link} ${classes.item}`}
            to={'/forgot-password'}
            variant={'body2'}
            data-testid={`forgot-password-link`}>
        Forgot password?
      </Link>

      <Button type="submit" className={classes.button} variant="contained" color="primary" data-testid={'login-button'}>
        Sign in
      </Button>


      {error && (
        <Typography className={`${classes.errorText} ${classes.item}`} data-testid={'error-message'}>
          {error}
        </Typography>
      )}
    </form>
  );
}
