export function getParameterByName(url: string, parameterName: string): string | null {
  const results = url.match(`[?&]${parameterName}=([^&#]*|&|#|$)`);
  if (!results) return null;
  if (!results[1]) return '';
  return decodeURIComponent(results[1]);
}

function hasParam(url: string, parameter: string): boolean {
  return !!getParameterByName(url, parameter);
}

export function hasParams(url: string, ...parameters: string[]): boolean {
  return parameters
    .map(param => hasParam(url, param))
    .every(value => value);
}
