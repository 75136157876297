import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {useSettingsContext} from '../../settings/SettingsContext';


export const useUserActivityQuery = (from: string, to: string) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {timezone} = useSettingsContext();
  return useApiQuery(gql`
      query userActivity($from: String!, $to: String!, $timezone: String!, $globalFilters: [GlobalFilter!]) {
          consumingUsers(from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters) {
              day {
                  isoDate
                  value
              }
              week {
                  isoDate
                  value
              }
              month {
                  isoDate
                  value
              }
          }
          interactingUsers(from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters) {
              day {
                  isoDate
                  value
              }
              week {
                  isoDate
                  value
              }
              month {
                  isoDate
                  value
              }
          }
      }
  `, {from, to, timezone, globalFilters: appliedGlobalFilters});
};
