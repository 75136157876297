import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useSettingsContext} from '../../settings/SettingsContext';

export const useHighlightsMessagesQuery = (from: string, to: string) => {
  return useApiQuery(gql`
      query highlights($from: String!, $to: String!) {
          highlights{
              messages {
                  highlights(from: $from, to: $to) {
                      total
                      trendPercent
                  }
              }
          }
      }
  `, {from, to});
};

export const useHighlightsPagesQuery = (from: string, to: string) => {
  return useApiQuery(gql`
      query highlights($from: String!, $to: String!) {
          highlights{
              pages(from: $from, to: $to) {
                  total
                  trendPercent
              }
          }
      }
  `, {from, to});
};

export const useHighlightsWorkspacesQuery = (from: string, to: string) => {
  return useApiQuery(gql`
      query highlights($from: String!, $to: String!) {
          highlights{
              workspaces(from: $from, to: $to) {
                  total
                  trendPercent
              }
          }
      }
  `, {from, to});
};

export const useHighlightsAvgSessionSecondsQuery = (from: string, to: string) => {
  const {timezone} = useSettingsContext();
  return useApiQuery(gql`
      query highlights($from: String!, $to: String!, $timezone: String!) {
          highlights{
              avgSessionSeconds(from: $from, to: $to, timezone: $timezone) {
                  total
                  trendPercent
              }
          }
      }
  `, {from, to, timezone});
};
