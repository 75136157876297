import React, {ReactElement, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Typography} from '@material-ui/core';
import CoyoTooltip from '../tooltip/CoyoTooltip';
import {ReactComponent as HelpIcon} from '../../svg/help-circle.svg';
import {HelpText} from './HelpText';

interface Props {
  helpText: HelpText;
}

export default function CoyoHelpBox({helpText}: Props): ReactElement {

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      helpBox: {
        paddingLeft: theme.spacing(1),
      },
      '@media print': {
        helpBox: {
          display: 'none',
        },
      },
    }),
  );

  const classes = useStyles();

  const [toolTipOpen, setToolTipOpen] = useState(false);

  const helpTextTemplate =
    <React.Fragment>
      <Typography variant={'h3'}>What?</Typography>
      <Typography component={'span'} paragraph={true} data-testid={'what-text'}>{helpText.what}</Typography>
      <Typography variant={'h3'}><br/>How?</Typography>
      <Typography component={'span'} paragraph={true} data-testid={'how-text'}>{helpText.how}</Typography>
      {helpText.important && (<span>
        <br/>
        <Typography variant={'h3'}><br/>Important:</Typography>
        <Typography component={'span'} paragraph={true}>{helpText.important}</Typography>
      </span>)}
    </React.Fragment>;

  return (
    <div className={classes.helpBox}>
      <CoyoTooltip title={helpTextTemplate} placement={'bottom-start'} open={toolTipOpen}
                   onClose={() => setToolTipOpen(false)} clickable>
        <IconButton size="small" aria-label="help" onClick={() => setToolTipOpen(true)}>
          <HelpIcon fontSize="small" height="22px" width="22px"/>
        </IconButton>
      </CoyoTooltip>
    </div>
  );
}
