import {useState} from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import {useAuthContext} from '../../auth/AuthContext';
import {CognitoUser} from 'amazon-cognito-identity-js';

interface Props {
  cognitoUser: CognitoUser
  onSuccess: () => void
}

export default function SetFirstPasswordForm({cognitoUser, onSuccess}: Props) {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const authentication = useAuthContext();

  async function submit() {
    setError(null);

    if (authentication.type === 'cognito') {
      try {
        await authentication.changePassword(cognitoUser, newPassword);
        onSuccess();
      } catch (error) {
        setError((error as Record<string, string>)?.message);
      }
    }
  }

  return (
    <ChangePasswordForm submit={submit} error={error} newPassword={newPassword} setNewPassword={setNewPassword}/>
  );
}
