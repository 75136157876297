import {Workbook} from 'exceljs';

export interface VisitReport {
  title: string
  amount: number
  durationSeconds: number
}

export function addVisitsReport(workbook: Workbook, visitsData: VisitReport[]) {
  const worksheet = workbook.addWorksheet('Visits');

  worksheet.columns = [
    {header: 'Client', width: 30},
    {header: 'Amount', width: 30, style: {numFmt: '0%'}},
    {header: 'Ø Duration (Seconds)', width: 30},
  ];

  visitsData.forEach(visit => {
    worksheet.addRow([
      visit.title,
      visit.amount / 100,
      visit.durationSeconds
    ]);
  });

  worksheet.autoFilter = 'A1:C1';
}
