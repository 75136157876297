import React, {useEffect, useState} from 'react';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import Bars, {Bar} from '../../chart-types/bars/Bars';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {Box, Button, MenuItem} from '@material-ui/core';
import {Audience, AudienceEntry} from '../../schema';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {useAudienceQuery} from './AudienceQuery';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '&:focus': {
      background: theme.palette.action.hover,
    },
  },
  bottomControls: {
    display: 'flex',
  },
  showMoreButton: {
    flex: 1,
    margin: theme.spacing(1, 3),
  },
  audienceCard: {
    height: '100%',
    display: 'grid',
  },
  barStyling: {
    position: 'relative',
    padding: theme.spacing(2, 3),
  },
  '@media print': {
    bottomControls: {
      display: 'none',
    },
  },
}));

export const PAGE_ROW_AMOUNT = 4;

const helpText = {
  what: 'Audience shows, who was accessing COYO in the configured time range sorted by company, department and location.',
  how: 'To calculate the Audience, the profile fields of a user are evaluated. ' +
    'Each user, who was active at least once for more than 3 seconds in the configured time range is counted.',
  important: 'If a group of users is small, the group will not be displayed seperatly, ' +
    'but counted as "Other" due to GDPR compliance. If a user has not filled out a field in his profile, the user ' +
    'will be counted towards "Other" in this category.',
};

interface Props {
  senderId?: string;
  className?: string;
}

export type AudienceType = 'company' | 'department' | 'location' | 'country' | 'grade' | 'platform' | 'bereich';
export default function AudienceCard({senderId, className}: Props) {
  const classes = useStyles();
  const [audienceType, setAudienceType] = useState<AudienceType>('department');
  const [bars, setBars] = useState<Bar[]>([]);
  const [visibleRowsAmount, setVisibleRowsAmount] = useState<number>(PAGE_ROW_AMOUNT);
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = useAudienceQuery(fromDay, toDay, senderId || null);
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {reportData} = useReportDataContext();
  const [audience, setAudience] = useState<Audience | undefined>();

  reportData.audience = audience;
  reportData.audienceLoading = loading;

  useEffect(() => {
    if (!!data?.audience) {
      const tempAudience = Object.fromEntries(Object.entries(data.audience).filter(([_, v]) => v !== null));
      const audienceKeys = Object.keys(tempAudience);

      if (!audienceKeys.includes(audienceType)) {
        setBars(audienceEntriesToBars(visibleRowsAmount, tempAudience[audienceKeys[0] as AudienceType]));
        setAudienceType(audienceKeys[0] as AudienceType);
      } else {
        setBars(audienceEntriesToBars(visibleRowsAmount, tempAudience[audienceType]));
      }
      setAudience(() => ({
        ...tempAudience,
      }) as Audience);
    }
  }, [visibleRowsAmount, audienceType, loading, data]);

  useEffect(() => {
    if (!!audience && !!audienceType)
      setBars(audienceEntriesToBars(visibleRowsAmount, audience[audienceType]!));
  }, [audience, visibleRowsAmount, audienceType]);

  function renderMenuItems() {
    return !!audience && Object.keys(audience)
      .filter(audienceValue => audienceValue !== '__typename')
      .map(audienceValue =>
        (<MenuItem className={classes.menuItem} key={audienceValue} value={audienceValue}
                   disabled={audienceType === audienceValue}>
          <span>{audienceValue.charAt(0).toUpperCase() + audienceValue.slice(1)}</span>
        </MenuItem>));
  }

  return (
    <Box className={`${classes.audienceCard} ${className}`}>
      <CoyoCard id={'audience-card'}
                title={'Audience'}
                loading={loading}
                helpText={helpText}
                filtered={appliedGlobalFilters.length > 0}
                filterTimeRange={generateNumericDates(startDate, endDate)}
                controls={(!!audience && !!audienceType &&
                    <CoyoSelect data-testid={'audience-selection'}
                                SelectProps={{
                                  value: audienceType,
                                  onChange: ({target}) => {
                                    setAudienceType(target.value as AudienceType);
                                    setVisibleRowsAmount(PAGE_ROW_AMOUNT);
                                  },
                                }}
                                label={'Filter audience'}>
                      {renderMenuItems()}
                    </CoyoSelect>
                )}
                reload={refetch}>
        <Bars bars={bars} loading={loading} skeletonBarAmount={PAGE_ROW_AMOUNT} classNames={classes.barStyling}/>
        {visibleRowsAmount < ((!!audience && !!audienceType) ? audience[audienceType]!.length : 0) && renderShowMoreButton()}
      </CoyoCard>
    </Box>
  );

  function renderShowMoreButton() {
    return (
      <Box className={classes.bottomControls}>
        <Button className={classes.showMoreButton}
                onClick={() => setVisibleRowsAmount(visibleRowsAmount + PAGE_ROW_AMOUNT)}
                data-testid={'show-more-button'}>
          Show more
        </Button>
      </Box>
    );
  }
}

export function audienceEntriesToBars(visibleRowsAmount: number, audienceEntries: AudienceEntry[]): Bar[] {
  if (!audienceEntries)
    return [];

  const sum = audienceEntries.reduce((sum, entry) => sum + entry.total, 0);
  const ROUND_FACTOR = 10;
  return audienceEntries.map((entry: AudienceEntry) => {
    const percent = entry.total / sum * 100;
    const bar: Bar = {
      title: entry.name,
      value: Math.round(percent * ROUND_FACTOR) / ROUND_FACTOR,
      maxValue: 100,
      dataType: 'percent',
    };
    return bar;
  }).sort((barOne: Bar, barTwo: Bar) => barsByPercentDesc(barOne, barTwo))
    .splice(0, visibleRowsAmount);
}

function barsByPercentDesc(barOne: Bar, barTwo: Bar) {
  if (barOne.value < barTwo.value) return 1;
  if (barOne.value > barTwo.value) return -1;
  return 0;
}
