import {ChartOptions} from 'chart.js';
import {Theme} from '@material-ui/core/styles';

export function getChartOptions(theme: Theme): ChartOptions {
  return {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 2,
        hoverRadius: 3,
        pointStyle: 'circle',
      },
      line: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        grid: {
          color: theme.palette.divider,
        },
        ticks: {
          font: {
            family: theme.typography.fontFamily,
            size: theme.typography.fontSize,
          },
          color: theme.palette.text.primary,
        },
      },
      y: {
        grid: {
          color: theme.palette.divider,
        },
        ticks: {
          font: {
            family: theme.typography.fontFamily,
            size: theme.typography.fontSize,
          },
          color: theme.palette.text.primary,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          boxHeight: 4,
          boxWidth: 16,
          boxPadding: 32,
          font: {
            family: theme.typography.fontFamily,
            size: theme.typography.fontSize,
          },
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        titleMarginBottom: 8,
        bodySpacing: 6,
        padding: 10,
        caretSize: 0,
        boxPadding: 6,
        borderWidth: 1,
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        titleFont: {
          family: theme.typography.fontFamily,
          size: theme.typography.fontSize,
          weight: theme.typography.fontWeightBold?.toString(),
        },
        bodyColor: theme.palette.text.primary,
        bodyFont: {
          family: theme.typography.fontFamily,
          size: theme.typography.fontSize,
          weight: theme.typography.fontWeightRegular?.toString(),
        },
        borderColor: theme.palette.background.default,
      },
    },
    datasets: {
      line: {
        spanGaps: true,
      },
      matrix: {
        borderColor: theme.palette.background.paper,
      },
    },
    normalized: true,
    parsing: false,
  };
}
