import React, {ReactChild} from 'react';
import {Box, CssBaseline} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CoyoLogoHeader from '../coyo-components/logo-header/CoyoLogoHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.action.disabled,
    },
    login: {
      maxWidth: 460,
      minWidth: 460,
      padding: '66px 56px 56px',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      borderRadius: 8,
    },
  }),
);

interface Props {
  children: ReactChild
}

export default function LoginLayout({children}: Props) {
  const classes = useStyles();
  return (
    <Box>
      <CssBaseline>
        <Box className={classes.root}>
          <Box className={classes.login}>
            <CoyoLogoHeader iconSize={56}/>
            {children}
          </Box>
        </Box>
      </CssBaseline>
    </Box>);
}
