import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import './animation.css';
import {Box} from '@material-ui/core';
import PlaceholderText from './PlaceholderText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: 60,
    },
    placeholderBox: {
      height: 42,
      width: 170,
      backgroundColor: theme.palette.type === 'dark'
        ? 'rgba(255, 255, 255, 0.13)'
        : 'rgba(0, 0, 0, 0.11)',
      borderRadius: 4,
      color: 'transparent',
      animation: '1.5s ease-in-out 0.5s infinite normal none running skeleton-animation',
      userSelect: 'none',
    },
    placeholderDescription: {
      fontWeight: 600,
      fontSize: 10,
    },
  }));

export interface Props {
  className?: string;
  loading: boolean;
}

export default function PlaceholderFilter({className, loading}: Props): ReactElement {
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        loading &&
        (<Box className={`${classes.controls} ${className}`}>
          <PlaceholderText loading={true} className={classes.placeholderDescription}>{'X'.repeat(25)}</PlaceholderText>
          <Box className={classes.placeholderBox}/>
        </Box>)
      }
    </React.Fragment>
  );
}
