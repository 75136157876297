import React, {ChangeEventHandler, FocusEventHandler} from 'react';
import {FormControl, IconButton, Input, InputAdornment} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClose?: () => void;
  className?: string;
  labelText?: string;
  labelWidth?: number;
  placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  svgIcon: {
    width: 16,
    height: 16,
    fill: theme.palette.text.secondary,
  },
  searchInput: {
    width: '100%',
    '& .MuiInputBase-inputMarginDense': {
      padding: 0,
      fontSize: 15,
      fontWeight: 600,
    },
  },
}));

export function CoyoSearchInput(props: Props) {
  const classes = useStyles();
  return <FormControl fullWidth className={`${props.className}`}>
    <Input fullWidth
           disableUnderline={false}
           autoFocus
           className={classes.searchInput}
           type={'text'}
           value={props.value}
           onChange={props.onChange}
           onBlur={props.onBlur}
           id={props.id}
           name={props.name}
           inputProps={{
             'data-testid': 'search-input',
             'aria-label': props.placeholder
           }}
           placeholder={props.placeholder || ''}
           margin="dense"
           endAdornment={
             <InputAdornment position="start">
               <IconButton size={'small'} data-testid={'search-close-button'} onClick={props.onClose}>
                 <CloseIcon fontSize="small"
                            className={classes.svgIcon}/>
               </IconButton>
             </InputAdornment>
           }
    />
  </FormControl>;
}
