import React from 'react';
import {SnackbarKey, SnackbarProvider} from 'notistack';
import {IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


interface Props {
  children: any;
}

export default function CoyoSnackbarProvider({children}: Props) {
  const ref = React.createRef<SnackbarProvider>();
  const closeSnackbar = (key: SnackbarKey) => ref.current?.closeSnackbar(key);

  return (
    <SnackbarProvider
      ref={ref}
      maxSnack={5}
      preventDuplicate={true}
      hideIconVariant={true}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(key) => (
        <IconButton size="small" aria-label="close" color={'inherit'} onClick={() => closeSnackbar(key)}>
          <CloseIcon fontSize="small"/>
        </IconButton>
      )}>
      {children}
    </SnackbarProvider>
  );
}
