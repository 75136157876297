import {Box, Checkbox, IconButton, ListItemText, ListSubheader, MenuItem} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import React, {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {ReactComponent as ClearIcon} from '../../svg/clear.svg';
import {ReactComponent as SearchIcon} from '../../svg/search.svg';
import CoyoInputField from '../input/CoyoInputField';
import CoyoInputLabel from '../input/CoyoInputLabel';
import CoyoSelect from '../select/CoyoSelect';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 14,
    paddingBottom: 4,
  },
  svgIcon: {
    width: 20,
    height: 20,
  },
  select: {
    width: 175,
    '& .MuiInputBase-input': {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 0, 0),
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  searchInput: {
    flex: 1,
    margin: theme.spacing(0, 1, 0, 1),
  },
  menuItem: {
    '&:focus': {
      background: theme.palette.action.hover,
    },
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  chips: {
    display: 'flex',
    height: 28,
    alignItems: 'center',
  },
  chip: {
    background: theme.palette.divider,
    borderRadius: 4,
    padding: '6px 8px',
    fontSize: 15,
    marginLeft: -6,

    '&:last-child:not(:first-child)': {
      marginLeft: 6,
    },
  },
  selectionChip: {
    flexShrink: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export interface Props {
  label: string;
  items: DropdownItem[];
  onSelection: (value: string) => void;
  onUnselection: (value: string) => void;
  loading: boolean;
}

export interface DropdownItem {
  value: string;
  amount: number;
  selected: boolean;
}

export default function CoyoMultiselect({label, items, onSelection, onUnselection, loading}: Props) {
  const classes = useStyles();
  const pageRowAmount = 10;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [visibleRowsAmount, setVisibleRowsAmount] = useState<number>(pageRowAmount);
  const [listItems, setListItems] = useState<DropdownItem[]>(items);

  const visibleItems = listItems.filter(item => searchTerm === '' || item.value.toLowerCase()
    .includes(searchTerm.toLowerCase()));
  const selectedValues = listItems.filter(item => item.selected)
    .map(item => item.value);
  const notSelectedVisibleValues = visibleItems.filter(item => !item.selected);

  function renderMenuItem(item: DropdownItem) {
    return (
      <MenuItem className={classes.menuItem} key={item.value} value={item.value} disabled={loading} onClick={() => {
        setListItems(listItems.map(listItem => {
          const mutableListItem = {
            ...listItem,
          };

          if (listItem.value === item.value && listItem.amount === item.amount) {
            mutableListItem.selected = !mutableListItem.selected;

            mutableListItem.selected ? onSelection(mutableListItem.value) : onUnselection(mutableListItem.value);
          }

          return mutableListItem;
        }));
      }}
                data-testid={`menu-item-${listItems.findIndex(listItem => listItem.value === item.value && listItem.amount === item.amount)}`}>
        <Checkbox checked={item.selected} disabled={loading}/>
        <ListItemText primary={`${item.value} (${item.amount})`}/>
      </MenuItem>
    );
  }

  function renderMenuItems(items: DropdownItem[]) {
    return items.map(item => renderMenuItem(item));
  }

  return (
    <Box>
      <CoyoInputLabel id={'multiselect-inputlabel-' + label} className={classes.label}>{label}</CoyoInputLabel>
      <CoyoSelect
        className={classes.select}
        SelectProps={{
          labelId: 'multiselect-inputlabel-' + label,
          multiple: true,
          value: selectedValues,
          displayEmpty: true,
          renderValue: (selected) => {
            const values = selected as string[];
            return (
              <Box className={classes.chips}>
                {values.length === 0 && 'All'}
                {values.length >= 1 && <Box className={`${classes.chip} ${classes.selectionChip}`}>{values[0]}</Box>}
                {values.length >= 2 && <Box className={classes.chip}>+{values.length - 1}</Box>}
              </Box>
            );
          },
          onKeyDownCapture: event => event.stopPropagation(),
          disabled: loading,
        }}
        data-testid={'multiselect-selection'}
        label={label}
      >
        <ListSubheader className={classes.searchBar}>
          <SearchIcon className={`${classes.svgIcon}`}/>
          <CoyoInputField
            placeholder="Search..."
            className={classes.searchInput}
            autoFocus={false}
            onChange={({target}) => setSearchTerm(target.value)}
            value={searchTerm}
            disabled={loading}
            inputProps={{
              'aria-label': `Search for ${label}`,
            }}
            data-testid={'search-input'}/>
          <IconButton size={'small'}
                      data-testid={'clear-icon-button'}
                      onClick={() => setSearchTerm('')}
                      disabled={loading}
                      aria-label={'Clear search input'}>
            <ClearIcon className={`${classes.svgIcon}`}/>
          </IconButton>
        </ListSubheader>

        <Box style={{maxHeight: 350, overflowY: 'auto'}} id={'listItems'} data-testid={'render-box'}>
          {renderMenuItems(visibleItems.filter(item => item.selected))}

          {(selectedValues.length > 0 && visibleItems.length !== selectedValues.length) && (
            <ListSubheader className={classes.divider} data-testid={'divider'}/>
          )}

          <InfiniteScroll
            next={() => setVisibleRowsAmount(visibleRowsAmount + pageRowAmount)}
            hasMore={visibleRowsAmount <= notSelectedVisibleValues.length}
            loader={<div> </div>}
            dataLength={visibleRowsAmount}
            scrollableTarget={'listItems'}
            data-testid={'infinite-scroll'}>
            {renderMenuItems(notSelectedVisibleValues.slice(0, visibleRowsAmount))}
          </InfiniteScroll>
        </Box>
      </CoyoSelect>
    </Box>
  );
}
