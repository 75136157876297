import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {Button, FormControl} from '@material-ui/core';
import CoyoInputLabel from '../../coyo-controls/input/CoyoInputLabel';
import CoyoInputField from '../../coyo-controls/input/CoyoInputField';
import {CognitoUser, CognitoUserPool, ICognitoUserData} from 'amazon-cognito-identity-js';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center'
    },
    item: {
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      margin: theme.spacing(7),
      fontSize: 24,
      fontWeight: 600,
    },
    textField: {
      margin: theme.spacing(1,0),
      '& .MuiInputBase-root': {
        height: 40,
        width: '100%',
        alignSelf: 'stretch',
        flexGrow: 0,
        padding: '10px 12px',
        borderRadius: 4,
        border: 'solid 1px #d7dbe0',
      },
      '& .MuiInputLabel-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 0.71,
        transform: 'none',
      },
    },
    button: {
      width: '100%',
      height: theme.spacing(5),
      margin: theme.spacing(7, 0),
      padding: '10px 16px',
      boxShadow: 'none',
      fontSize: 15,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    errorText: {
      color: '#FF5630',
      margin: theme.spacing(1,0,0,0),
      textAlign: 'center'
    },
  }),
);

interface Props {
  afterSuccessfulFormSubmit: (username: string) => void
}

export default function ForgotPasswordForm({afterSuccessfulFormSubmit}: Props) {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [username, setUsername] = useState('');
  const [error, setError] = useState(null);

  function onFailure(error: any): void {
    if ((error as Record<string, string>)?.message) {
      setError(error.message);
    }
  }

  function onSuccess(): void {
    setError(null);
    afterSuccessfulFormSubmit(username);
    enqueueSnackbar('A code for resetting your password has been send to your email', {variant: 'success'})
  }

  function forgotPassword(event: any) {
    event.preventDefault();
    const userPool: CognitoUserPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_CLIENT_ID || ''
    });
    const userData: ICognitoUserData = {Pool: userPool, Username: username};
    const user: CognitoUser = new CognitoUser(userData);
    if (user) {
      user.forgotPassword({onSuccess: onSuccess, onFailure: onFailure});
    }
  }

  return (
    <form onSubmit={forgotPassword} noValidate autoComplete="off" data-testid={'forgot-password-form'} className={classes.form}>
      <Typography variant="h5" className={`${classes.title} ${classes.item}`}>Enter your username</Typography>

      <FormControl fullWidth className={`${classes.textField} ${classes.item}`}>
        <CoyoInputLabel htmlFor="standard-adornment-username">Username</CoyoInputLabel>
        <CoyoInputField fullWidth
                        type="text"
                        value={username}
                        onChange={change => setUsername(change.target.value)}
                        id="username"
                        data-testid={'username-input'}
                        labelWidth={60}/>
      </FormControl>

      <Button type="submit" className={`${classes.button} ${classes.item}`} variant="contained"
              color="primary">Submit</Button>

      {error && <Typography className={`${classes.errorText} ${classes.item}`} data-testid={'error-message'}>{error}</Typography>}
    </form>
  );
}
