import { gql, useApolloClient } from '@apollo/client';
import { SenderTypeData } from '../types';
import { useEffect, useState } from 'react';
import { Sender, WebApiResponse } from '../schema';
import { SortOrder } from '../coyo-components/table-header-cell/CoyoTableHeaderCell';
import { SortColumn } from './overview/SendersOverview';
import { useList } from '../utils/lists';

const SubTypes = ['visits', 'uniqueVisits', 'avgEngagementRate'] as const;

type Data = {
  senders: Sender[],
  requestId?: string,
  visitsLoaded?: boolean,
  uniqueVisitsLoaded?: boolean,
  avgEngagementRateLoaded?: boolean
};

export const useSendersQuery = (
  senderType: SenderTypeData,
  from: string,
  to: string,
  sortColumn: SortColumn,
  sortOrder: SortOrder,
  nameFilter?: string | null,
  showConsolidatedDashboard?: boolean
) => {
  const [data, setData] = useState<Data>({ senders: [] });
  const [isMounted, setIsMounted] = useState(false);
  const client = useApolloClient();
  const { sort, filter } = useList<Sender>(sortColumn, sortOrder, nameFilter);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);


  const buildQuery = (type: string) => ({
    query: gql`
      query ${type}($senderType: String!, $from: String!, $to: String!, $showConsolidatedDashboard: Boolean) {
        senders(senderType: $senderType, from: $from, to: $to, showConsolidatedDashboard: $showConsolidatedDashboard, type: ${type} )
        { id, name, ${type} }
      }
    `,
    variables: { senderType, from, to, showConsolidatedDashboard }
  });

  useEffect(() => {
    if (!isMounted) {
      return
    }
    const currentRequestId = Math.random().toString(36).substring(2);

    setData(data => ({ senders: [], requestId: currentRequestId }));

    const mergeSenders = (senders: Sender[], existingSenders: Sender[]) => {
      if (!senders) {
        return existingSenders;
      }

      const map = existingSenders.reduce((all, current) => {
        all[current.id] = current;
        return all;
      }, {} as { [key: string]: Sender });

      senders.forEach(sender => {
        const existing = map[sender.id];
        map[sender.id] = existing ? { ...existing, ...sender } : sender;
      });

      return Object.values(map);
    }

    Promise.all(
      SubTypes.map(async (type: string) => {
        const result = await client.query<WebApiResponse>(buildQuery(type));

        setData(data => {
          if (currentRequestId !== data.requestId) {
            return data;
          }
          if (!result.data.senders) {
            return data;
          }
          return {
            ...data,
            senders: mergeSenders(result.data.senders, data.senders ?? []),
            [`${type}Loaded`]: true,
          }
        });
      })
    );
  }, [isMounted, senderType, from, to, showConsolidatedDashboard]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: {
      data: !data.visitsLoaded || !data.uniqueVisitsLoaded || !data.avgEngagementRateLoaded,
      visits: !data.visitsLoaded,
      uniqueVisits: !data.uniqueVisitsLoaded,
      avgEngagementRate: !data.avgEngagementRateLoaded,
    },
    data: { senders: sort(filter(data.senders)) }
  };
};
