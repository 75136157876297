import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';

export const useAdoptionQuery = () => {
    return useApiQuery(gql`
        query adoption {
            adoption {
                currentActivatedUsers
                licenses
                uniqueActiveUsers
            }
        }
    `);
};
