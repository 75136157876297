import React, {useContext} from 'react';
import {Feature} from './feature';

export interface IFeatureToggleContext {
  isDisabledFeature: (feature: Feature) => boolean
}

export const FeatureToggleContext = React.createContext<IFeatureToggleContext>({
  isDisabledFeature: (feature: Feature) => true,
});

export const useFeatureToggleContext = () => useContext(FeatureToggleContext);
