import React, {useEffect} from 'react';
import {Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CurrentUserActivityCard from '../charts/current-user-activity/CurrentUserActivityCard';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import UserActivityCard from '../charts/user-activity/UserActivityCard';
import HighlightsCard from '../charts/highlights/HighlightsCard';
import CreatedContentCard from '../charts/created-content/CreatedContentCard';
import VisitsCard from '../charts/visits/VisitsCard';
import AudienceCard from '../charts/audience/AudienceCard';
import ContentPerformanceCard from '../charts/content-performance/ContentPerformanceCard';
import {useSideNavContext} from '../side-navigation/SideNavContext';
import AdoptionCard from '../charts/adoption/AdoptionCard';
import UserActivityMapCard from '../charts/user-activity-map/UserActivityMapCard';
import SearchAnalyticsCard from '../charts/search-terms/SearchAnalyticsCard';
import {useFeatureToggleContext} from '../feature-toggle/FeatureToggleContext';
import { FeatureToggle } from '../feature-toggle/feature';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > h1': {
        width: '100%',
        maxWidth: '1275px',
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 40,
      maxWidth: '1275px',
      width: '100%',
      gap: '40px',

      '& > *': {
        width: '100%',
      },
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    flex1: {
      flex: 1,
    },
    flex3: {
      flex: 3,
    },
    flex4: {
      flex: 4,
    },
    w350: {
      maxWidth: 350,
    },
    w400: {
      maxWidth: 400,

      '@media only screen and (max-width: 1400px)': {
        maxWidth: 'unset',
        minWidth: 400,
      },
    },
    '@media print': {
      row: {
        pageBreakInside: 'avoid',
        boxShadow: '1px'
      },
    },
  }),
);


export default function Dashboard() {
  const classes = useStyles();
  const {setActiveSideNavOption} = useSideNavContext();
  const {isDisabledFeature} = useFeatureToggleContext();

  useEffect(() => {
    document.title = 'Haiilo Intranet Analytics | Dashboard';
    setActiveSideNavOption('dashboard');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Box className={classes.content}>
        <Typography variant="h1">Dashboard</Typography>

        <Box className={classes.row}>
          <AdoptionCard className={`${classes.flexColumn} ${classes.flex3} ${classes.w350}`}/>
          <CurrentUserActivityCard className={`${classes.flexColumn} ${classes.flex3} ${classes.w350}`}/>
          <HighlightsCard className={`${classes.flexRow} ${classes.flex4}`}/>
        </Box>

        <Box className={classes.row}>
          <UserActivityCard/>
        </Box>

        <Box className={classes.row}>
          <UserActivityMapCard/>
        </Box>

        <Box className={classes.row}>
          <CreatedContentCard/>
        </Box>

        <Box className={classes.row}>
          <ContentPerformanceCard/>
        </Box>

        <Box className={classes.row}>
          <VisitsCard className={`${classes.flex1} ${classes.w400}`}/>
          <AudienceCard className={`${classes.flex1} ${classes.w400}`}/>
          {!isDisabledFeature(FeatureToggle.Search) && <SearchAnalyticsCard className={`${classes.flex1} ${classes.w400}`}/>}
        </Box>
      </Box>
    </React.Fragment>);
}
