import React, {useEffect, useState} from 'react';
import ForgotPasswordForm from './forms/ForgotPasswordForm';
import EnterCodeForm from './forms/EnterCodeForm';
import LoginLayout from './LoginLayout';


export default function ForgotPassword() {
  const [emailSend, setEmailSend] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    document.title = 'Haiilo Intranet Analytics | Forgot Password';
  });

  return (
    <LoginLayout>
      {
        !emailSend
          ? <ForgotPasswordForm afterSuccessfulFormSubmit={username => {
            setUsername(username);
            setEmailSend(true);
          }}/>
          : <EnterCodeForm username={username}/>
      }
    </LoginLayout>);
}
