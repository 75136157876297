import {Workbook} from 'exceljs';
import {Histogram, SenderActivity} from '../../schema';
import {generateIsoDates, toLocaleDate} from '../../charts/DateUtils';
import {capitalizeFirstLetter, getValueFromTimeValues} from './ReportUtils';
import {Moment} from 'moment-timezone';
import {SenderTypeFrontend} from '../../types';

export function addSenderActivityReport(type: SenderTypeFrontend, workbook: Workbook, senderActivity: SenderActivity, startDate: Moment, endDate: Moment) {
  addSenderActivityHistogramSheet(type, workbook, 'day', senderActivity, startDate, endDate);
  addSenderActivityHistogramSheet(type, workbook, 'week', senderActivity, startDate, endDate);
  addSenderActivityHistogramSheet(type, workbook, 'month', senderActivity, startDate, endDate);
}

function addSenderActivityHistogramSheet(type: SenderTypeFrontend, workbook: Workbook, grouping: keyof Histogram, senderActivityData: SenderActivity, startDate: Moment, endDate: Moment) {
  const groupingFormatted = capitalizeFirstLetter(grouping);
  const worksheet = workbook.addWorksheet(`${capitalizeFirstLetter(type)} Activity (Histogram ${groupingFormatted})`);
  worksheet.columns = [
    {header: 'Date', width: 20},
    {header: 'Visitors', width: 20},
    {header: 'Visits', width: 20},
    {header: 'Consuming Users', width: 20},
    {header: 'Interacting Users', width: 20},
  ];
  const isoDates = generateIsoDates(startDate, endDate, grouping);
  isoDates.forEach(isoDate => {
    worksheet.addRow([
      toLocaleDate(isoDate),
      getValueFromTimeValues(senderActivityData.visitors, grouping, isoDate),
      getValueFromTimeValues(senderActivityData.visits, grouping, isoDate),
      getValueFromTimeValues(senderActivityData.consumingUsers, grouping, isoDate),
      getValueFromTimeValues(senderActivityData.interactingUsers, grouping, isoDate),
    ]);
  });
  worksheet.autoFilter = 'A1:E1';
}
