import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {useSettingsContext} from '../../settings/SettingsContext';

export const useVisitsQuery = (from: string, to: string, senderId: string | null) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {timezone} = useSettingsContext();
  const expression = !!senderId ?
    gql`
        query visits($from: String!, $to: String!, $senderId: String, $timezone: String!, $globalFilters: [GlobalFilter!]) {
            visits(from: $from, to: $to, senderId: $senderId, timezone: $timezone, globalFilters: $globalFilters){
                client
                total
            }
        }
    ` :
    gql`
        query visits($from: String!, $to: String!, $timezone: String!, $globalFilters: [GlobalFilter!]) {
            visits(from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters){
                client
                total
            }
            avgSessionLength(from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters){
                client
                seconds
            }
        }
    `;
  return useApiQuery(expression, {from, to, senderId, globalFilters: appliedGlobalFilters, timezone});
};
