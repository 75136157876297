import {Box, Button} from '@material-ui/core';
import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import ExcelJS, {Workbook} from 'exceljs';
import {saveAs} from 'file-saver';
import {addAudienceReport} from './reports/AudienceReport';
import {addContentPerformanceReport} from './reports/ContentPerformanceReport';
import {addCreatedContentReport} from './reports/CreatedContentReport';
import {useReportDataContext} from '../report-data/ReportDataContext';
import {useDateRangeContext} from '../date-range-selection/DateRangeContext';
import {addCurrentUsersActivityReport} from './reports/CurrentUserActivityReport';
import {addVisitsReport} from './reports/VisitsReport';
import {addUserActivityReport} from './reports/UserActivityReport';
import {useLocation} from 'react-router-dom';
import {addSendersOverviewReport} from './reports/SendersOverviewReport';
import {addHighlightsReport} from './reports/HighlightsReport';
import {addSenderHighlightsReport} from './reports/SenderHighlightsReport';
import {addSenderActivityReport} from './reports/SenderActivityReport';
import {useSnackbar} from 'notistack';
import {addWorkspaceHighlightsReport, WorkspaceHighlightsReport} from './reports/WorkspaceHighlightsReport';
import {capitalizeFirstLetter} from './reports/ReportUtils';
import {SenderTypeFrontend} from '../types';
import {addAdoptionReport} from './reports/AdoptionReport';
import {addHomepagesOverviewReport} from './reports/HomepagesOverviewReport';
import {addContentInsightsMetadataReport} from './reports/ContentInsightsMetadataReport';
import {addContentInsightsHistogramReport} from './reports/ContentInsightsHistogramReport';
import {addSearchAnalyticsReport} from './reports/SearchAnalyticsReport';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportButton: {
      margin: theme.spacing(1),
      marginRight: 0,
      marginBottom: 0,
      fontSize: 16,
      font: 'strong',
      height: theme.spacing(5) + 2, //add border height of multiselect
      alignSelf: 'flex-end',
    },
    reportButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'baseline',
      flexGrow: 1,
    },
  }),
);

export default function ReportGeneration() {
  const {reportData} = useReportDataContext();
  const {startDate, endDate} = useDateRangeContext();
  const {pathname} = useLocation();
  const {enqueueSnackbar} = useSnackbar();

  async function generateReport(): Promise<void> {
    if (pathname.startsWith('/dashboard')) {
      await generateDashboardReport();
    } else if (pathname.startsWith('/pages') || pathname.startsWith('/my-pages')) {
      await generateSendersOverviewReport('page');
    } else if (pathname.startsWith('/communities') || pathname.startsWith('/my-communities')) {
      await generateSendersOverviewReport('community');
    } else if (pathname.startsWith('/homepages')) {
      await generateHomepagesOverviewReport();
    } else if (pathname.includes('/content/')) {
      await generateContentInsightsReport();
    } else if (['page', 'my-page', 'community', 'my-community', 'sender'].find(part => pathname.startsWith(`/${part}/`))) {
      await generateSenderAnalyticsReport();
    }
  }

  const classes = useStyles();

  return (
    <Box className={classes.reportButtonWrapper}>
      <Button data-testid={'report-generation-button'}
              className={classes.reportButton}
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => generateReport()}
      >
        Generate Report
      </Button>
    </Box>
  );

  async function generateDashboardReport() {
    if (reportData.currentUserActivityLoading
      || reportData.highlightsLoading
      || reportData.userActivityLoading
      || reportData.createdContentLoading
      || reportData.contentPerformanceLoading
      || reportData.visitsLoading
      || reportData.audienceLoading
      || reportData.searchAnalyticsLoading) {
      enqueueSnackbar('Please wait until data is loaded.');
    } else if (!reportData.adoption
      || !reportData.currentUserActivity
      || !reportData.highlights
      || !reportData.consumingUsers
      || !reportData.interactingUsers
      || !reportData.createdContent
      || !reportData.contentPerformance
      || !reportData.visits
      || !reportData.audience
      || !reportData.searchAnalytics) {
      enqueueSnackbar('Not all data is loaded, therefore report cannot be created. Please reload missing data.');
    } else {
      const workbook = new ExcelJS.Workbook();
      const filename = 'COYO_Analytics_Dashboard.xlsx';

      addAdoptionReport(workbook, reportData.adoption);
      addCurrentUsersActivityReport(workbook, reportData.currentUserActivity);
      addHighlightsReport(workbook, reportData.highlights);
      addUserActivityReport(workbook, reportData.consumingUsers, reportData.interactingUsers, startDate, endDate);
      addCreatedContentReport(workbook, reportData.createdContent, startDate, endDate);
      addContentPerformanceReport(workbook, reportData.contentPerformance);
      addVisitsReport(workbook, reportData.visits);
      addAudienceReport(workbook, reportData.audience);
      addSearchAnalyticsReport(workbook, reportData.searchAnalytics);
      await saveXlsFile(workbook, filename);
    }
  }

  async function generateSendersOverviewReport(type: SenderTypeFrontend) {
    if (reportData.senderLoading) {
      enqueueSnackbar('Please wait until data is loaded.');
    } else if (!reportData.sender) {
      enqueueSnackbar('Not all data is loaded, therefore report cannot be created. Please reload missing data.');
    } else {
      const workbook = new ExcelJS.Workbook();
      const filename = `COYO_Analytics_${type.includes('page') ? 'Pages' : 'Communities'}Overview.xlsx`;
      addSendersOverviewReport(type, workbook, reportData.sender);
      await saveXlsFile(workbook, filename);
    }
  }

  async function generateHomepagesOverviewReport() {
    if (reportData.landingPageLoading) {
      enqueueSnackbar('Please wait until data is loaded.');
    } else if (!reportData.landingPage) {
      enqueueSnackbar('Not all data is loaded, therefore report cannot be created. Please reload missing data.');
    } else {
      const workbook = new ExcelJS.Workbook();
      const filename = `COYO_Analytics_HomepagesOverview.xlsx`;
      addHomepagesOverviewReport(workbook, reportData.landingPage);
      await saveXlsFile(workbook, filename);
    }
  }

  async function generateContentInsightsReport() {
    console.log('generateContentInsightsReport');
    if (reportData.contentInsightsMetadataLoading || reportData.contentInsightsHistogramLoading) {
      enqueueSnackbar('Please wait until data is loaded.');
    } else if (!reportData.contentInsightsMetadata || !reportData.contentInsightsHistogram) {
      enqueueSnackbar('Not all data is loaded, therefore report cannot be created. Please reload missing data.');
    } else {
      const workbook = new ExcelJS.Workbook();
      const filename = `COYO_Analytics_ContentInsights.xlsx`;
      if (!!reportData.contentInsightsMetadata)
        addContentInsightsMetadataReport(workbook, reportData.contentInsightsMetadata);

      if (!!reportData.contentInsightsHistogram)
        addContentInsightsHistogramReport(workbook, reportData.contentInsightsHistogram, startDate, endDate);

      await saveXlsFile(workbook, filename);
    }
  }

  async function generateSenderAnalyticsReport() {
    if (reportData.senderHighlightsLoading
      || reportData.senderActivityLoading
      || reportData.contentPerformanceLoading
      || reportData.visitsLoading
      || reportData.audienceLoading) {
      enqueueSnackbar('Please wait until data is loaded.');
    } else if (!reportData.senderHighlights
      || !reportData.contentPerformance
      || !reportData.audience
      || !reportData.senderActivity
      || !reportData.visits) {
      enqueueSnackbar('Not all data is loaded, therefore report cannot be created. Please reload missing data.');
    } else {
      const type = reportData.senderInfo.type === 'workspace' ? 'community' : 'page';
      const workbook = new ExcelJS.Workbook();
      const senderNameClean = reportData.senderInfo?.name?.replace(/[^a-zA-Z0-9]/g, '');
      const filename = `COYO_Analytics_${capitalizeFirstLetter(type)}_${senderNameClean}.xlsx`;

      addVisitsReport(workbook, reportData.visits);

      if (type === 'page') {
        addSenderHighlightsReport(type, workbook, reportData.senderHighlights);
      } else if (type === 'community') {
        addWorkspaceHighlightsReport(workbook, reportData.senderHighlights as WorkspaceHighlightsReport);
      }

      addSenderActivityReport(type, workbook, reportData.senderActivity, startDate, endDate);
      addContentPerformanceReport(workbook, reportData.contentPerformance);
      addAudienceReport(workbook, reportData.audience);
      await saveXlsFile(workbook, filename);
    }
  }

  async function saveXlsFile(workbook: Workbook, filename: string) {
    const xlsBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([xlsBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}
