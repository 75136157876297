import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 4, 4),
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.background.paper,
      outline: 'none',
    },
  }),
);

interface Props {
  children: any;
  open: boolean;
  onClose: () => void;
  keepMounted?: boolean;
}

export default function CoyoModal(props: Props) {
  const classes = useStyles();

  return (
      <Modal
        keepMounted={props.keepMounted || false}
        className={classes.modal}
        open={props.open}
        onClose={props.onClose}>
        <div role={'dialog'} className={classes.paper}
             aria-modal={'true'}>
          {props.children}
        </div>
      </Modal>
  );
}
