import React, {useState} from 'react';
import LineChart, {EMPTY_SERIES, Group, Series} from '../../chart-types/line-chart/LineChart';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {Box, MenuItem} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useUserActivityQuery} from './UserActivityQuery';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {Histogram} from '../../schema';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&:focus': {
        background: theme.palette.action.hover,
      },
    },
    chart: {
      padding: '8px',
    },
  }),
);

const helpText = {
  what: `Consuming users is the number of unique users, who have been using COYO in the configured date range. 
  Interacting users is the number of consuming users, who have been interacting with content at least once.
  The chart can be configured to group the activity by days, weeks or months.`,
  how: <React.Fragment>
    {`A user is counted as consuming, if he/she/they used COYO for at least once in the specific day, week or month for
    more than 3 seconds. If the consuming users has created at least one interaction he
    is also counted as interacting user.`}
    <br/>
    <br/>
    {`An interaction is defined as:`}
    <ul style={{marginTop: 0}}>
      <li>liking content</li>
      <li>commenting content</li>
      <li>sharing content</li>
      <li>creating a timeline item</li>
      <li>creating a blog article</li>
      <li>creating a wiki article</li>
    </ul>
  </React.Fragment>,
};

export default function UserActivityCard() {
  const classes = useStyles();
  const [group, setGroup] = useState<Group>('day');
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = useUserActivityQuery(fromDay, toDay);
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {reportData} = useReportDataContext();
  const consumingUsers: Histogram | undefined = data?.consumingUsers;
  const interactingUsers: Histogram | undefined = data?.interactingUsers;
  const title = 'User activity';

  reportData.consumingUsers = consumingUsers;
  reportData.interactingUsers = interactingUsers;
  reportData.userActivityLoading = loading;

  const series: Series[] = [{
    name: 'Consuming users',
    dataByRange: consumingUsers || EMPTY_SERIES,
  }, {
    name: 'Interacting users',
    dataByRange: interactingUsers || EMPTY_SERIES,
  }];

  return (
    <CoyoCard id={'user-activity-card'}
              title={title}
              loading={loading}
              helpText={helpText}
              filtered={appliedGlobalFilters.length > 0}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              controls={(
                <CoyoSelect SelectProps={{
                  value: group,
                  onChange: ({target}) => setGroup(target.value as Group),
                }}
                data-testid={'user-activity-selection'}
                label={'Select user activity time range'}>
                  <MenuItem className={classes.menuItem} value={'day'} disabled={group === 'day'}><span>Day</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'week'} disabled={group === 'week'}><span>Week</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'month'} disabled={group === 'month'}><span>Month</span></MenuItem>
                </CoyoSelect>
              )}
              reload={refetch}>
      <Box className={classes.chart}>
        <LineChart series={series} group={group} loading={loading}/>
      </Box>
    </CoyoCard>);
}
