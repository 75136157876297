import React, {ChangeEvent, useMemo, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Box, MenuItem} from '@material-ui/core';
import moment, {Moment} from 'moment-timezone';
import CoyoDatePicker from '../coyo-controls/date-picker/CoyoDatePicker';
import CoyoInputLabel from '../coyo-controls/input/CoyoInputLabel';
import CoyoSelect from '../coyo-controls/select/CoyoSelect';
import {useDateRangeContext} from './DateRangeContext';
import {DEFAULT_PRESETS} from './DateRangePreset';
import {createBrowserHistory} from 'history';
import {hasParams} from '../coyo-controls/WebUtils';
import {useSettingsContext} from '../settings/SettingsContext';

export default function DateRangeSelection() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      menuItem: {
        '&:focus': {
          background: theme.palette.action.hover,
        },
      },
      controls: {
        display: 'flex',
        flexDirection: 'column',

        '& .MuiTextField-root': {
          marginRight: 8,
          float: 'left',
        },

        '& .MuiOutlinedInput-notchedOutline': {
          transition: '.1s',
        },
      },
      preset: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        transition: 'background-color .1s',
      },
      description: {
        color: theme.palette.text.secondary,
        fontWeight: 600,
        fontSize: 14,
        paddingBottom: 4,
      },
      selection: {
        display: 'flex',
        flexDirection: 'row',
      },
    }),
  );

  const NO_PRESET_SELECTED_INDEX = -1;

  const classes = useStyles();
  const {timezone} = useSettingsContext();
  const {setRange} = useDateRangeContext();
  const history = createBrowserHistory();
  const hasStartAndEndDate = hasParams(history.location.search, 'startDate', 'endDate');
  const {startDate, endDate} = useDateRangeContext();
  const indexMatchingDateRange = DEFAULT_PRESETS.findIndex(preset =>
    startDate.startOf('day').diff(preset.getFrom(timezone).startOf('day'), 'seconds') === 0 &&
    endDate.endOf('day').diff(preset.getTo(timezone).endOf('day'), 'seconds') === 0,
  );
  const [selectedPresetIndex, setSelectedPresetIndex] = useState<number>(indexMatchingDateRange);

  useMemo(() => {
    if (hasStartAndEndDate) {
      setSelectedPresetIndex(indexMatchingDateRange);
    }
  }, [indexMatchingDateRange, hasStartAndEndDate]);

  const publishDateChange = (start: Moment, end: Moment) => {
    if (start.isValid() && end.isValid()) {
      setRange(
        start.startOf('day'),
        end.endOf('day'),
      );
    }
  };

  const handleStartDateChange = (date: Moment) => {
    setSelectedPresetIndex(NO_PRESET_SELECTED_INDEX);
    if (!!date && !!endDate)
      publishDateChange(date, endDate);
  };

  const handleEndDateChange = (date: Moment) => {
    setSelectedPresetIndex(NO_PRESET_SELECTED_INDEX);
    if (!!startDate && !!date)
      publishDateChange(startDate, date);
  };

  const handlePresetChange = (event: ChangeEvent<{ value: unknown }>) => {
    const index = event.target.value as number;
    setSelectedPresetIndex(index);

    if (index !== NO_PRESET_SELECTED_INDEX) {
      const preset = DEFAULT_PRESETS[index];
      publishDateChange(preset.getFrom(timezone), preset.getTo(timezone));
    }
  };

  return (
    <Box className={classes.controls}>
      <CoyoInputLabel id={'daterange-selection-label'} className={classes.description}>Filter by</CoyoInputLabel>
      <div className={classes.selection}>
        <CoyoDatePicker
          data-testid={'startDate'}
          value={startDate}
          max={endDate}
          onChange={handleStartDateChange}
          label={'Filter by start date'}/>

        <CoyoDatePicker
          data-testid={'endDate'}
          value={endDate}
          min={startDate}
          max={moment.tz(timezone)}
          onChange={handleEndDateChange}
          label={'Filter by end date'}/>

        <CoyoSelect
          id="preset"
          data-testid={'preset'}
          className={classes.preset}
          variant={'standard'}
          SelectProps={{
            value: selectedPresetIndex,
            onChange: handlePresetChange,
          }}
          label={'Filter by preset'}
          labelId={'daterange-selection-label'}>
          {
            selectedPresetIndex === NO_PRESET_SELECTED_INDEX
              ? <MenuItem className={classes.menuItem} value={NO_PRESET_SELECTED_INDEX} disabled><span>Custom</span></MenuItem>
              : null
          }
          {DEFAULT_PRESETS.map((preset, index) => (
            <MenuItem className={classes.menuItem} value={index} key={index} disabled={index === selectedPresetIndex}>
              <span>{preset.name}</span>
            </MenuItem>
          ))}
        </CoyoSelect>
      </div>
    </Box>
  );
}
