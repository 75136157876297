import {Workbook} from 'exceljs';
import {CurrentUserActivity} from '../../schema';

export function addCurrentUsersActivityReport(workbook: Workbook, currentUserActivityData: CurrentUserActivity) {
  const worksheet = workbook.addWorksheet('Active unique users');
  worksheet.columns = [
    {header: 'Last 24 hours', width: 15},
    {header: 'Last 7 days', width: 15},
    {header: 'Last 28 days', width: 15},
  ];
  worksheet.addRow([currentUserActivityData.day, currentUserActivityData.week, currentUserActivityData.month]);
}
