import { useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { useAuthContext } from '../auth/AuthContext';
import { useSnackbar } from 'notistack';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

const LONG_QUERY_WARNING_KEY = 'LONG_QUERY_WARNING_KEY';

interface Props {
  children: any;
}

const DELAY_TIME = 6_000;
const RETRIES = 10;

class TimeoutError extends Error {
  constructor() {
    super(`Timed out after ${DELAY_TIME} ms.`);
    this.name = 'TimeoutError';
  }
}

let counter = 0;
let displayTime = 0;

export function Apollo(props: Props) {
  const APP_SYNC_URL = process.env.REACT_APP_APP_SYNC_URL;
  const authorization = useAuthContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const httpLink = useMemo(() => {

    function withTimeout<T>(promise: Promise<T>) {
      counter++;

      Promise.race([
        promise.finally(() => {
          counter--;
          if (counter <= 0) {
            // prevent closing snackbar too early if query result triggers another query
            const delay = Math.max(displayTime + 2_900 - Date.now(), 0) + 100;
            setTimeout(() => {
              if (counter <= 0) {
                closeSnackbar(LONG_QUERY_WARNING_KEY);
                counter = 0;
                displayTime = 0;
              }
            }, delay);
          }
        }),
        new Promise((_, reject) =>
          setTimeout(() => reject(new TimeoutError()), DELAY_TIME),
        ),
      ]).catch((err: any) => {
        if (err instanceof TimeoutError) {
          if (displayTime <= 0) {
            displayTime = Date.now();
          }
          enqueueSnackbar('Selected filters result in longer query time. Please wait...', { key: LONG_QUERY_WARNING_KEY, variant: 'warning', persist: true });
        }
      });

      return promise;
    }

    const fetch = (input: RequestInfo, init?: RequestInit) =>
      withTimeout(window.fetch(input, init));

    if (authorization?.type === 'api-key') {
      return new HttpLink({
        uri: APP_SYNC_URL,
        headers: {
          'x-api-key': authorization.apiKey ?? '',
          'x-data-access-key': authorization.dataAccessKey ?? '',
        },
        fetch,
      });
    }

    if (authorization?.type === 'cognito') {
      const authMiddleware = new ApolloLink((operation, forward) => {

        return new Observable(observer => {
          authorization.refreshSessionIfNeeded()
            .then(() => {
              observer.next(null);
              observer.complete();
            })
            .catch(err => observer.error(err));
        }).flatMap(() => forward(operation));
      });

      const link = new HttpLink({
        uri: APP_SYNC_URL,
        headers: { 'authorization': authorization.getJwtToken() ?? '' },
        fetch,
      });

      return concat(authMiddleware, link);
    }

    return new HttpLink({ uri: APP_SYNC_URL });
  }, [APP_SYNC_URL, authorization]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      return forward(operation).map((response) => {
        if (response.errors?.some((error) => (error as any).errorType === 'ExecutionTimeout'))
          throw new Error(`Data retrieval failed after ${RETRIES} retries.`);

        return response;
      });
    }
  });

  const retryLink = new RetryLink({ attempts: (count: number) => count <= RETRIES });

  const client = new ApolloClient({
    link: ApolloLink.from([retryLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            highlights: {
              merge: false,
            },
            createdContent: {
              merge: false,
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  return (<ApolloProvider client={client}>{props.children}</ApolloProvider>);
}
