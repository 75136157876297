import React from 'react';
import {Box, LinearProgress, TableCell} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Bar, DisplayType} from '../../chart-types/bars/Bars';
import PlaceholderText from '../../placeholder/PlaceholderText';
import CoyoWarningBox from '../warning/CoyoWarningBox';
import {formatBarValue, isBarFocusedOrDefaultStyle} from '../../chart-types/bars/BarsUtils';

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    '&.MuiTableCell-body': {
      position: 'relative',
      padding: '0 36px 0 16px',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
  },
  barValue: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  colorPrimary: {
    background: theme.palette.primary.main,
  },
  colorSecondary: {
    background: theme.palette.background.paper,
  },
  colorSecondaryFocused: {
    background: theme.palette.divider,
  },
}));

interface Props {
  value: number,
  maxValue: number,
  width?: number | string
  showWarning?: boolean
  warningText?: string
  displayType: DisplayType
  focused?: boolean
  loading?: boolean
}

export default function CoyoTableCellBar({
                                           value,
                                           maxValue,
                                           width,
                                           showWarning,
                                           warningText,
                                           displayType,
                                           focused,
                                           loading,
                                         }: Props) {
  const classes = useStyles();

  const bar: Bar = {
    title: undefined,
    value: value,
    maxValue: maxValue,
    dataType: displayType,
    focused: focused,
  };

  return (
    <TableCell className={classes.cell} width={width}>
      <Box className={classes.root}>
        <Box className={classes.barValue} data-testid={'value'}>
          <PlaceholderText loading={loading || false}>
            {!loading ? formatBarValue(bar) : '000'}
          </PlaceholderText>
          <Box>
            {showWarning && (<CoyoWarningBox warningText={warningText} data-testid={'warning-box'}/>)}
          </Box>
        </Box>
        <Box className={classes.progress} aria-hidden={true}>
          <LinearProgress variant="determinate"
                          value={!bar.value ? 0 : Math.ceil(bar.value / bar.maxValue * 100)}
                          classes={{
                            barColorPrimary: isBarFocusedOrDefaultStyle(bar.focused)
                              ? classes.colorPrimary
                              : classes.colorSecondaryFocused,
                            colorPrimary: bar.focused === undefined
                              ? classes.colorSecondaryFocused
                              : classes.colorSecondary,
                          }}
                          data-testid={'progress-bar'}/>
        </Box>

      </Box>
    </TableCell>
  );
}
