import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';
import {Box} from '@material-ui/core';
import CoyoTooltip from '../../../coyo-components/tooltip/CoyoTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 1,
      color: theme.palette.background.paper,
      margin: '4px',

      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginLeft: 0,
      },
    },
    outlined: {
      color: theme.palette.text.primary,
    },
    arrow: {
      height: '2px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#0073e6',
    },
    arrowSecondary: {
      backgroundColor: theme.palette.type === 'dark' ? '#99c7f5' : '#ebecf0',
    },
    number: {
      width: '45px',
      height: theme.spacing(3),
      borderRadius: '100px',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1.43,
      backgroundColor: '#0073e6',
      border: `solid 2px ${'#0073e6'}`,

      '&:hover': {
        cursor: 'pointer',
      },
    },
    numberSecondary: {
      backgroundColor: theme.palette.type === 'dark' ? '#99c7f5' : '#ebecf0',
      borderColor: theme.palette.type === 'dark' ? '#99c7f5' : '#ebecf0',
    },
    numberOutlined: {
      backgroundColor: theme.palette.background.paper,
    },
    arrowRight: {
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      height: '10px',
    },
    arrowRightSecondary: {
      borderLeft: `5px solid ${theme.palette.type === 'dark' ? '#99c7f5' : '#ebecf0'}`,
    },
  }));

interface Props {
  secondary?: boolean;
  outlined?: boolean;
  helpText?: string;
  children: any;
}

export default function ArrowDataLabel({secondary, outlined, helpText, children}: Props) {
  const classes = useStyles();

  return (
    <Box className={`${classes.wrapper} ${outlined && classes.outlined}`} data-test-id={'arrow-wrapper'}>
      <Box className={`${classes.arrow} ${secondary && classes.arrowSecondary}`} data-test-id={'arrow'}>
        {helpText && <CoyoTooltip title={<React.Fragment>{helpText}</React.Fragment>}
                                  placement={'top-start'} data-testid={'highlight-helptext-tooltip'}>
          {renderNumberBox(children)}
        </CoyoTooltip>}
        {!helpText && renderNumberBox(children)}
      </Box>
      <Box className={`${classes.arrowRight} ${secondary && classes.arrowRightSecondary}`}
           data-test-id={'arrow-right'}/>
    </Box>
  );

  function renderNumberBox(children: any) {
    return (
      <Box className={`${classes.number} ${secondary && classes.numberSecondary} ${outlined && classes.numberOutlined}`}
           aria-label={helpText}
           data-test-id={'arrow-number'}>
        <span aria-hidden={!!helpText}>{children}</span>
      </Box>);
  }
}
