import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useSettingsContext} from '../../settings/SettingsContext';


export const useCreatedContentQuery = (from: string, to: string) => {
  const {timezone} = useSettingsContext();
  return useApiQuery(gql`
      query createdContent($from: String!, $to: String!, $timezone: String!) {
          createdContent {
              highlights(from: $from, to: $to, timezone: $timezone) {
                  wikiArticles {
                      trendPercent
                      total
                  }
                  timelineItems {
                      trendPercent
                      total
                  }
                  blogArticles {
                      trendPercent
                      total
                  }
              }

              histogram(from: $from, to: $to, timezone: $timezone) {
                  videos {
                      week {
                          value
                          isoDate
                      }
                      month {
                          value
                          isoDate
                      }
                      day {
                          value
                          isoDate
                      }
                  }
                  text {
                      week {
                          value
                          isoDate
                      }
                      month {
                          value
                          isoDate
                      }
                      day {
                          value
                          isoDate
                      }
                  }
                  images {
                      week {
                          value
                          isoDate
                      }
                      month {
                          value
                          isoDate
                      }
                      day {
                          value
                          isoDate
                      }
                  }
                  files {
                      week {
                          value
                          isoDate
                      }
                      month {
                          value
                          isoDate
                      }
                      day {
                          value
                          isoDate
                      }
                  }
              }
          }
      }
  `, {from, to, timezone});
};
