import { gql, useApolloClient } from '@apollo/client';
import { useGlobalFiltersContext } from '../../global-filters/GlobalFiltersContext';
import { useSettingsContext } from '../../settings/SettingsContext';
import { useEffect, useState } from 'react';
import { SenderActivity, WebApiResponse } from '../../schema';
import { EMPTY_SERIES } from '../../chart-types/line-chart/LineChart';

type Data = SenderActivity & { requestId: string };

const buildQuery = (type: string, ...keys: string[]) => {
  return `
    query senderActivity($senderId: String!, $from: String!, $to: String!, $timezone: String!, $includeUserGeneratedContent: Boolean, $globalFilters: [GlobalFilter!]) {
        senderActivity(type: ${type}, senderId: $senderId, from: $from, to: $to, timezone: $timezone, includeUserGeneratedContent: $includeUserGeneratedContent, globalFilters: $globalFilters) {
            ${keys.map(key => {
    return `
      ${key} {
        day { value isoDate }
        week { value isoDate }
        month { value isoDate }
      }`
  })}
        }
    }`;
};

const QUERIES = [
  buildQuery('visitsAndVisitors', 'visits', 'visitors'),
  buildQuery('consumingUsers', 'consumingUsers'),
  buildQuery('interactingUsers', 'interactingUsers'),
];

export const usePageActivityQuery = (
  senderId: string,
  from: string,
  to: string,
  includeUserGeneratedContent: boolean
) => {
  const { appliedGlobalFilters } = useGlobalFiltersContext();
  const { timezone } = useSettingsContext();

  const [data, setData] = useState<Data>({
    visits: EMPTY_SERIES,
    visitors: EMPTY_SERIES,
    consumingUsers: EMPTY_SERIES,
    interactingUsers: EMPTY_SERIES,
    requestId: ''
  } as Data);
  const [loading, setLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [refetchTime, setRefetchTime] = useState<number>();

  const variables = { senderId, from, to, timezone, includeUserGeneratedContent, globalFilters: appliedGlobalFilters };

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const client = useApolloClient();

  useEffect(() => {
    if (!isMounted) {
      return
    }
    const currentRequestId = Math.random().toString(36).substring(2);

    setLoading(true);

    setData(data => ({
      visits: EMPTY_SERIES,
      visitors: EMPTY_SERIES,
      consumingUsers: EMPTY_SERIES,
      interactingUsers: EMPTY_SERIES,
      requestId: currentRequestId
    }));

    Promise.all(
      QUERIES.map(async (query: string) => {
        const result = await client.query<WebApiResponse>({ query: gql`${query}`, variables });

        setData(data => {
          if (currentRequestId !== data.requestId) {
            return data;
          }
          if (!result.data.senderActivity) {
            return data;
          }
          const validData = Object.entries(result.data.senderActivity)
            .filter(([_, value]) => value !== null)
            .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
          if (!Object.keys(validData).length) {
            return data;
          }
          return {
            ...data,
            ...validData,
          }
        });
      })
    ).finally(() => setLoading(false));
  }, [isMounted, senderId, from, to, includeUserGeneratedContent, appliedGlobalFilters, refetchTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    refetch: () => setRefetchTime(Date.now()),
    loading,
    data: { senderActivity: data },
  };
};
