import React, {useEffect} from 'react';
import {Box, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useSenderInfoQuery} from '../SenderInfoQuery';
import {Link as RouterLink, useLocation, useParams} from 'react-router-dom';
import AudienceCard from '../../charts/audience/AudienceCard';
import PlaceholderText from '../../placeholder/PlaceholderText';
import VisitsCard from '../../charts/visits/VisitsCard';
import {useSideNavContext} from '../../side-navigation/SideNavContext';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {useLayoutContext} from '../../layout/LayoutContext';
import {SenderInfo} from '../../schema';
import HomepageActivityCard from '../../charts/homepage-activity/HomepageActivityCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 40,

      '&:last-child': {
        marginBottom: 0,
      },

      '& > *': {
        flex: 1,
        marginRight: 40,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    visitsCard: {
      flex: 1,
    },
    audienceCard: {
      flex: 1,
    },
    overviewRedirect: {},
    '@media print': {
      overviewRedirect: {
        display: 'none',
      },
    },
    '@media only screen and (max-width: 1000px)': {
      visitsCard: {
        flexBasis: '100%',
        marginRight: 0,
        marginBottom: theme.spacing(5),
      },
    },
  }),
);

export default function HomepageDashboard() {
  const {homepageId} = useParams<{ homepageId: string }>();
  const classes = useStyles();
  const {search} = useLocation();
  const {loading, data} = useSenderInfoQuery(homepageId);
  const {reportData} = useReportDataContext();
  const {isMinimalisticView} = useLayoutContext();
  const {activeSideNavOption, setActiveSideNavOption} = useSideNavContext();
  const senderName = data?.senderInfo?.name || 'Dashboard';

  reportData.senderInfo = data?.senderInfo || {} as SenderInfo;
  reportData.senderInfoLoading = loading;

  useEffect(() => {
    document.title = `Haiilo Intranet Analytics | Homepage: ${senderName}`;
    setActiveSideNavOption('homepages');
  }, [setActiveSideNavOption, senderName]);

  return (
    <React.Fragment>
      {!isMinimalisticView && (
        <Link className={classes.overviewRedirect} component={RouterLink} to={`/${activeSideNavOption}${search}`}
              variant={'body2'}
              data-testid={'back-to-overview-link'}>
          ← Back to overview
        </Link>
      )}
      <Typography variant="h1" data-testid={`sender-name`}>
        <PlaceholderText loading={loading}>{senderName || '0000'}</PlaceholderText>
      </Typography>

      <Box className={classes.row}>
        <HomepageActivityCard homepageId={homepageId}/>
      </Box>

      <Box className={classes.row}>
        <VisitsCard senderId={homepageId} className={classes.visitsCard}/>
        <AudienceCard senderId={homepageId} className={classes.audienceCard}/>
      </Box>

    </React.Fragment>);
}
