import React, { ReactElement, useState } from 'react';
import { Box, IconButton, Link, Typography, createStyles, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';
import { useLocalStorage } from '../../utils/useStorage';

const ONE_DAY = 24 * 60 * 60 * 1000;

interface Props {
  className: string,
  closeable?: boolean,
}

const useStyles = makeStyles(() =>
  createStyles({
    basicStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  }),
);

function initDate() {
  const date = process.env.REACT_APP_LOGIN_WARNING_DATE;

  return date ? moment(date, '').format(moment.localeData().longDateFormat('L')) : null;
}

function initShow(formattedDate: string | null, closeable: boolean, loginWarningDismissed: number) {
  if (!formattedDate) {
    return false;
  }
  if (!closeable) {
    return true;
  }
  return Date.now() > loginWarningDismissed;
}

export default function CoyoLoginWarningBox({ className, closeable = false }: Props): ReactElement | null {
  const classes = useStyles();
  const [formattedDate] = useState(initDate);
  const [loginWarningDismissed, setLoginWarningDismissed] = useLocalStorage<number>('loginWarningDismissed', 0);

  const [show, setShow] = useState(() => initShow(formattedDate, closeable, loginWarningDismissed));

  if (!show) {
    return null;
  }

  const dismissWarning = () => {
    setShow(false);
    setLoginWarningDismissed(Date.now() + ONE_DAY);
  }

  return (
    <Box className={`${className} ${classes.basicStyle}`}>
      <Typography>
        Username / Password login will be deactivated on <strong>{formattedDate}</strong>.<br />
        Please login with SSO by clicking on <strong>"Analytics"</strong> inside your intranets user menu.<br />
        "My Communities" and "My Pages" can only be accessed using SSO.<br />
        More Information can be found <Link href="https://support.haiilo.com/hc/de/articles/360020733020-Analytics-in-Haiilo" target="_blank" rel="noopener noreferrer">here</Link>.
      </Typography>
      {!!closeable && (
        <IconButton size="small" aria-label="close" color="inherit" onClick={dismissWarning}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
