import React, {useContext} from 'react';

export interface ILayoutContext {
  isMinimalisticView: boolean;
  setMinimalisticView: (option: boolean) => void;
}

const DEFAULT_VALUE: ILayoutContext = {
  isMinimalisticView: false,
  setMinimalisticView: () => null,
};

export const LayoutContext = React.createContext<ILayoutContext>(DEFAULT_VALUE);

export const useLayoutContext = () => useContext(LayoutContext);
