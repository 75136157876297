import moment, {Moment} from 'moment-timezone';

export interface DateRangePreset {
  name: string,
  getFrom: (timezone: string) => Moment,
  getTo: (timezone: string) => Moment,
}

export const DEFAULT_PRESETS: DateRangePreset[] = [{
  name: 'Last 7 days',
  getFrom: (timezone: string) => moment.tz(timezone).subtract(6, 'days'),
  getTo: (timezone: string) => moment.tz(timezone),
}, {
  name: 'Last 28 days',
  getFrom: (timezone: string) => moment.tz(timezone).subtract(27, 'days'),
  getTo: (timezone: string) => moment.tz(timezone),
}, {
  name: 'This week',
  getFrom: (timezone: string) => moment.tz(timezone).startOf('isoWeek'),
  getTo: (timezone: string) => moment.tz(timezone),
}, {
  name: 'This month',
  getFrom: (timezone: string) => moment.tz(timezone).startOf('month'),
  getTo: (timezone: string) => moment.tz(timezone),
}, {
  name: 'This year',
  getFrom: (timezone: string) => moment.tz(timezone).startOf('year'),
  getTo: (timezone: string) => moment.tz(timezone),
}];
