import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { createBrowserHistory } from 'history';
import { getParameterByName, hasParams } from '../coyo-controls/WebUtils';
import { useSettingsContext } from '../settings/SettingsContext';

export interface IDateRangeContext {
  startDate: Moment,
  endDate: Moment,
  setRange: (start: Moment, end: Moment) => void
}

export const DateRangeContext = createContext<IDateRangeContext>({
  startDate: moment.tz().startOf('day'),
  endDate: moment.tz().endOf('day'),
  setRange: () => null,
});

export const useDateRangeContext = () => useContext(DateRangeContext);

interface Props {
  children: any;
}

const removeDates = (search: string) => {
  const url = new URLSearchParams(search);
  url.delete('startDate');
  url.delete('endDate');
  return url.toString();
};

export const DateRange = (props: Props) => {
  const { timezone } = useSettingsContext();
  const history = createBrowserHistory();

  const [initialStateStartDate, initialStateEndDate] = useMemo(() => {
    const initialStartDate = moment.tz(timezone).subtract(6, 'days').startOf('day');
    const initialEndDate = moment.tz(timezone).endOf('day')

    const hasStartAndEndDate = hasParams(history.location.search, 'startDate', 'endDate');
    const startDayFromParams = moment.tz(getParameterByName(history.location.search, 'startDate'), timezone).startOf('day');
    const endDayFromParams = moment.tz(getParameterByName(history.location.search, 'endDate'), timezone).endOf('day');
    const initialStateStartDate =
      hasStartAndEndDate && startDayFromParams.isValid()
        ? startDayFromParams
        : initialStartDate;
    const initialStateEndDate =
      hasStartAndEndDate && endDayFromParams.isValid()
        ? endDayFromParams
        : initialEndDate;

    return [initialStateStartDate, initialStateEndDate]
  }, [history.location.search, timezone]);

  const [startDate, setStartDate] = useState<Moment>(initialStateStartDate);
  const [endDate, setEndDate] = useState<Moment>(initialStateEndDate);

  const context: IDateRangeContext = {
    startDate,
    endDate,
    setRange: (start, end) => {
      setStartDate(start);
      setEndDate(end);
    },
  };

  useMemo(() => {
    setStartDate(initialStateStartDate);
    setEndDate(initialStateEndDate);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const search = removeDates(history.location.search);
    history.push(`?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}${search? `&${search}` : ''}`);
  }, [startDate, endDate, history]);

  return (
    <DateRangeContext.Provider value={context}>
      {props.children}
    </DateRangeContext.Provider>
  );
};
