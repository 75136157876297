import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useFeatureToggleContext } from './feature-toggle/FeatureToggleContext';
import { useSettingsContext } from './settings/SettingsContext';
import Login from './login/Login';
import ForgotPassword from './login/ForgotPassword';
import { SideNavProvider } from './side-navigation/SideNavProvider';
import Layout from './layout/Layout';
import AuthRoutes from './auth/AuthRoutes';
import Dashboard from './dashboard/Dashboard';
import SendersOverview from './senders/overview/SendersOverview';
import HomepagesOverview from './homepages/overview/HomepagesOverview';
import SenderDashboard from './senders/dashboard/SenderDashboard';
import HomepageDashboard from './homepages/dashboard/HomepageDashboard';
import { Feature, FeatureToggle } from './feature-toggle/feature';
import ContentInsightsDashboard from './content/dashboard/ContentInsightsDashboard';

export default function Routes() {
  const { isDisabledFeature } = useFeatureToggleContext();
  const { initializing, showMainLinks, showConsolidatedDashboardLinks } = useSettingsContext();
  const [routes, setRoutes] = useState<any>();

  useEffect(() => {
    setRoutes(renderRoutes(isDisabledFeature, initializing, showMainLinks, showConsolidatedDashboardLinks));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing, setRoutes, showMainLinks, showConsolidatedDashboardLinks]);

  const renderRoutes = (isDisabledFeature: (feature: Feature) => boolean, initializing: boolean, showMainLinks: boolean, showConsolidatedDashboardLinks: boolean) => {

    const consolidatedDashboardEnabled = !isDisabledFeature(FeatureToggle.ConsolidatedDashboard);

    const defaultRoute = (consolidatedDashboardEnabled && showConsolidatedDashboardLinks && !showMainLinks) ? '/my-pages' : '/dashboard';

    const nav: JSX.Element[] = [];

    const route = (path: string, component: () => JSX.Element) => {
      nav.push(<Route key={path} exact path={path} component={component} />);
    }

    if (showMainLinks) {
      route('/dashboard', Dashboard);

      route('/content/:contentId', ContentInsightsDashboard);

      route('/:sender(pages|communities)', SendersOverview);
      route('/:senderType(page|community)/:senderId/content/:contentId', ContentInsightsDashboard);
      route('/:senderType(page|community)/:senderId', SenderDashboard);

      if (!initializing && !isDisabledFeature(FeatureToggle.LandingPages)) {
        route('/homepages', HomepagesOverview);
        route('/homepage/:homepageId', HomepageDashboard);
      }
    }

    if (showConsolidatedDashboardLinks && consolidatedDashboardEnabled) {
      route('/:sender(my-pages|my-communities)', SendersOverview);
    }

    route('/:senderType(my-page|my-community)/:senderId/content/:contentId', ContentInsightsDashboard);
    route('/:senderType(my-page|my-community)/:senderId', SenderDashboard);

    return (
      <Switch>
        <Redirect exact from="/" to={defaultRoute} />,
        <Route exact path="/login" component={Login} />,
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <SideNavProvider>
          <Layout>
            <AuthRoutes>
              {nav}
            </AuthRoutes>
          </Layout>
        </SideNavProvider>
      </Switch>
    );
  };

  return (
    <>{routes}</>
  );
}
