import React, {ReactChild, useState} from "react";
import {IReportDataContext, ReportDataContext} from "./ReportDataContext";
import {ReportData} from "./ReportDataContext";

interface Props {
  children: ReactChild,
}

export function ReportDataProvider({children}: Props) {
  const [reportData] = useState<ReportData>({} as ReportData);

  const reportDataContext: IReportDataContext = {
    reportData
  };

  return (
    <ReportDataContext.Provider value={reportDataContext}>{children}</ReportDataContext.Provider>
  );
}
