import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DateRangeSelection from '../date-range-selection/DateRangeSelection';
import ReportGeneration from '../report-generation/ReportGeneration';
import GlobalFilters from '../coyo-components/global-filters/GlobalFilters';
import {Box, IconButton} from '@material-ui/core';
import {useGlobalFiltersContext} from '../global-filters/GlobalFiltersContext';
import {ReactComponent as ClearIcon} from '../svg/clear.svg';

interface Props {
  height: number,
  drawerWidth: number
}

export default function HeaderToolbar({height}: Props) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        zIndex: theme.zIndex.drawer - 1,
        borderBottomColor: theme.palette.background.default,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
        overflow: 'hidden',
        width: '100%',
        position: 'sticky',
      },
      toolbar: {
        minHeight: height,
        height: 'auto',
        display: 'flex',
        padding: theme.spacing(2, 6),
        justifyContent: 'space-between',
        margin: 'auto',
        flexWrap: 'wrap',
        gap: '8px',
      },
      headerText: {
        fontWeight: 600,
        lineHeight: 1.5,
      },
      analyticsLogo: {
        marginRight: 8,
      },
      form: {
        display: 'flex',
      },
      label: {
        position: 'relative',
        transform: 'none',
        paddingRight: 12,
      },
      globalFiltersWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flex: '0 0 auto',
      },
      wrapperToolbar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      unappliedFiltersWrapper: {
        position: 'relative',
        height: 0,
        width: 255,
        zIndex: 1,
      },
      unappliedFilters: {
        display: 'flex',
        position: 'absolute',
        padding: '12px 24px',
        background: theme.palette.primary.main,
        color: '#ffffff', // always N0
        borderRadius: '0px 0px 8px 8px',
        whiteSpace: 'nowrap',
      },
      iconWrapper: {
        marginLeft: theme.spacing(2.5),
      },
      svgIcon: {
        width: 20,
        height: 20,
        fill: '#ffffff', // always N0
      },
      '@media print': {
        leftSide: {
          width: '100%',
          padding: 16,
        },
        appBar: {
          padding: theme.spacing(0, 2),
          width: '290mm',
          left: 0,
        },
      },
    }),
  );

  const classes = useStyles();
  const {needToBeApplied, selectedGlobalFilters} = useGlobalFiltersContext();
  const [isUnappliedBoxOpen, setIsUnappliedBoxOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsUnappliedBoxOpen(needToBeApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGlobalFilters]);


  const renderUnappliedFilters = () => {
    return (
      <div className={classes.unappliedFiltersWrapper} data-testid={'unapplied-filters-info'}>
        <div className={classes.unappliedFilters}>
          <span>You have unapplied Filters</span>
          <Box className={`${classes.iconWrapper}`}>
            <IconButton size={'small'}
                        data-testid={'clear-icon-button'}
                        onClick={() => setIsUnappliedBoxOpen(false)}
                        aria-label={'Close unapplied filters information box'}>
              <ClearIcon className={`${classes.svgIcon}`}/>
            </IconButton>
          </Box>
        </div>
      </div>
    );
  };

  return (
    <Box className={classes.wrapperToolbar}>
      <AppBar position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <DateRangeSelection data-testid={'date-range-selection'}/>
          <Box className={classes.globalFiltersWrapper}>
            <GlobalFilters/>
            <ReportGeneration data-testid={'report-generation'}/>
          </Box>
        </Toolbar>
      </AppBar>
      {(needToBeApplied && isUnappliedBoxOpen) && renderUnappliedFilters()}
    </Box>
  );
}
