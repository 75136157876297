import React from 'react';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import {Box} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import HighlightBox from '../../chart-types/highlight-box/HighlightBox';
import {ReactComponent as ThumbUp} from '../../svg/thumbs-up.svg';
import {ReactComponent as SpeechBubble} from '../../svg/speech-bubble-circle.svg';
import {ReactComponent as Share} from '../../svg/share.svg';
import {usePageHighlightsQuery} from './SenderHighlightsQuery';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {SenderHighlights} from '../../schema';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {SenderTypeFrontend} from '../../types';
import moment from 'moment-timezone';
import {generateNumericDates} from '../DateUtils';

interface Props {
  senderId: string,
  senderType: SenderTypeFrontend,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    values: {
      display: 'flex',
    },
    highlightBox: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    }
  }));

export default function SenderHighlightsCard({senderId, senderType}: Props) {
  const classes = useStyles();
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = usePageHighlightsQuery(senderId, fromDay, toDay, true);
  const {reportData} = useReportDataContext();
  const senderHighlights: SenderHighlights | undefined = data?.senderHighlights;

  const helpText = {
    what: `Shows the number of reactions, comments and shares, which happened to content related to this ${senderType} in the configured time range.`,
    how: 'All reactions, comments and shares which happened in the configured time range are counted. Important is not, when the content was created, but when the reaction, comment or share was made.',
  };

  const startDateFormatted = startDate.format(moment.localeData().longDateFormat('LL'));
  const endDateFormatted = endDate.format(moment.localeData().longDateFormat('LL'));

  const helpTextReactions = `Content related to this ${senderType} has been reacted to ${data?.senderHighlights?.likes.total} times
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const helpTextComments = `Content related to this ${senderType} was commented ${data?.senderHighlights?.comments.total} times
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const helpTextShares = `Content related to this ${senderType} was shared ${data?.senderHighlights?.shares.total} times
    between ${startDateFormatted} and ${endDateFormatted}.`;

  reportData.senderHighlights = senderHighlights;
  reportData.senderHighlightsLoading = loading;

  return (
    <CoyoCard id={'sender-highlights-card'} helpText={helpText} title={'Highlights'} loading={loading}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              reload={refetch}>
      <Box className={classes.values}>
        <HighlightBox loading={loading} title={'Reactions'} data={senderHighlights?.likes} icon={<ThumbUp/>}
                      valueType={'number'} helpText={helpTextReactions} className={classes.highlightBox}/>
        <HighlightBox loading={loading} title={'Comments'} data={senderHighlights?.comments} icon={<SpeechBubble/>}
                      valueType={'number'} helpText={helpTextComments} className={classes.highlightBox}/>
        <HighlightBox loading={loading} title={'Shares'} data={senderHighlights?.shares} icon={<Share/>}
                      valueType={'number'} helpText={helpTextShares} className={classes.highlightBox}/>
      </Box>
    </CoyoCard>
  );
}
