import React, {ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Typography} from '@material-ui/core';
import CoyoTooltip from '../tooltip/CoyoTooltip';
import {ReactComponent as WarningIcon} from '../../svg/warning-triangle.svg'

interface Props {
  warningText: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningText: {
      paddingLeft: theme.spacing(1)
    }
  }),
);

export default function CoyoWarningBox({warningText}: Props): ReactElement {
  const classes = useStyles();

  const helpTextTemplate =
    <React.Fragment>
      <Typography data-testid={'warning-text'}>{warningText}</Typography>
    </React.Fragment>;

  return (
    <div className={classes.warningText}>
      <CoyoTooltip title={helpTextTemplate} placement={'bottom-end'}>
        <IconButton size="small" aria-label="warning">
          <WarningIcon fontSize="small"/>
        </IconButton>
      </CoyoTooltip>
    </div>
  );
}
