import React, { useContext, useEffect, useMemo } from 'react';
import { useSettingsQuery } from './SettingsQuery';
import { Settings } from '../schema';
import moment from 'moment-timezone';
import momentNonTz from 'moment';

import { useLocalStorage } from '../utils/useStorage';
import { GlobalFilterColumn } from '../coyo-components/global-filters/GlobalFilterColumn';
import { useAuthContext } from '../auth/AuthContext';
import { FeatureToggle } from '../feature-toggle/feature';

export const DEFAULT_TIMEZONE = process.env.REACT_APP_DEFAULT_TIMEZONE || 'Europe/Berlin';
const DEFAULT_MIN_GROUP_SIZE = 5;
const DEFAULT_GLOBAL_FILTERS = ['location', 'company', 'department'] as GlobalFilterColumn[];

enum AccessType {
  Admin = 'admin-access',
  User = 'user-access'
}

interface Context extends Settings {
  initializing: boolean;
  setTimezone: (timezone: string) => void;
  timezone: string;
  globalFilters: GlobalFilterColumn[];
  accessType: AccessType;
  showMainLinks: boolean;
  showConsolidatedDashboardLinks: boolean;
}

export const SettingsContext = React.createContext<Context>({
  initializing: true,
  disabledFeatures: [],
  availableTimezones: [],
  setTimezone: (timezone: string) => null,
  timezone: DEFAULT_TIMEZONE,
  tenantId: '',
  minGroupSize: DEFAULT_MIN_GROUP_SIZE,
  globalFilters: DEFAULT_GLOBAL_FILTERS as GlobalFilterColumn[],
  accessType: AccessType.User,
  showMainLinks: false,
  showConsolidatedDashboardLinks: false,
});

export const useSettingsContext = () => useContext(SettingsContext);

interface Props {
  children: any,
}

export function SettingsProvider({ children }: Props) {
  const { data, loading } = useSettingsQuery();
  const [timezone, setTimezone] = useLocalStorage<string>('timezone', DEFAULT_TIMEZONE);
  const [disabledFeatures, setDisabledFeatures] = useLocalStorage<string[]>('disabledFeatures', [FeatureToggle.ConsolidatedDashboard]);
  const [minGroupSize, setMinGroupSize] = useLocalStorage<number>('minGroupSize', DEFAULT_MIN_GROUP_SIZE);
  const [accessType, setAccessType] = useLocalStorage<String>('accessType', AccessType.User);
  const [globalFilters, setGlobalFilters] = useLocalStorage<GlobalFilterColumn[]>('globalFilters', DEFAULT_GLOBAL_FILTERS);
  const authorization = useAuthContext();

  const availableTimezones = data?.settings?.availableTimezones;

  useEffect(() => {
    if (availableTimezones && availableTimezones.length > 0 && !availableTimezones.includes(timezone)) {
      setTimezone(availableTimezones[0]);
    }
  }, [availableTimezones, setTimezone, timezone]);

  useMemo(() => {
    if (loading || !data?.settings) {
      return;
    }

    const disabledFeaturesData = data.settings.disabledFeatures;
    const minGroupSizeData = data.settings.minGroupSize;
    const globalFiltersData = data.settings.globalFilters as GlobalFilterColumn[];
    const accessTypeData = data.settings.accessType;

    if (!!disabledFeaturesData) {
      setDisabledFeatures(disabledFeaturesData);
    }
    if (!!minGroupSizeData) {
      setMinGroupSize(minGroupSizeData);
    }
    if (!!globalFiltersData) {
      setGlobalFilters(globalFiltersData);
    }
    if (!!accessTypeData) {
      setAccessType(accessTypeData);
    }
  }, [loading, data]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const localeData = momentNonTz.localeData(navigator.language);
    const localeSpec: moment.LocaleSpecification = (localeData as any)._config;
    moment.updateLocale(navigator.language, localeSpec);

    moment.tz.setDefault(timezone || undefined);
    moment.locale(navigator.language);
    momentNonTz.locale(navigator.language);
  }, [timezone]);

  const showMainLinks = accessType === AccessType.Admin;
  const showConsolidatedDashboardLinks = !disabledFeatures.includes(FeatureToggle.ConsolidatedDashboard) && authorization.type !== 'cognito';

  const context = useMemo(() => ({
    initializing: loading,

    setTimezone,
    timezone: timezone,

    showMainLinks,
    showConsolidatedDashboardLinks,

    disabledFeatures,
    minGroupSize,
    globalFilters,

    ...data?.settings,
  } as Context), [data?.settings, disabledFeatures, globalFilters, loading, minGroupSize, setTimezone, showConsolidatedDashboardLinks, showMainLinks, timezone]);

  return (
    <SettingsContext.Provider value={context}>
      {children}
    </SettingsContext.Provider>
  );
}
