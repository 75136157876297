import {Workbook} from 'exceljs';
import {Highlights} from '../../schema';
import {secondsToHms} from '../../charts/DateUtils';

export function addHighlightsReport(workbook: Workbook, highlightsData: Highlights) {
  const worksheet = workbook.addWorksheet('Highlights');
  worksheet.columns = [
    {header: 'Highlights Type', width: 20},
    {header: 'Value', width: 10},
    {header: 'Trend', width: 10, style: {numFmt: '0%'}},
  ];
  const {pages, workspaces, messages, avgSessionSeconds} = highlightsData;
  worksheet.addRow(['Active Pages', pages.total, pages.trendPercent ? pages.trendPercent / 100 : '']);
  worksheet.addRow(['Active Communities', workspaces.total, workspaces.trendPercent ? workspaces.trendPercent / 100 : '']);
  worksheet.addRow(['Messages sent', messages.highlights.total, messages.highlights.trendPercent ? messages.highlights.trendPercent / 100 : '']);
  worksheet.addRow(['Average Session Length', secondsToHms(avgSessionSeconds.total), avgSessionSeconds.trendPercent ? avgSessionSeconds.trendPercent / 100 : '']);

  worksheet.autoFilter = 'A1:D1';
}
