import {Histogram} from '../../schema';
import moment from 'moment-timezone';

export function getValueFromTimeValues(histogram: Histogram, grouping: keyof Histogram, isoDate: string): number {
  const timeValues = histogram[grouping];
  const timeValue = timeValues.find(value => moment(value.isoDate).isSame(moment(isoDate)));

  return timeValue?.value || 0;
}

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
