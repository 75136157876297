import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {useSettingsContext} from '../../settings/SettingsContext';

export const usePageActivityQuery = (senderId: string, from: string, to: string) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {timezone} = useSettingsContext();
  return useApiQuery(gql`
      query senderActivity($senderId: String!, $from: String!, $to: String!, $timezone: String!, $globalFilters: [GlobalFilter!]) {
          senderActivity(senderId: $senderId, from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters) {
              visits {
                  day {
                      value
                      isoDate
                  }
                  week {
                      value
                      isoDate
                  }
                  month {
                      value
                      isoDate
                  }
              }
              visitors {
                  day {
                      value
                      isoDate
                  }
                  week {
                      value
                      isoDate
                  }
                  month {
                      value
                      isoDate
                  }
              }
          }
      }`, {senderId, from, to, timezone, globalFilters: appliedGlobalFilters});
};
