import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Link, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoTable from '../../coyo-components/table/CoyoTable';
import CoyoTableHeaderCell, { SortOrder } from '../../coyo-components/table-header-cell/CoyoTableHeaderCell';
import { useHomepagesQuery } from '../HomepagesQuery';
import { useDateRangeContext } from '../../date-range-selection/DateRangeContext';
import CoyoTableCellBar from '../../coyo-components/table-cell-bar/CoyoTableCellBar';
import { useSideNavContext } from '../../side-navigation/SideNavContext';
import { useReportDataContext } from '../../report-data/ReportDataContext';
import CoyoHelpBox from '../../coyo-components/help-box/CoyoHelpBox';
import PlaceholderText from '../../placeholder/PlaceholderText';
import { LandingPage } from '../../schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',

      '& .MuiIconButton-sizeSmall': {
        paddingTop: 5,
      },
    },
    svgIcon: {
      width: 16,
      height: 16,
    },
    svgIconActive: {
      fill: theme.palette.action.focus,
    },
    row: {
      display: 'flex',
      marginBottom: 40,

      '&:last-child': {
        marginBottom: 0,
      },

      '& > *': {
        flex: 1,
        marginRight: 40,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    nameCell: {
      maxWidth: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default function HomepagesOverview() {
  const classes = useStyles();
  const rowAmount = 20;
  const { search } = useLocation();
  const [visibleRowsAmount, setVisibleRowsAmount] = useState<number>(rowAmount);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { startDate, endDate } = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const [sorting, setSorting] = useState<Sorting>({ column: 'visits', order: 'desc' });
  const { setActiveSideNavOption } = useSideNavContext();
  const { loading, data } = useHomepagesQuery(
    fromDay,
    toDay,
    sorting.column,
    sorting.order,
    searchTerm || null
  );
  const { reportData } = useReportDataContext();
  const landingPages = data.landingPages;
  const pageTitle = `Homepages Overview`;
  const helpText = {
    what: `This Overview shows total visits, visitors and the average visit duration for all homepages.`,
    how: <React.Fragment>
      Visits are counted everytime anyone is opening the homepage in COYO in the selected time range.<br />
      Visitors is the total number of unique users, who opened the homepage in the selected time range.<br />
      The average visit length is calculated by adding all visit lengths and averaged by the number of visits.
    </React.Fragment>,
  };

  reportData.landingPage = data.landingPages;
  reportData.landingPageLoading = loading.data;

  useEffect(() => {
    document.title = `Haiilo Intranet Analytics | ${pageTitle}`;
    setActiveSideNavOption('homepages');
  }, [pageTitle, setActiveSideNavOption]);

  function isFocused(key: string) {
    return key === sorting.column;
  }

  const headerCells = [
    <CoyoTableHeaderCell key={`homepage-name`}
      title={`homepage name`}
      sorting={{
        onClick: setSorting,
        column: 'name',
        active: sorting.column === 'name',
      }}
      width={'31%'}
      search={{
        text: `Homepage name`,
        isFocused: isFocused('search'),
        setSearchTerm: setSearchTerm,
        executionOffset: 50,
      }}
      loading={false} />,
    <CoyoTableHeaderCell key={'visits'}
      title={'Visits'}
      helpText={`All homepage visits in period`}
      sorting={{
        onClick: setSorting,
        column: 'visits',
        active: sorting.column === 'visits',
      }}
      width={'23%'}
      focused={isFocused('visits')}
      loading={false} />,
    <CoyoTableHeaderCell key={'visitors'}
      title={'Visitors'}
      helpText={'Visitors in period'}
      sorting={{
        onClick: setSorting,
        column: 'visitors',
        active: sorting.column === 'visitors',
      }}
      width={'23%'}
      focused={isFocused('visitors')}
      loading={false} />,
    <CoyoTableHeaderCell key={'average-visit-duration'}
      title={'Average visit duration'}
      helpText={'Average visit duration in period'}
      sorting={{
        onClick: setSorting,
        column: 'avgVisitSeconds',
        active: sorting.column === 'avgVisitSeconds',
      }}
      width={'23%'}
      focused={isFocused('avgVisitSeconds')}
      loading={false} />,
  ];

  const visibleLandingPages = landingPages.slice(0, visibleRowsAmount);

  const max = {
    visits: Math.max(...visibleLandingPages.map(({ visits }) => visits ?? 0)),
    visitors: Math.max(...visibleLandingPages.map(({ visitors }) => visitors ?? 0)),
    avgVisitSeconds: Math.max(...visibleLandingPages.map(({ avgVisitSeconds }) => avgVisitSeconds ?? 0)),
  }

  return (
    <React.Fragment>
      <Box className={classes.header}>
        <Typography variant="h1">{pageTitle}</Typography>
        <CoyoHelpBox helpText={helpText} />
      </Box>
      <Box className={classes.row}>
        <CoyoCard id={`homepage-card`} loading={false}>
          <CoyoTable key={'homepage'}
            headerCells={headerCells}
            loading={loading.data && !landingPages.length}
            skeletonRow={<TableRow>
              <TableCell><PlaceholderText loading={true}>{'X'.repeat(25)}</PlaceholderText></TableCell>
              <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true} />
              <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true} />
              <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true} />
            </TableRow>}
            skeletons={rowAmount}
            isEmpty={landingPages.length === 0}
            showShowMoreButton={visibleRowsAmount < landingPages.length}
            onShowMoreClick={() => setVisibleRowsAmount(visibleRowsAmount + rowAmount)}>
            <TableBody>
              {visibleLandingPages.map(({ id, name, visits, visitors, avgVisitSeconds }: LandingPage) => (
                <TableRow key={id}>
                  <TableCell className={classes.nameCell} title={name}>
                    <Link component={RouterLink}
                      to={`/homepage/${id}${search}`}
                      variant={'body2'}
                      data-testid={`homepage-link-${id}`}>
                      {name}
                    </Link>
                  </TableCell>
                  <CoyoTableCellBar value={visits ?? 0}
                    maxValue={max.visits}
                    displayType="raw"
                    focused={isFocused('visits')}
                    loading={loading.visits}
                    showWarning={!loading.visits && visits === null}
                    warningText='Error loading data' />
                  <CoyoTableCellBar value={visitors ?? 0}
                    maxValue={max.visitors}
                    displayType="raw"
                    focused={isFocused('visitors')}
                    loading={loading.visitors}
                    showWarning={!loading.visitors && visitors === null}
                    warningText='Error loading data' />
                  <CoyoTableCellBar value={Math.round(avgVisitSeconds ?? 0)}
                    maxValue={max.avgVisitSeconds}
                    displayType="seconds-to-duration"
                    focused={isFocused('avgVisitSeconds')}
                    loading={loading.avgVisitSeconds}
                    showWarning={!loading.avgVisitSeconds && avgVisitSeconds === null}
                    warningText='Error loading data' />
                </TableRow>
              ))}
            </TableBody>
          </CoyoTable>
        </CoyoCard>
      </Box>
    </React.Fragment>);
}

export type SortColumn = 'name' | 'visits' | 'visitors' | 'avgVisitSeconds';

interface Sorting {
  column: SortColumn,
  order: SortOrder
}
