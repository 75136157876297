import {FormControl, IconButton, InputAdornment} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React, {ChangeEventHandler, useState} from 'react';
import {ReactComponent as VisibilityOff} from '../../svg/eye-outlined.svg';
import {ReactComponent as Visibility} from '../../svg/eye-solid.svg';
import CoyoInputField from '../input/CoyoInputField';
import CoyoInputLabel from '../input/CoyoInputLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& svg': {
        height: 20,
        width: 20,
      },
    },
    svgBlue: {
      fill: theme.palette.primary.main,
    },
  }),
);

interface Props {
  id: string;
  name: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  labelText?: string;
}

export function CoyoPasswordInput(props: Props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return <FormControl fullWidth className={`${props.className} ${classes.root}`}>
    <CoyoInputLabel shrink htmlFor="standard-adornment-password">{props.labelText || 'Password'}</CoyoInputLabel>
    <CoyoInputField fullWidth type={showPassword ? 'text' : 'password'}
                    value={props.value}
                    onChange={props.onChange}
                    id={props.id}
                    name={props.name}
                    autoComplete={props.name}
                    inputProps={{
                      'data-testid': 'password-input',
                      'aria-label': props.name
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          data-testid={'toggle-password-visibility'}
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Visibility className={classes.svgBlue}/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>
                    }/>
  </FormControl>;
}
