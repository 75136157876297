import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fallbackTable: {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      whiteSpace: 'nowrap',
      border: 0,
    },
  }));

interface Props {
  headerRowCount: 1 | 2,
  headerRow: string[],
  contentRows: string[][]
}

export function FallbackTable({headerRowCount, headerRow, contentRows}: Props) {
  const classes = useStyles()

  return (
    <table className={classes.fallbackTable}>
      <thead>
      <tr>
        {headerRowCount === 2 && <th data-testid={'placeholder-header'}/>}
        {React.Children.toArray(
          headerRow.map(headerItem =>
            <th scope={'col'} data-testid={'column-header'}>{headerItem}</th>,
          )
        )}
      </tr>
      </thead>
      <tbody>
      {React.Children.toArray(
        contentRows.map(row =>
          <tr>{React.Children.toArray(
            row.map((rowItem, index) => {
              if(headerRowCount === 2 && index === 0) {
                return <th scope={'row'} data-testid={'row-header'}>{rowItem}</th>;
              }

              return <td>{rowItem}</td>;
          }))}</tr>,
      ))}
      </tbody>
    </table>
  );
}
