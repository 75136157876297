import {useApiQuery} from '../apollo/ApiQuery';
import {gql} from '@apollo/client';

export const useSenderInfoQuery = (senderId: string) => useApiQuery(gql`
    query senderInfo($senderId: String!){
        senderInfo(senderId : $senderId) {
            type
            name
        }
    }`, {senderId});

