import {Workbook} from 'exceljs';
import {CONTENT_TYPE_NAMES} from '../../charts/content-performance/ContentPerformanceCard';
import {ContentPerformance} from '../../schema';

export function addContentPerformanceReport(workbook: Workbook, contentPerformanceData: ContentPerformance[]) {
  const worksheet = workbook.addWorksheet('Content Performance');
  worksheet.columns = [
    {header: 'Title', width: 60},
    {header: 'Type', width: 15},
    {header: 'Reactions', width: 9},
    {header: 'Comments', width: 9},
    {header: 'Shares', width: 9},
    {header: 'Reach', width: 9},
    {header: 'Views', width: 9},
    {header: 'Engagement rate', width: 15, style: {numFmt: '0.00%'}},
  ];

  contentPerformanceData.forEach((content) => {
    worksheet.addRow([content.title, CONTENT_TYPE_NAMES[content.type], content.likes, content.comments, content.shares, content.reach, content.views, content.engagementRate / 100]);
  });

  worksheet.autoFilter = 'A1:G1';
}
