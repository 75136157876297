import {Box, IconButton} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useEffect, useState} from 'react';
import {CoyoSearchInput} from '../../coyo-controls/search-input/CoyoSearchInput';
import PlaceholderText from '../../placeholder/PlaceholderText';
import {ReactComponent as SearchIcon} from '../../svg/search.svg';

const useStyles = makeStyles((theme: Theme) => ({
  svgIcon: {
    width: 20,
    height: 20,
  },
  focused: {
    display: 'flex',

    '& *': {
      color: '#0073e6',
      fill: '#0073e6',
      borderBottomColor: '#0073e6',
    },
  },
  searchBox: {
    display: 'flex',

    '& *': {
      lineHeight: 1.7,
    },
  },
  default: {
    display: 'flex',
    color: 'inherit',
  },
}));

interface Props {
  onChange: (value: string) => void;
  text: string;
  focused: boolean;
  loading: boolean;
  searchExecutionOffset?: number;
}

export default function CoyoSearch({onChange, text, focused, loading, searchExecutionOffset = 400}: Props) {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => onChange(searchTerm), searchExecutionOffset);
    return () => clearTimeout(timeOutId);
  }, [onChange, searchTerm, searchExecutionOffset]);

  function renderSearchButtonWithText() {
    return (
      <Box className={`${classes.searchBox}`}>
        <PlaceholderText loading={loading}>{text}</PlaceholderText>
        <IconButton size={'small'} onClick={() => setShowSearchInput(!showSearchInput)}
                    data-testid={'search-icon-button'}
                    style={{visibility: loading ? 'hidden' : 'visible'}}
                    disabled={loading}
                    aria-label={`Search in ${text}`}>
          <SearchIcon className={`${classes.svgIcon}`}/>
        </IconButton>
      </Box>
    );
  }

  function renderSearchInput() {
    return (
      <CoyoSearchInput id={'title-search-input'}
                       name={'title-search'}
                       value={searchTerm}
                       onClose={() => {
                         setSearchTerm('');
                         setShowSearchInput(false);
                       }}
                       onChange={change => {
                         setSearchTerm(change?.target?.value);
                       }}
                       placeholder={text}
                       className={`${classes.searchBox}`}/>
    );
  }

  return (
    <Box className={focused ? classes.focused : classes.default}>
      {showSearchInput ? renderSearchInput() : renderSearchButtonWithText()}
    </Box>
  );
}
