import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';

export const useCurrentUserActivityQuery = () => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  return useApiQuery(gql`
      query currentUserActivity($globalFilters: [GlobalFilter!]) {
          currentUserActivity(globalFilters: $globalFilters) {
              day
              week
              month
          }
      }
  `, {globalFilters: appliedGlobalFilters});
};
