import React from 'react';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {Box, Button} from '@material-ui/core';
import {GlobalFilterColumn} from './GlobalFilterColumn';
import CoyoMultiselect from '../../coyo-controls/multiselect/CoyoMultiselect';
import {AvailableGlobalFilter} from '../../schema';
import {makeStyles, Theme} from '@material-ui/core/styles';
import PlaceholderFilter from '../../placeholder/PlaceholderFilter';
import {useSettingsContext} from '../../settings/SettingsContext';

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    marginLeft: theme.spacing(1),

    '&:first-child': {
      marginLeft: 0,
    },
  },
  applyFilterButtonWrapper: {
    alignSelf: 'flex-end',
  },
  disabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  applyFilterButton: {
    margin: theme.spacing(1),
    marginRight: 0,
    marginBottom: 0,
    fontSize: 16,
    font: 'strong',
    height: theme.spacing(5) + 2, //add border height of multiselect
    alignSelf: 'flex-end',

    '&.Mui-disabled': {
      background: theme.palette.action.disabled,
      color: theme.palette.text.primary,
    },
  },
}));

export default function GlobalFilters() {
  const classes = useStyles();
  const {
    availableGlobalFilters,
    selectGlobalFilter,
    unselectGlobalFilter,
    loadingAvailableGlobalFilters,
    applySelectedGlobalFilters,
    needToBeApplied,
  } = useGlobalFiltersContext();
  const {globalFilters} = useSettingsContext();

  function renderDropdown(label: string, column: GlobalFilterColumn, items: AvailableGlobalFilter[]) {
    return (
      <Box className={classes.dropdown}>
        <CoyoMultiselect
          label={label}
          items={items}
          onSelection={value => selectGlobalFilter(column, value)}
          onUnselection={value => unselectGlobalFilter(column, value)}
          loading={loadingAvailableGlobalFilters}
          data-testid={'global-filter'}/>
      </Box>
    );
  }

  function renderLoadingFilters() {
    return (
      new Array(globalFilters.length).fill(null).map((_, i) => (
        <React.Fragment key={i}>
          <PlaceholderFilter loading={true} className={classes.dropdown}/>
        </React.Fragment>
      )));
  }

  return (
    <Box className={classes.filter}>
      {loadingAvailableGlobalFilters && renderLoadingFilters()}
      {!loadingAvailableGlobalFilters &&
        React.Children.toArray(
          globalFilters
            .map(globalFilter => {
              const availableGlobalFilter = availableGlobalFilters[globalFilter as keyof typeof availableGlobalFilters];
              if (!availableGlobalFilter)
                return null;
              return renderDropdown(globalFilter.charAt(0).toUpperCase() + globalFilter.slice(1), globalFilter, availableGlobalFilter);
            })
            .filter(filter => !!filter),
        )
      }
      <Box className={`${classes.applyFilterButtonWrapper} ${!needToBeApplied ? classes.disabled : ''}`}>
        <Button data-testid={'report-generation-button'}
                className={classes.applyFilterButton}
                variant="contained"
                color="primary"
                disableElevation
                disabled={!needToBeApplied}
                onClick={() => applySelectedGlobalFilters()}
        >
          Apply filter
        </Button>
      </Box>
    </Box>
  );
}
