import {useApiQuery} from '../../apollo/ApiQuery';
import {gql} from '@apollo/client';
import {SortOrder} from '../../coyo-components/table-header-cell/CoyoTableHeaderCell';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';

export const useContentPerformanceQuery = (
  from: string,
  to: string,
  title: string,
  typeInclusions: string[],
  sortColumn: string,
  sortOrder: SortOrder,
  limit: number,
  senderId: string | null,
  includeUserGeneratedContent: boolean,
) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  return useApiQuery(gql`
      query contentPerformance(
          $from: String!,
          $to: String!,
          $title: String,
          $typeInclusions: [String]!,
          $sortColumn: String!,
          $sortOrder: String!
          $limit: Int!,
          $senderId: String,
          $includeUserGeneratedContent: Boolean,
          $globalFilters: [GlobalFilter!]){
          contentPerformance(
              from: $from,
              to: $to,
              title: $title,
              typeInclusions:
              $typeInclusions,
              sortColumn: $sortColumn,
              sortOrder: $sortOrder,
              limit: $limit,
              senderId: $senderId,
              includeUserGeneratedContent: $includeUserGeneratedContent,
              globalFilters: $globalFilters) {
              id
              creationTime
              title
              type
              likes
              comments
              shares
              reach
              views
              reachTrackedSinceCreation
              engagementRate
              link
          }
      }`, {
    from,
    to,
    title,
    typeInclusions,
    sortColumn,
    sortOrder,
    limit,
    senderId,
    includeUserGeneratedContent,
    globalFilters: appliedGlobalFilters,
  });
};
