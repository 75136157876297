import React, {useState} from 'react';
import {
  ISideNavContext,
  ActiveSideNavOption,
  SideNavContext
} from './SideNavContext';

interface Props {
  children: any,
}

export function SideNavProvider({children}: Props) {
  const [selected, setSelected] = useState<ActiveSideNavOption>('dashboard');
  const context: ISideNavContext = {
    activeSideNavOption: selected,
    setActiveSideNavOption: setSelected,
  };
  return (
    <SideNavContext.Provider value={context}>
      {children}
    </SideNavContext.Provider>
  );
}
