import {FeatureToggleContext, IFeatureToggleContext} from './FeatureToggleContext';
import React from 'react';
import {Feature, FEATURES} from './feature';
import {useSettingsContext} from '../settings/SettingsContext';

interface Props {
  children: any,
}

export function FeatureToggleProvider({children}: Props) {
  const {disabledFeatures, initializing} = useSettingsContext();

  const context: IFeatureToggleContext = {
    isDisabledFeature: (feature: Feature) => {
      if (initializing) {
        return (localStorage.getItem('disabledFeatures') || FEATURES)?.includes(feature);
      }
      return disabledFeatures?.includes(feature);
    },
  };

  return (
    <FeatureToggleContext.Provider value={context}>
      {children}
    </FeatureToggleContext.Provider>
  );
}
