import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Link, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoTable from '../../coyo-components/table/CoyoTable';
import CoyoTableHeaderCell, { SortOrder } from '../../coyo-components/table-header-cell/CoyoTableHeaderCell';
import { useSendersQuery } from '../SenderQuery';
import { Sender } from '../../schema';
import { useDateRangeContext } from '../../date-range-selection/DateRangeContext';
import CoyoTableCellBar from '../../coyo-components/table-cell-bar/CoyoTableCellBar';
import { useSideNavContext } from '../../side-navigation/SideNavContext';
import { useReportDataContext } from '../../report-data/ReportDataContext';
import CoyoHelpBox from '../../coyo-components/help-box/CoyoHelpBox';
import { SenderTypeFrontend } from '../../types';
import PlaceholderText from '../../placeholder/PlaceholderText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',

      '& .MuiIconButton-sizeSmall': {
        paddingTop: 5,
      },
    },
    svgIcon: {
      width: 16,
      height: 16,
    },
    svgIconActive: {
      fill: theme.palette.action.focus,
    },
    row: {
      display: 'flex',
      marginBottom: 40,

      '&:last-child': {
        marginBottom: 0,
      },

      '& > *': {
        flex: 1,
        marginRight: 40,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    nameCell: {
      maxWidth: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

export default function SendersOverview() {
  const { sender } = useParams<{ sender: 'pages' | 'communities' | 'my-pages' | 'my-communities' }>();
  const senderName = sender.includes('pages') ? 'pages' : 'communities';
  const senderType: SenderTypeFrontend = senderName === 'pages' ? 'page' : 'community';
  const showConsolidatedDashboard = sender.startsWith('my-');

  const classes = useStyles();
  const { search } = useLocation();
  const rowAmount = 20;
  const [visibleRowsAmount, setVisibleRowsAmount] = useState<number>(rowAmount);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { startDate, endDate } = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const [sorting, setSorting] = useState<Sorting>({ column: 'visits', order: 'desc' });
  const { setActiveSideNavOption } = useSideNavContext();
  const { loading, data } = useSendersQuery(
    senderType === 'page' ? 'page' : 'workspace',
    fromDay,
    toDay,
    sorting.column,
    sorting.order,
    searchTerm || null,
    showConsolidatedDashboard
  );
  const { reportData } = useReportDataContext();
  const senders = data.senders;
  const pageTitle = `${senderName.charAt(0).toUpperCase() + senderName.slice(1)} Overview`
  const helpText = {
    what: `This Overview shows total visits, visitors and the average engagement rate for all ${senderName}.`,
    how: <React.Fragment>
      Visits are counted everytime anyone is opening the {senderType} in COYO in the selected time range.<br />
      Visitors is the total number of unique users, who opened the {senderType} in the selected time range.<br />
      The average engagement rate is calculated over all the content, which was posted by the {senderType} or on the
      timeline of
      the {senderType} and has been created during the selected time range.
    </React.Fragment>,
  };

  reportData.sender = data.senders;
  reportData.senderLoading = loading.data;

  useEffect(() => {
    document.title = `Haiilo Intranet Analytics | ${pageTitle}`;
    setActiveSideNavOption(sender);
  }, [pageTitle, sender, setActiveSideNavOption]);

  function isFocused(key: string) {
    return key === sorting.column;
  }

  const title = `${senderType.charAt(0).toUpperCase() + senderType.substring(1).toLowerCase()} name`;

  const headerCells = [
    <CoyoTableHeaderCell key={`${senderType}-name`}
      title={title}
      sorting={{
        onClick: setSorting,
        column: 'name',
        active: sorting.column === 'name',
      }}
      width={'31%'}
      search={{
        text: title,
        isFocused: isFocused('search'),
        setSearchTerm: setSearchTerm,
        executionOffset: 50,
      }}
      loading={false} />,
    <CoyoTableHeaderCell key={'visits'}
      title={'Visits'}
      helpText={`All ${senderType} visits in period`}
      sorting={{
        onClick: setSorting,
        column: 'visits',
        active: sorting.column === 'visits',
      }}
      width={'23%'}
      focused={isFocused('visits')}
      loading={false} />,
    <CoyoTableHeaderCell key={'unique-visits'}
      title={'Visitors'}
      helpText={'Visitors in period'}
      sorting={{
        onClick: setSorting,
        column: 'uniqueVisits',
        active: sorting.column === 'uniqueVisits',
      }}
      width={'23%'}
      focused={isFocused('uniqueVisits')}
      loading={false} />,
    <CoyoTableHeaderCell key={'average-engagement-rate'}
      title={'Average engagement rate'}
      helpText={'Average engagement rate of content in period'}
      sorting={{
        onClick: setSorting,
        column: 'avgEngagementRate',
        active: sorting.column === 'avgEngagementRate',
      }}
      width={'23%'}
      focused={isFocused('avgEngagementRate')}
      loading={false} />,
  ];

  const visibleSenders = senders.slice(0, visibleRowsAmount);

  const max = {
    visits: Math.max(...visibleSenders.map(({ visits }) => visits ?? 0)),
    uniqueVisits: Math.max(...visibleSenders.map(({ uniqueVisits }) => uniqueVisits ?? 0)),
    avgEngagementRate: Math.max(...visibleSenders.map(({ avgEngagementRate }) => avgEngagementRate ?? 0)),
  }

  return (
    <React.Fragment>
      <Box className={classes.header}>
        <Typography variant="h1">{pageTitle}</Typography>
        <CoyoHelpBox helpText={helpText} />
      </Box>
      <Box className={classes.row}>
        <CoyoCard id={`${senderType}-card`} loading={false}>
          <CoyoTable key={sender}
            headerCells={headerCells}
            loading={loading.data && !senders.length}
            skeletonRow={<TableRow>
              <TableCell><PlaceholderText loading={true}>{'X'.repeat(25)}</PlaceholderText></TableCell>
              <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true} />
              <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true} />
              <CoyoTableCellBar value={0} maxValue={100} displayType={'percent'} loading={true} />
            </TableRow>}
            skeletons={rowAmount}
            isEmpty={senders.length === 0}
            showShowMoreButton={visibleRowsAmount < senders.length}
            onShowMoreClick={() => setVisibleRowsAmount(visibleRowsAmount + rowAmount)}>
            <TableBody>
              {visibleSenders.map(({ id, name, visits, uniqueVisits, avgEngagementRate }: Sender) => (
                <TableRow key={id}>
                  <TableCell className={classes.nameCell} title={name}>
                    <Link component={RouterLink}
                      to={`/${showConsolidatedDashboard ? 'my-' : ''}${senderType}/${id}${search}`}
                      variant={'body2'}
                      data-testid={`sender-link-${id}`}>
                      {name}
                    </Link>
                  </TableCell>
                  <CoyoTableCellBar value={visits ?? 0}
                    maxValue={max.visits}
                    displayType="raw"
                    focused={isFocused('visits')}
                    loading={loading.visits}
                    showWarning={!loading.visits && visits === null}
                    warningText='Error loading data' />
                  <CoyoTableCellBar value={uniqueVisits ?? 0}
                    maxValue={max.uniqueVisits}
                    displayType="raw"
                    focused={isFocused('uniqueVisits')}
                    loading={loading.uniqueVisits}
                    showWarning={!loading.uniqueVisits && uniqueVisits === null}
                    warningText='Error loading data' />
                  <CoyoTableCellBar value={Math.round(avgEngagementRate ?? 0)}
                    maxValue={max.avgEngagementRate}
                    displayType="percent"
                    focused={isFocused('avgEngagementRate')}
                    loading={loading.avgEngagementRate}
                    showWarning={!loading.avgEngagementRate && avgEngagementRate === null}
                    warningText='Error loading data' />
                </TableRow>
              ))}
            </TableBody>
          </CoyoTable>
        </CoyoCard>
      </Box>
    </React.Fragment>);
}

export type SortColumn = 'name' | 'visits' | 'uniqueVisits' | 'avgEngagementRate';

interface Sorting {
  column: SortColumn,
  order: SortOrder
}
