import { ChartOptions } from 'chart.js';
import { merge } from 'lodash';
import { Labels } from './Heatmap';
import { Theme } from '@material-ui/core/styles';
import { getChartOptions } from '../ChartOptions';

export function getHeatmapChartOptions(theme: Theme, labels: Labels): ChartOptions<'matrix'> {
  const chartOptions = getChartOptions(theme);

  const heatmapChartOptions: ChartOptions<'matrix'> = {
    scales: {
      x: {
        min: 0,
        max: labels.x.length - 1,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          callback: value => typeof value === 'number' ? labels.x[value] : '',
        },
      },
      y: {
        min: 0,
        max: labels.y.length - 1,
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          callback: value => typeof value === 'number' ? labels.y[value] : '',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return merge(chartOptions, heatmapChartOptions);
}