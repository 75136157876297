import {gql, useApolloClient} from '@apollo/client';
import {Box} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import React, {ReactElement} from 'react';
import HighlightBox from '../../chart-types/highlight-box/HighlightBox';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {useReportDataContext} from '../../report-data/ReportDataContext';
import {Highlights, HighlightValues, Histogram, MessagesHighlightValues, WebApiResponse} from '../../schema';
import {useSettingsContext} from '../../settings/SettingsContext';
import {generateNumericDates, secondsToHms} from '../DateUtils';
import {
  useHighlightsAvgSessionSecondsQuery,
  useHighlightsMessagesQuery,
  useHighlightsPagesQuery,
  useHighlightsWorkspacesQuery,
} from './HighlightsQuery';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    values: {
      display: 'flex',
      flex: 4,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: '10%',

      '& .MuiPaper-root': {
        flex: '1 0 41%',
        display: 'flex',
      },
    },
    singleBox: {
      maxWidth: '240px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media only screen and (max-width: 1400px)': {
      values: {
        '& .MuiPaper-root': {
          margin: theme.spacing(1, 0),

          '&:first-child': {
            marginTop: 0,
          },
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
  }));

export interface Props {
  className?: string;
}

export default function HighlightsCard({className}: Props): ReactElement {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const apolloClient = useApolloClient();
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const messagesData = useHighlightsMessagesQuery(fromDay, toDay);
  const pagesData = useHighlightsPagesQuery(fromDay, toDay);
  const workspacesData = useHighlightsWorkspacesQuery(fromDay, toDay);
  const avgSessionSecondsData = useHighlightsAvgSessionSecondsQuery(fromDay, toDay);
  const {reportData} = useReportDataContext();
  const {timezone} = useSettingsContext();
  const messagesValues = messagesData.data?.highlights?.messages;
  const pagesValues = pagesData.data?.highlights?.pages;
  const workspacesValues = workspacesData.data?.highlights?.workspaces;
  const avgSessionSecondsValues = avgSessionSecondsData.data?.highlights?.avgSessionSeconds;
  const highlights: Highlights = {
    messages: messagesValues || {} as MessagesHighlightValues,
    pages: pagesValues || {} as HighlightValues,
    workspaces: workspacesValues || {} as HighlightValues,
    avgSessionSeconds: avgSessionSecondsValues || {} as HighlightValues,
  };

  const highlightsDataComplete = messagesValues?.highlights && pagesValues && workspacesValues && avgSessionSecondsValues;
  reportData.highlights = highlightsDataComplete ? {
    messages: messagesValues,
    pages: pagesValues,
    workspaces: workspacesValues,
    avgSessionSeconds: avgSessionSecondsValues,
  } as Highlights : undefined;
  reportData.highlightsLoading = messagesData.loading || pagesData.loading || workspacesData.loading || avgSessionSecondsData.loading;

  const getMessagesHighlightHistogramData = () => new Promise<Histogram>(async (resolve, reject) => {
    try {
      const {data} = await apolloClient.query<WebApiResponse>({
        query: MESSAGES_HIGHLIGHT_HISTOGRAM_QUERY,
        errorPolicy: 'all',
        variables: {
          from: startDate.toISOString(true),
          to: endDate.toISOString(true),
          timezone,
        },
      });
      const histogram = data?.highlights.messages?.histogram;
      if (histogram) resolve(histogram);
      else reject();
    } catch (error) {
      enqueueSnackbar((error as Record<string, string>)?.message, {variant: 'error'});
      reject();
    }
  });

  const startDateFormatted = startDate.format(moment.localeData().longDateFormat('LL'));
  const endDateFormatted = endDate.format(moment.localeData().longDateFormat('LL'));

  const activePagesHelpText = `There are in total ${highlights?.pages?.total} active pages on ${endDateFormatted}.`;
  const activeCommunitiesHelpText = `There are in total ${highlights?.workspaces?.total} active communities on ${endDateFormatted}.`;
  const averageSessionLengthHelpText = `A user was active in average ${secondsToHms(highlights?.avgSessionSeconds?.total)} per day 
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const messagesSentHelpText = `${(highlights?.messages?.highlights?.total || 0).toLocaleString()} messages were sent via the COYO chat 
    between ${startDateFormatted} and ${endDateFormatted}.`;

  return (
    <Box className={classes.values}>
      <CoyoCard id={'highlights-card'} loading={pagesData.loading} filterTimeRange={generateNumericDates(startDate, endDate)} reload={pagesData.refetch}>
        <HighlightBox loading={pagesData.loading} title={'Active pages'} data={highlights?.pages} valueType={'number'}
                      helpText={activePagesHelpText} className={classes.singleBox} reload={pagesData.refetch}
                      filterTimeRange={generateNumericDates(startDate, endDate)}/>
      </CoyoCard>
      <CoyoCard id={'highlights-card'} loading={workspacesData.loading} filterTimeRange={generateNumericDates(startDate, endDate)}>
        <HighlightBox loading={workspacesData.loading} title={'Active communities'} data={highlights?.workspaces}
                      valueType={'number'}
                      helpText={activeCommunitiesHelpText} className={classes.singleBox}
                      reload={workspacesData.refetch}
                      filterTimeRange={generateNumericDates(startDate, endDate)}/>
      </CoyoCard>
      <CoyoCard id={'highlights-card'} loading={avgSessionSecondsData.loading} filterTimeRange={generateNumericDates(startDate, endDate)}>
        <HighlightBox loading={avgSessionSecondsData.loading} title={'∅ session length'}
                      data={highlights?.avgSessionSeconds}
                      valueType={'seconds'}
                      helpText={averageSessionLengthHelpText} className={classes.singleBox}
                      reload={avgSessionSecondsData.refetch}
                      filterTimeRange={generateNumericDates(startDate, endDate)}/>
      </CoyoCard>
      <CoyoCard id={'highlights-card'} loading={messagesData.loading} filterTimeRange={generateNumericDates(startDate, endDate)}>
        <HighlightBox loading={messagesData.loading} title={'Messages sent'}
                      data={highlights?.messages?.highlights}
                      histogramData={getMessagesHighlightHistogramData}
                      data-testid={'messages-highlight'}
                      valueType={'number'}
                      helpText={messagesSentHelpText} className={classes.singleBox} reload={messagesData.refetch}
                      filterTimeRange={generateNumericDates(startDate, endDate)}/>
      </CoyoCard>
    </Box>
  );
}

const MESSAGES_HIGHLIGHT_HISTOGRAM_QUERY = gql`
    query messages($from: String!, $to: String!, $timezone: String!){
        highlights{
            messages {
                histogram(from: $from, to: $to, timezone: $timezone)  {
                    week {
                        value
                        isoDate
                    }
                    month {
                        value
                        isoDate
                    }
                    day {
                        value
                        isoDate
                    }
                }
            }
        }
    }
`;
