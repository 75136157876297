import moment, { Moment } from 'moment-timezone';
import { DateTime } from 'luxon';
import { DEFAULT_TIMEZONE } from '../settings/SettingsContext';

export type Distance = 'day' | 'week' | 'month'

/**
 * Generates gapless dates in ISO8601 format between `from` and `to`
 * @param from The first date (including).
 * @param to the last date (including).
 * @param distance The distance between the dates.
 */
export function generateIsoDates(from: Moment, to: Moment, distance: Distance): string[] {
  if (!from || !to || from.isAfter(to))
    return [];

  const dates: Moment[] = [];
  const max: Moment = to.clone().endOf(distance === 'week' ? 'isoWeek' : distance);
  let current: Moment = from.clone().startOf(distance === 'week' ? 'isoWeek' : distance);
  while (current.isSameOrBefore(max)) {
    dates.push(current);
    current = current.clone().add(1, distance);
  }
  if (!dates.find(date => date.endOf(distance === 'week' ? 'isoWeek' : distance).isSame(max)))
    dates.push(max.clone());

  return dates.map(date => toIsoFormat(date, distance));
}

export function generateNumericDates(from: Moment, to: Moment): string[] {
  if (!from || !to || from.isAfter(to))
    return [];

  const dates: string[] = [];
  dates.push(from.format('YYYY-MM-DD'));
  dates.push(to.format('YYYY-MM-DD'));

  return dates;
}

export type Unit = 'day' | 'week' | 'month'

/**
 * Formats the `date` to an ISO date string. The returned ISO date string will start at the defined `startUnit`.
 * @param date The date that should be formatted.
 * @param startUnit The unit at which the ISO date string should start.
 */
export function toIsoFormat(date: Moment, startUnit: Unit): string {
  switch (startUnit) {
    case 'day':
      return date.startOf('day').toISOString(true);
    case 'week':
      return date.startOf('isoWeek').toISOString(true);
    case 'month':
      return date.startOf('month').toISOString(true);
  }
}

export function toIsoFormatLuxon(date: DateTime, startUnit: Unit): string {
  return date.setZone(DEFAULT_TIMEZONE).startOf(startUnit).toISO() || '';
}

export type Format = 'day' | 'week' | 'month'

/**
 * Formats the date in a short style.
 * @param isoDate The date that should be formatted.
 * @param format The way the date should be formatted (e.g. year: Jan '20).
 */
export function formatDateShort(isoDate: string, format: Format): string {
  switch (format) {
    case 'day':
      return moment(isoDate).format('D MMM \'YY');

    case 'week':
      return moment(isoDate).format('[Week] W \'GG');

    case 'month':
      return moment(isoDate).format('MMM \'YY');

    default:
      return isoDate;
  }
}

/**
 * Formats the date in a long style.
 * @param isoDate The date that should be formatted.
 * @param format The way the date should be formatted (e.g. year: January 2020).
 */
export function formatDateLong(isoDate: string, format: Format): string {
  const date = moment(isoDate);
  switch (format) {
    case 'day':
      return date.format('dddd, LL');

    case 'week':
      const week = date.isoWeek();
      const startDay = date.startOf('isoWeek').format('L');
      const endDay = date.endOf('isoWeek').format('L');
      return `Week ${week}, ${startDay} - ${endDay}`;

    case 'month':
      return date.format('MMMM YYYY');

    default:
      return isoDate;
  }
}

export function secondsToHms(seconds: number | undefined): string {
  const hoursNumber = seconds ? Math.floor(seconds / 3600) : 0;
  const minutesNumber = seconds ? Math.floor(seconds % 3600 / 60) : 0;
  const secondsNumber = seconds ? Math.floor(seconds % 3600 % 60) : 0;
  const hoursString = hoursNumber > 0 ? hoursNumber + 'h' : '';
  const minutesString = minutesNumber > 0 ? minutesNumber + 'm' : '';
  const secondsString = secondsNumber > 0 ? secondsNumber + 's' : '';
  const hoursMinutesSpaceSeperator = hoursString !== '' && minutesString !== '' ? ' ' : '';
  const hoursMinutesString = hoursString + hoursMinutesSpaceSeperator + minutesString;
  const minutesSecondsSpaceSeperator = minutesString !== '' && secondsString !== '' ? ' ' : '';
  const minutesSecondsString = minutesString + minutesSecondsSpaceSeperator + secondsString;

  if (!seconds || seconds === 0)
    return '0m 0s';

  if (hoursNumber > 0)
    return hoursMinutesString;

  return minutesSecondsString;
}

export function toLocaleDate(date: Date | string | number) {
  const options = { year: 'numeric', month: '2-digit', day: 'numeric', timeZone: DEFAULT_TIMEZONE } as Intl.DateTimeFormatOptions;
  return new Date(date).toLocaleString(moment.locale(), options);
}

export function toLocaleDateTime(date: Date | string | number) {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: DEFAULT_TIMEZONE,
  } as Intl.DateTimeFormatOptions;
  return new Date(date).toLocaleString(moment.locale(), options);
}

/**
 * Checks if two given ISO date strings are describing the same point in time. The timezone of comparison is the one used by 'isoStringStart'.
 * @param isoStringStart The start ISO string in timezone of comparison.
 * @param isoStringCompare The compared ISO string.
 */
export function compareIsoStrings(isoStringStart: string, isoStringCompare: string): boolean {
  return new Date(isoStringStart).getTime() === new Date(isoStringCompare).getTime();
}
