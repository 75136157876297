import { Workbook } from 'exceljs';
import { Sender } from '../../schema';
import { SenderTypeFrontend } from '../../types';

export function addSendersOverviewReport(type: SenderTypeFrontend, workbook: Workbook, senderOverviewData: Sender[]) {
  const worksheet = workbook.addWorksheet(`${type.includes('page') ? 'Pages' : 'Communities'} Overview`);
  worksheet.columns = [
    { header: 'Name', width: 65 },
    { header: 'Visits', width: 15 },
    { header: 'Visitors', width: 15 },
    { header: 'Engagement rate', width: 15, style: { numFmt: '0.00%' } },
  ];

  senderOverviewData.forEach((sender) => {
    worksheet.addRow([sender.name, sender.visits, sender.uniqueVisits, (sender.avgEngagementRate ?? 0) / 100]);
  });

  worksheet.autoFilter = 'A1:D1';
}
