import {Workbook} from 'exceljs';
import {ContentInsightsMetadata} from '../../schema';
import {toLocaleDate} from '../../charts/DateUtils';

export function addContentInsightsMetadataReport(workbook: Workbook, contentInsightsMetadata: ContentInsightsMetadata) {
  const worksheet = workbook.addWorksheet(`Content Insights Info`);
  const publicationTime = {
    header: contentInsightsMetadata.publicationTime !== null ? [{header: 'Published date', width: 25}] : [],
    value: contentInsightsMetadata.publicationTime !== null ? [toLocaleDate(contentInsightsMetadata.publicationTime)] : [],
  };
  worksheet.columns = [
    {header: 'Title', width: 65},
    {header: 'Reactions', width: 15},
    {header: 'Comments', width: 15},
    {header: 'Shares', width: 15},
    {header: 'Reach', width: 15},
    {header: 'Average Engagement Rate', width: 15},
    {header: 'Created date', width: 25},
    {header: 'Last updated date', width: 25},
    ...publicationTime.header,
  ];
  worksheet.addRow([
    contentInsightsMetadata.title,
    contentInsightsMetadata.likes,
    contentInsightsMetadata.comments,
    contentInsightsMetadata.shares,
    contentInsightsMetadata.reach,
    contentInsightsMetadata.engagementRate,
    toLocaleDate(contentInsightsMetadata.creationTime),
    toLocaleDate(contentInsightsMetadata.updatedTime),
    ...publicationTime.value,
  ]);

  worksheet.autoFilter = `A1:${publicationTime.header.length > 0 ? 'I' : 'H'}1`;
}
