import React, {ReactComponentElement, ReactElement} from 'react';
import {Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ReactComponent as MoreHorizontalIcon} from '../../svg/more-horizontal.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemContainer: {
      alignItems: 'flex-start',
      paddingTop: '8px',
      paddingBottom: '8px',

      '&:focus': {
        background: theme.palette.action.hover,
      },
    },

    svgIcon: {
      width: 20,
      height: 20,
    },

    menuItemIcon: {
      minWidth: 'unset',
      marginRight: '8px'
    },

    textContainer: {
      display: 'flex',
      flexDirection: 'column'
    },

    title: {
      lineHeight: '24px',
    },

    subtitle: {
      lineHeight: '16px',
      color: theme.palette.text.secondary
    },

    divider: {
      marginTop: '8px',
      marginBottom: '8px'
    }
  }));

export interface Props {
  items: BasicMenuItem[];
  parentTitle: string;
}

export interface BasicMenuItem {
  title: string,
  subtitle?: string,
  onClick: () => void,
  icon: ReactComponentElement<any>
  divider?: boolean
}

export default function CoyoBasicMenu({items, parentTitle}: Props): ReactElement {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function onMenuItemClick(item: BasicMenuItem) {
    item.onClick();
    closeMenu();
  }

  function renderMenuItem(item: BasicMenuItem, index: number) {
    return (
      <MenuItem
        className={classes.menuItemContainer}
        onClick={() => onMenuItemClick(item)}
        data-testid={`menu-item-${index}`}
        tabIndex={0}
        autoFocus={index === 0}
        aria-label={item.title}>
        <ListItemIcon className={classes.menuItemIcon}
                      data-testid={`menu-item-${index}-icon`}>{item.icon}</ListItemIcon>

        <div className={classes.textContainer}>
          <Typography variant={'body2'} className={classes.title} data-testid={`menu-item-${index}-title`}>
            {item.title}
          </Typography>
          {!!item.subtitle &&
              <Typography variant={'body1'} className={classes.subtitle} data-testid={`menu-item-${index}-subtitle`}>
                {item.subtitle}
              </Typography>
          }
        </div>
      </MenuItem>);
  }

  function renderMenuItems(items: BasicMenuItem[]) {
    return React.Children.toArray(items.map((item, index) => {
      return (
        <Box>
          {(!!item.divider && index) > 0 && <Divider className={classes.divider}/>}
          {renderMenuItem(item, index)}
        </Box>
      );
    }));
  }

  return (
    <div>
      {openButton()}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        data-testid={'basic-menu'}>
        {renderMenuItems(items)}
      </Menu>
    </div>
  );

  function openButton() {
    return <IconButton key={'icon-button-menu'} size={'small'}
                       onClick={openMenu} data-testid={'open-basic-menu-button'}
                       aria-label={`Open menu of ${parentTitle}`}>
      <MoreHorizontalIcon className={`${classes.svgIcon}`}/>
    </IconButton>;
  }
}

