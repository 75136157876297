import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ListSubheader } from '@material-ui/core';
import { ReactComponent as CommunitiesIcon } from '../svg/communities.svg';
import { ReactComponent as DashboardIcon } from '../svg/chart.svg';
import { ReactComponent as HelpIcon } from '../svg/help-circle.svg';
import { ReactComponent as PagesIcon } from '../svg/pages.svg';
import { ReactComponent as SignOutIcon } from '../svg/sign-out.svg';
import { ReactComponent as SunIcon } from '../svg/sun.svg';
import { ReactComponent as MoonIcon } from '../svg/moon.svg';
import { ReactComponent as HomeIcon } from '../svg/home.svg';
import { useAppContext } from '../CoyoAnalyticsTheme';
import { useAuthContext } from '../auth/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useSideNavContext } from './SideNavContext';
import CoyoLogoHeader from '../coyo-components/logo-header/CoyoLogoHeader';
import { useLayoutContext } from '../layout/LayoutContext';
import { useFeatureToggleContext } from '../feature-toggle/FeatureToggleContext';
import { useSettingsContext } from '../settings/SettingsContext';
import { FeatureToggle } from '../feature-toggle/feature';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface Props {
  width: number,
}

const useStyles = (width: number) => makeStyles((theme: Theme) => {
  return createStyles({
    drawer: {
      width,
      flexShrink: 0,
    },
    paper: {
      padding: theme.spacing(0, 2),
      width,
    },
    logo: {
      padding: '50px 0px',
    },
    nav: {
      height: '100%',
    },
    listMenu: {
      padding: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    separator: {
      flex: 1,
    },
    divider: {
      width: '90%',
    },
    listItemMenu: {
      color: theme.palette.text.secondary,
      fontWeight: 400,
      padding: '16px 32px',
      width: 'auto',
      borderRadius: 4,
      transition: 'background .1s, color .1s',

      '& svg': {
        fill: theme.palette.text.secondary,
        transition: 'fill .1s',
        height: 20,
        width: 20,
      },

      '&:hover': {
        color: theme.palette.type === 'dark' ? theme.palette.text.primary : undefined,

        '& svg': {
          fill: theme.palette.type === 'dark' ? theme.palette.text.primary : undefined,
        },
      },

      '&.Mui-focusVisible': {
        outlineStyle: 'none',
        backgroundColor: 'inherit',
        boxShadow: `${theme.palette.action.focus} 0px 0px 0px 2px inset`,
      },

      '&.Mui-selected': {
        color: theme.palette.primary.main,

        '& svg, &:hover svg': {
          fill: theme.palette.primary.main,
        },
      },
    },
    listItemIcon: {
      minWidth: 20,
      marginRight: 12,
    },
    listItemText: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 1,
    },
    subheader: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'uppercase',
      lineHeight: 1,
    },
    signOutListItem: {
      marginTop: 24,
      marginBottom: 16,
    },
    '@media print': {
      drawer: {
        display: 'none',
      },
      paper: {
        display: 'none',
      },
    },
  });
},
)();

const NavItem = ({ type, name, Icon, classes }: { type: string, name: string, Icon: React.FunctionComponent, classes: ClassNameMap }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { activeSideNavOption } = useSideNavContext();

  return (
    <ListItem button key={type} className={classes.listItemMenu}
      selected={activeSideNavOption === type}
      onClick={() => {
        history.push(`/${type}${search}`);
      }}
      component={'li'}
      role={'link'}
      aria-current={activeSideNavOption === type}
      data-testid={`${type}-list-item`}>
      <ListItemIcon className={classes.listItemIcon}><Icon /></ListItemIcon>
      <Typography variant="body1" className={classes.listItemText}>{name}</Typography>
    </ListItem>
  )
};

export default function SideNavigation(props: Props) {
  const { toggleDarkMode, darkMode } = useAppContext();
  const ENV_NAME = process.env.REACT_APP_ENV_NAME ?? 'COYO Dev';
  const NEED_HELP_LINK = process.env.REACT_NEED_HELP_LINK ?? 'https://knowledgebase.coyoapp.com/hc/en-us/sections/360005912340-Analytics';
  const { showMainLinks, showConsolidatedDashboardLinks } = useSettingsContext();

  const classes = useStyles(props.width);

  const authorization = useAuthContext();
  const { isDisabledFeature } = useFeatureToggleContext();
  const history = useHistory();
  const { isMinimalisticView } = useLayoutContext();

  if (isMinimalisticView || (!showMainLinks && !showConsolidatedDashboardLinks)) {
    return null;
  }

  function signOut(): void {
    if ('logout' in authorization) {
      authorization
        .logout?.()
        .then(() => history.push('/login'))
        .catch(() => history.push('/login'));
    }
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{ paper: classes.paper }}>
      <CoyoLogoHeader iconSize={40} envName={ENV_NAME} className={classes.logo} data-testid={'coyo-logo-header'} />
      <nav aria-label={'main navigation'} className={classes.nav}>
        <List className={classes.listMenu}>
          {!!showMainLinks && (
            <>
              <NavItem type="dashboard" name="Dashboard" Icon={DashboardIcon} classes={classes} />
              <NavItem type="pages" name="Pages" Icon={PagesIcon} classes={classes} />
              <NavItem type="communities" name="Communities" Icon={CommunitiesIcon} classes={classes} />

              {!isDisabledFeature(FeatureToggle.LandingPages) && <NavItem type="homepages" name="Homepages" Icon={HomeIcon} classes={classes} />}
            </>
          )}

          {!!showMainLinks && !!showConsolidatedDashboardLinks && (
            <>
              <ListItem><hr className={classes.divider} /></ListItem>
            </>
          )}

          {!!showConsolidatedDashboardLinks && (
            <>
              <NavItem type="my-pages" name="My Pages" Icon={PagesIcon} classes={classes} />
              <NavItem type="my-communities" name="My Communities" Icon={CommunitiesIcon} classes={classes} />
            </>
          )}

          <ListItem className={classes.separator}>&nbsp;</ListItem>
          <ListSubheader className={`${classes.subheader} ${classes.listItemMenu}`}><span>Extra</span></ListSubheader>

          <ListItem button key="help" className={classes.listItemMenu}
            component={'a'}
            role={'link'}
            href={NEED_HELP_LINK}
            target="_blank">
            <ListItemIcon className={classes.listItemIcon}><HelpIcon /></ListItemIcon>
            <Typography variant="body1" className={classes.listItemText}>Need help?</Typography>
          </ListItem>
          <ListItem button key="dark-mode" className={classes.listItemMenu}
            onClick={toggleDarkMode} component={'li'} role={'switch'} aria-checked={darkMode}>
            <ListItemIcon className={classes.listItemIcon}>{darkMode ? <SunIcon /> : <MoonIcon />}</ListItemIcon>
            <Typography variant="body1" className={classes.listItemText}>
              {darkMode ? 'Switch to light theme' : 'Switch to dark theme'}
            </Typography>
          </ListItem>

          {('logout' in authorization) &&
            <ListItem button key="sign-out" className={`${classes.listItemMenu} ${classes.signOutListItem}`}
              onClick={signOut} component={'li'} data-testid={'sign-out-item'}>
              <ListItemIcon className={classes.listItemIcon}><SignOutIcon /></ListItemIcon>
              <Typography variant="body1" className={classes.listItemText}>Sign out</Typography>
            </ListItem>
          }
        </List>
      </nav>
    </Drawer>
  );
}
