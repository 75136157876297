import {useApiQuery} from '../apollo/ApiQuery';
import {gql} from '@apollo/client';

export const useContentInsightsMetadataQuery = (entityId: string, senderId?: string) => useApiQuery(gql`
    query contentInsightsMetadata($entityId: String!, $senderId: String){
        contentInsights {
            metadata(entityId: $entityId, senderId: $senderId) {
                id
                title
                type
                likes
                comments
                shares
                reach
                engagementRate
                creationTime
                publicationTime
                updatedTime
                link
            }
        }
    }`, {entityId, senderId});

