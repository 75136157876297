import React, {useState} from 'react';
import LineChart, {Group, Series} from '../../chart-types/line-chart/LineChart';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {Box, MenuItem} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';
import {HelpText} from '../../coyo-components/help-box/HelpText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&:focus': {
        background: theme.palette.action.hover,
      },
    },
    chart: {
      padding: '8px',
    },
  }));

interface Props {
  id: string
  title: string,
  data: Series[]
  loading: boolean
  reload?: () => void
  helpText?: HelpText
  threshold: boolean
}

export default function ContentInsightsGraphTemplate({id, title, data, loading, reload, helpText, threshold}: Props) {
  const classes = useStyles();
  const [group, setGroup] = useState<Group>('day');
  const {startDate, endDate} = useDateRangeContext();
  const {appliedGlobalFilters} = useGlobalFiltersContext();

  return (
    <CoyoCard id={id}
              title={title}
              loading={loading}
              filtered={appliedGlobalFilters.length > 0}
              filterTimeRange={generateNumericDates(startDate, endDate)}
              reload={reload}
              helpText={helpText}
              controls={(
                <CoyoSelect SelectProps={{
                  value: group,
                  onChange: ({target}) => setGroup(target.value as Group),
                }}
                            label={`Select ${title} in time range`}>
                  <MenuItem className={classes.menuItem} value={'day'}
                            disabled={group === 'day'}><span>Day</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'week'}
                            disabled={group === 'week'}><span>Week</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'month'}
                            disabled={group === 'month'}><span>Month</span></MenuItem>
                </CoyoSelect>
              )}>
      <Box className={classes.chart}>
        <LineChart series={data} group={group} loading={loading}
                   threshold={!!threshold}/>
      </Box>
    </CoyoCard>
  );
}
