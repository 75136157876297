import React, {useEffect, useState} from 'react';
import {Button, Link} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {CoyoPasswordInput} from '../../coyo-controls/password-input/CoyoPasswordInput';
import {Link as RouterLink} from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center'
    },
    item: {
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      margin: theme.spacing(4),
      fontSize: 24,
      fontWeight: 600,
    },
    textField: {
      margin: theme.spacing(1, 0),
      '& .MuiInputBase-root': {
        height: 40,
        width: '100%',
        alignSelf: 'stretch',
        flexGrow: 0,
        padding: '10px 12px',
        borderRadius: 4,
        border: 'solid 1px #d7dbe0',
      },
      '& .MuiInputLabel-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 0.71,
        transform: 'none',
      },
    },
    button: {
      width: '100%',
      height: theme.spacing(5),
      margin: theme.spacing(5, 0, 1, 0),
      padding: '10px 16px',
      boxShadow: 'none',
      fontSize: 15,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    link: {
      alignSelf: 'flex-start',
      margin: theme.spacing(1)
    },
    infoText: {
      fontSize: 13,
      textAlign: 'center',
      marginTop: theme.spacing(2)
    },
    validationMessage: {
      color: '#FF5630',
      margin: theme.spacing(1, 0, 0, 0),
      textAlign: 'center'
    },
  }),
);

interface Props {
  submit: () => void
  newPassword: string
  setNewPassword: (newPassword: string) => void
  error: string | null
  goBack?: () => void
}

export default function ChangePasswordForm({submit, newPassword, setNewPassword, error, goBack}: Props) {
  const classes = useStyles();
  const [validationMessage, setValidationMessage] = useState(error);
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setValidationMessage(error);
  }, [error]);

  function submitForm(event: any) {
    event.preventDefault();
    if (newPassword === '') {
      setValidationMessage('Please enter a new password');
      return;
    }
    if (confirmPassword === '') {
      setValidationMessage('Please confirm the password');
      return;
    }
    if (newPassword !== confirmPassword) {
      setValidationMessage('Passwords do not match');
      return;
    } else
      submit();
  }

  return (
    <form onSubmit={submitForm} noValidate autoComplete="off" data-testid={'change-password-form'}
          className={classes.form}>

      <Typography variant="h5" className={`${classes.title} ${classes.item}`}>Change your password</Typography>

      {goBack &&
      <Link component={RouterLink}
            className={`${classes.link} ${classes.item}`}
            to={''}
            onClick={(event: any) => {
              event.preventDefault();
              goBack();
            }}
            variant={'body2'}
            data-testid={`go-back-link`}>← Back</Link>}

      <CoyoPasswordInput
        id={'new-password'}
        name={'new-password'}
        data-testid={'new-password'}
        className={`${classes.textField} ${classes.item}`}
        labelText={'New password'}
        value={newPassword || ''}
        onChange={change => setNewPassword(change.target.value)}/>

      <CoyoPasswordInput
        id={'confirm-password'}
        data-testid={'confirm-password'}
        name={'confirm-password'}
        className={`${classes.textField} ${classes.item}`}
        labelText={'Confirm password'}
        value={confirmPassword || ''}
        onChange={change => setConfirmPassword(change.target.value)}/>

      <Typography className={`${classes.infoText} ${classes.item}`}>
        The password must have at least 12 characters, one number, one symbol, one uppercase letter and one lowercase
        letter.
      </Typography>

      <Button type="submit" className={`${classes.button} ${classes.item}`} variant="contained"
              color="primary">Change password</Button>

      {validationMessage &&
      <Typography className={`${classes.validationMessage} ${classes.item}`}
                  data-testid={'validation-message'}>{validationMessage}</Typography>}

    </form>
  );
}
