import React, {useEffect, useState} from 'react';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import Bars, {Bar} from '../../chart-types/bars/Bars';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {Box, Button, MenuItem} from '@material-ui/core';
import {SearchAnalytics, SearchTerm} from '../../schema';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {useSearchAnalyticsQuery} from './SearchAnalyticsQuery';
import {useGlobalFiltersContext} from '../../global-filters/GlobalFiltersContext';
import {generateNumericDates} from '../DateUtils';
import {useReportDataContext} from '../../report-data/ReportDataContext';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    '&:focus': {
      background: theme.palette.action.hover,
    },
  },
  bottomControls: {
    display: 'flex',
  },
  showMoreButton: {
    flex: 1,
    margin: theme.spacing(1, 3),
  },
  searchAnalyticsCard: {
    height: '100%',
    display: 'grid',
  },
  barStyling: {
    position: 'relative',
    padding: theme.spacing(2, 3),
  },
  '@media print': {
    bottomControls: {
      display: 'none',
    },
  },
}));

export const PAGE_ROW_AMOUNT = 4;

const helpText = {
  what: 'The evaluation of the COYO search shows the most frequently searched search words and phrases.',
  how: 'All searches made in COYO during the specified period are summed up and displayed in descending order.',
};

interface Props {
  className?: string;
}

export type SearchType = 'terms' | 'phrases';
export default function SearchAnalyticsCard({className}: Props) {
  const classes = useStyles();
  const [searchType, setSearchType] = useState<SearchType>('terms');
  const [bars, setBars] = useState<Bar[]>([]);
  const [visibleRowsAmount, setVisibleRowsAmount] = useState<number>(PAGE_ROW_AMOUNT);
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {loading, data, refetch} = useSearchAnalyticsQuery(fromDay, toDay, 1000);
  const searchAnalytics: SearchAnalytics | undefined = data?.searchAnalytics;
  const {reportData} = useReportDataContext();

  reportData.searchAnalytics = searchAnalytics;
  reportData.searchAnalyticsLoading = loading;

  useEffect(() => {
    if (!!searchAnalytics) {
      setBars(searchAnalyticsToBars(visibleRowsAmount, searchAnalytics[searchType]));
    }
  }, [searchType, searchAnalytics, visibleRowsAmount]);

  return (
    <Box className={`${classes.searchAnalyticsCard} ${className}`}>
      <CoyoCard id={'search-analytics-card'}
                title={'Top searches'}
                loading={loading}
                helpText={helpText}
                filtered={appliedGlobalFilters.length > 0}
                filterTimeRange={generateNumericDates(startDate, endDate)}
                controls={(
                  <CoyoSelect data-testid={'search-analytics-selection'}
                              SelectProps={{
                                value: searchType,
                                onChange: ({target}) => {
                                  setSearchType(target.value as SearchType);
                                  setVisibleRowsAmount(PAGE_ROW_AMOUNT);
                                },
                              }}
                              label={'Filter top searches'}>
                    <MenuItem className={classes.menuItem} value={'terms'} disabled={searchType === 'terms'}><span>Search terms</span></MenuItem>
                    <MenuItem className={classes.menuItem} value={'phrases'} disabled={searchType === 'phrases'}><span>Search phrases</span></MenuItem>
                  </CoyoSelect>
                )}
                reload={refetch}>
        <Bars bars={bars} loading={loading} skeletonBarAmount={PAGE_ROW_AMOUNT} classNames={classes.barStyling}/>
        {visibleRowsAmount < (searchAnalytics ? searchAnalytics[searchType].length : 0) && renderShowMoreButton()}
      </CoyoCard>
    </Box>
  );

  function renderShowMoreButton() {
    return (
      <Box className={classes.bottomControls}>
        <Button className={classes.showMoreButton}
                onClick={() => setVisibleRowsAmount(visibleRowsAmount + PAGE_ROW_AMOUNT)}
                data-testid={'show-more-button'}>
          Show more
        </Button>
      </Box>
    );
  }
}

export function searchAnalyticsToBars(visibleRowsAmount: number, searchAnalytics: SearchTerm[]): Bar[] {
  const sum = searchAnalytics.reduce((sum, entry) => sum + entry.total, 0);
  return searchAnalytics.map((entry: SearchTerm) => {
    const bar: Bar = {
      title: entry.term,
      value: entry.total,
      maxValue: sum,
      dataType: 'amount'
    };
    return bar;
  }).sort((barOne: Bar, barTwo: Bar) => barsByPercentDesc(barOne, barTwo))
    .splice(0, visibleRowsAmount);
}

function barsByPercentDesc(barOne: Bar, barTwo: Bar) {
  if (barOne.value < barTwo.value) return 1;
  if (barOne.value > barTwo.value) return -1;
  return 0;
}
