import {gql} from '@apollo/client';
import {useApiQuery} from '../../apollo/ApiQuery';

export const usePageHighlightsQuery = (senderId: string, from: string, to: string, includeUserGeneratedContent: boolean) => {
  return useApiQuery(gql`
      query senderHighlights($senderId: String!, $from: String!, $to: String!, $includeUserGeneratedContent: Boolean) {
          senderHighlights(senderId: $senderId, from: $from, to: $to, includeUserGeneratedContent: $includeUserGeneratedContent){
              likes {
                  total
                  trendPercent
              }
              comments {
                  total
                  trendPercent
              }
              shares{
                  total
                  trendPercent
              }
          }
      }
  `, {senderId, from, to, includeUserGeneratedContent});
};
