import { useEffect, useMemo, useState } from 'react';
import { AuthContext, Authorization } from './AuthContext';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useHistory } from 'react-router-dom';
import { CognitoClient } from './CognitoClient';
import { getParameterByName } from '../coyo-controls/WebUtils';
import { Auth } from '@aws-amplify/auth';
import { useSessionStorage } from '../utils/useStorage';


interface Props {
  children: any,
}

export function AuthProvider(props: Props) {
  const history = useHistory();

  // hack because useParams() is not working properly in this component
  const apiKey = getParameterByName(history.location.search, 'apiKey');
  const dataAccessKey = getParameterByName(history.location.search, 'dataAccessKey');

  const [auth, setAuth] = useSessionStorage<undefined | { apiKey: string, dataAccessKey: string }>('auth', undefined);

  const [senderId, setSenderId] = useState<string | undefined>();

  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [cognitoClient] = useState(new CognitoClient());

  useEffect(() => {
    if (apiKey && dataAccessKey) {
      setAuth({ apiKey, dataAccessKey });
      history.replace(history.location.pathname);
    }
  }, [apiKey, dataAccessKey]); // eslint-disable-line react-hooks/exhaustive-deps


  const authorization: Authorization = useMemo(() => {
    // api-key
    if (auth) {
      return {
        type: 'api-key',
        apiKey: auth.apiKey,
        dataAccessKey: auth.dataAccessKey,
        senderId,
        setSenderId,
        isAuthorized: true,
      };
    }

    // cognito
    return {
      type: 'cognito',
      isAuthorized: !!session?.isValid(),

      refreshSessionIfNeeded: async () => {
        if (session?.isValid()) return;
        const newSession = await cognitoClient.getOrRefreshSession();
        setSession(newSession);
      },

      login: async (username: string, password: string) => {
        const loginResult = await cognitoClient.login(username, password);
        if (loginResult.result === 'SUCCESS') {
          setSession(loginResult.session);
        }

        return loginResult;
      },

      enterMfa: async (user: CognitoUser, code: string) => {
        try {
          const data = await Auth.sendCustomChallengeAnswer(
            user,
            code,
          );
          if (!!data.signInUserSession) {
            setSession(data.signInUserSession);
            return { result: 'SUCCESS', session: data.signInUserSession };
          }
        } catch (err) {
          throw (err);
        }
        throw (new Error('The provided code is invalid.'));
      },

      logout: () => cognitoClient.logout().finally(() => setSession(null)),

      changePassword: (cognitoUser: CognitoUser, newPassword: string) => cognitoClient.changePassword(cognitoUser, newPassword),

      getJwtToken: () => session?.getIdToken().getJwtToken() || null,
    };
  }, [auth, cognitoClient, session, senderId, setSenderId]);

  return (
    <AuthContext.Provider value={authorization}>
      {props.children}
    </AuthContext.Provider>
  );
}
