import {useApiQuery} from '../apollo/ApiQuery';
import {gql} from '@apollo/client';
import {useGlobalFiltersContext} from '../global-filters/GlobalFiltersContext';
import {useSettingsContext} from '../settings/SettingsContext';

export const useContentInsightsHistogramQuery = (entityId: string, from: string, to: string, senderId?: string) => {
  const {appliedGlobalFilters} = useGlobalFiltersContext();
  const {timezone} = useSettingsContext();
    return useApiQuery(gql`
        query contentInsightsHistogram($entityId: String!, $senderId: String, $from: String!, $to: String!, $timezone: String!, $globalFilters: [GlobalFilter!]){
            contentInsights {
                histogram(entityId: $entityId, senderId: $senderId, from: $from, to: $to, timezone: $timezone, globalFilters: $globalFilters) {
                    likes {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    comments {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    views {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    uniqueViews {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    newUniqueViews {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    viewsShare {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    uniqueViewsShare {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    newUniqueViewsShare {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    viewsTotal {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    uniqueViewsTotal {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }

                    newUniqueViewsTotal {
                        day {
                            isoDate
                            value
                        }
                        month {
                            value
                            isoDate
                        }
                        week {
                            isoDate
                            value
                        }
                    }
                }
            }
        }`, {entityId, senderId, from, to, timezone, globalFilters: appliedGlobalFilters});
};

