import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import ArrowDataLabel from './ArrowDataLabel';
import PlaceholderText from '../../../placeholder/PlaceholderText';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FallbackTable } from '../../fallback-table/FallbackTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    barLabel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      bottom: '-8px',
    },
    chartWrapper: {
      position: 'relative',
    },
    fallbackTable: {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      whiteSpace: 'nowrap',
      border: 0,
    },
  }));

interface Props {
  series: Series;
  loading: boolean;
  categories: string[];
  suffixes?: string[];
  height?: number | string;
  width?: number | string;
  helpTexts?: string[];
}

interface Series {
  name: string;
  data: number[];
}

export default function ColumnWithDataLabels({ series, loading, categories, suffixes, height, width, helpTexts }: Props) {
  const classes = useStyles();
  const colors = ['#99c7f5', '#569fee', '#0073e6'];
  const theme = useTheme();

  const fallbackContent = <FallbackTable headerRowCount={1} headerRow={suffixes!} contentRows={[series.data.map(series => (series || 0).toString())]} />

  const data = {
    labels: suffixes,
    datasets: [{
      data: loading ? [] : series.data,
      backgroundColor: colors,
      hoverBackgroundColor: colors,
    }],
  };

  const options: ChartOptions<'bar'> = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    datasets: {
      bar: {
        borderRadius: 3,
        barThickness: 90,
      },
    },
    layout: {
      padding: {
        top: 42,
        left: -20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        text: series.name,
      },
      datalabels: {
        formatter: function (value, context) {
          return value + '\n' + context.chart.data.labels![context.dataIndex];
        },
        textAlign: 'center',
        anchor: 'end',
        align: 'top',
        font: {
          family: 'Source Sans Pro',
          size: 15,
          weight: 600,
        },
        color: theme.palette.type === 'light' ? '#000' : '#fff',
      },
    },
    maintainAspectRatio: false,
    normalized: true,
  };

  function renderArrows(values: (number | string)[], helpTexts: string[]) {
    return values.map((value, index) => {
      const helpText = typeof helpTexts[index] == 'undefined' ? '' : helpTexts[index];
      const key = `arrow-${index}`;
      if (index % 2 === 1) {
        return (
          <ArrowDataLabel key={key} helpText={helpText}>
            <PlaceholderText loading={loading}>{value}</PlaceholderText>
          </ArrowDataLabel>
        );
      }
      return (
        <ArrowDataLabel key={key} outlined={true} secondary={true} helpText={helpText}>
          <PlaceholderText loading={loading}>{value}</PlaceholderText>
        </ArrowDataLabel>);
    });
  }

  return (
    <Box width={width || 'auto'}>
      <Box className={classes.chartWrapper} height={height || '100%'} width={width || '100%'}>
        <Bar data={data} plugins={[ChartDataLabels]} options={options} fallbackContent={fallbackContent}
          data-testid={'bar-chart'} aria-hidden={true} />
        {fallbackContent}
      </Box>
      <Box className={classes.barLabel}>
        {renderArrows(categories, helpTexts || [])}
      </Box>
    </Box>
  );
}
