import React, {useState} from 'react';
import LineChart, {EMPTY_SERIES, Group, Series} from '../../chart-types/line-chart/LineChart';
import {CreatedContent, HighlightValues} from '../../schema';
import {Box, MenuItem} from '@material-ui/core';
import HighlightBox from '../../chart-types/highlight-box/HighlightBox';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import CoyoCard from '../../coyo-components/card/CoyoCard';
import CoyoSelect from '../../coyo-controls/select/CoyoSelect';
import {useDateRangeContext} from '../../date-range-selection/DateRangeContext';
import {useCreatedContentQuery} from './CreatedContentQuery';
import {useReportDataContext} from '../../report-data/ReportDataContext';

import moment from 'moment-timezone';
import {generateNumericDates} from '../DateUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&:focus': {
        background: theme.palette.action.hover,
      },
    },
    highlights: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    chart: {
      padding: theme.spacing(1, 3),
    },
    highlightBoxes: {
      textAlign: 'left',
      padding: '24px',
      margin: '8px',
      borderRadius: 8,
      border: 'solid 1px',
      borderColor: theme.palette.background.default,
      backgroundColor: theme.palette.type === 'light' ? '#FFFFFF' : theme.palette.background.default,
      '&:first-child': {
        marginLeft: 24,
      },

      '&:last-child': {
        marginRight: 24,
      },
    },
  }));

const helpText = {
  what: 'Created content shows what kind of content was created.',
  how: 'Each timeline post, blog article and wiki article, which was created in the configured' +
    ' time is scanned for content. Content can be text, image, video and files. Each attachement is counted seperatly.' +
    ' For example: A timeline post with text and two images is counted once for text and twice for images.',
  important: 'Comments, messages and private pages/communities are not scanned.',
};

export default function CreatedContentCard() {
  const classes = useStyles();
  const [group, setGroup] = useState<Group>('day');
  const {startDate, endDate} = useDateRangeContext();
  const fromDay = startDate.clone().toISOString(true);
  const toDay = endDate.clone().toISOString(true);
  const {loading, data, refetch} = useCreatedContentQuery(fromDay, toDay);
  const {reportData} = useReportDataContext();
  const createdContent: CreatedContent | undefined = data?.createdContent;
  const title = 'Created content';

  reportData.createdContent = createdContent;
  reportData.createdContentLoading = loading;

  const series: Series[] = [{
    name: 'Text',
    dataByRange: createdContent?.histogram?.text || EMPTY_SERIES,
  }, {
    name: 'Images',
    dataByRange: createdContent?.histogram?.images || EMPTY_SERIES,
  }, {
    name: 'Videos',
    dataByRange: createdContent?.histogram?.videos || EMPTY_SERIES,
  }, {
    name: 'Files',
    dataByRange: createdContent?.histogram?.files || EMPTY_SERIES,
  }];

  const timelineItems = data?.createdContent?.highlights?.timelineItems || {} as HighlightValues;
  const blogArticles = data?.createdContent?.highlights?.blogArticles || {} as HighlightValues;
  const wikiArticles = data?.createdContent?.highlights?.wikiArticles || {} as HighlightValues;

  const startDateFormatted = startDate.format(moment.localeData().longDateFormat('LL'));
  const endDateFormatted = endDate.format(moment.localeData().longDateFormat('LL'));

  const timelineItemsHelpText = `${timelineItems?.total} timeline items were created 
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const blogArticleHelpText = `${blogArticles?.total} blog articles were created 
    between ${startDateFormatted} and ${endDateFormatted}.`;
  const wikiArticleHelpText = `${wikiArticles?.total} wiki articles were created 
    between ${startDateFormatted} and ${endDateFormatted}.`;

  return (
    <CoyoCard id={'created-content-card'}
              title={title}
              loading={loading}
              helpText={helpText}
              controls={(
                <CoyoSelect SelectProps={{
                  value: group,
                  onChange: ({target}) => setGroup(target.value as Group),
                }}
                data-testid={'created-content-selection'}
                label={'Select created content time range'}>
                  <MenuItem className={classes.menuItem} value={'day'} disabled={group === 'day'}><span>Day</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'week'} disabled={group === 'week'}><span>Week</span></MenuItem>
                  <MenuItem className={classes.menuItem} value={'month'} disabled={group === 'month'}><span>Month</span></MenuItem>
                </CoyoSelect>
              )}
              reload={refetch}
              filterTimeRange={generateNumericDates(startDate, endDate)}>
      <Box>
        <Box className={classes.highlights}>
          <HighlightBox loading={loading} title={'Timeline Posts published'}
                        data={timelineItems} className={classes.highlightBoxes}
                        valueType={'number'} helpText={timelineItemsHelpText}/>
          <HighlightBox loading={loading} title={'Blog Articles written'}
                        data={blogArticles} className={classes.highlightBoxes}
                        valueType={'number'} helpText={blogArticleHelpText}/>
          <HighlightBox loading={loading} title={'Wiki Articles written'}
                        data={wikiArticles} className={classes.highlightBoxes}
                        valueType={'number'} helpText={wikiArticleHelpText}/>
        </Box>
        <Box className={classes.chart}>
          <LineChart series={series} group={group} loading={loading}/>
        </Box>
      </Box>
    </CoyoCard>
  );
}
