import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useAuthContext} from '../auth/AuthContext';
import LoginForm from './forms/LoginForm';
import LoginLayout from './LoginLayout';
import SetFirstPasswordForm from './forms/SetFirstPasswordForm';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {useDateRangeContext} from '../date-range-selection/DateRangeContext';
import EnterMfaForm from './forms/EnterMfaForm';

export default function Login() {
  const history = useHistory();
  const {search} = useLocation();
  const authorization = useAuthContext();
  const {startDate, endDate} = useDateRangeContext();
  const [requiredPasswordChangeUser, setRequiredPasswordChangeUser] = useState<CognitoUser | null>(null);
  const [mfaRequiredUser, setMfaRequiredUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    document.title = 'Haiilo Intranet Analytics | Login';
  });

  useEffect(() => {
    if (authorization.type === 'cognito' && authorization.isAuthorized) {
      history.push(`?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`);
    }
  }, [authorization, history, startDate, endDate]);

  function onSuccess(): void {
    if (authorization.type === 'cognito') {
      history.push(`/dashboard${search}`);
    }
  }

  return (
    <LoginLayout>
      {
        !mfaRequiredUser ? (
          !requiredPasswordChangeUser ? (
            <LoginForm onSuccess={() => onSuccess()}
                       onNewPasswordRequired={(cognitoUser => setRequiredPasswordChangeUser(cognitoUser))}
                       onMfaRequired={cognitoUser => setMfaRequiredUser(cognitoUser)}
                       data-testid={'login-form'}/>
          ) : (
            <SetFirstPasswordForm
              cognitoUser={requiredPasswordChangeUser}
              onSuccess={() => onSuccess()}
              data-testid={'change-password-form'}/>
          )
        ) : (
          <EnterMfaForm user={mfaRequiredUser}/>
        )
      }

    </LoginLayout>
  );
}
