import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useAuthContext} from './AuthContext';

export default function AuthRoutes(props: { children: any }) {
  const history = useHistory();
  const authorization = useAuthContext();

  useEffect(() => {
    if (authorization.type === 'cognito') {
      authorization
        .refreshSessionIfNeeded()
        .catch(() => history.push('/login'));
    }
  }, [authorization, history]);

  return authorization.isAuthorized ? props.children : null;
}
