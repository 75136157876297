import { useCallback } from "react";
import { SortOrder } from "../coyo-components/table-header-cell/CoyoTableHeaderCell";

export type NamedEntity = {
  name: string;
};

export const sortList = <T extends { name: string }>(list: T[], sortColumn: keyof T, sortOrder: string) => {
  if (!list.length) {
    return list;
  }

  const dir = sortOrder === 'asc' ? 1 : -1;
  if (sortColumn === 'name') {
    return list.sort((a, b) => a.name.localeCompare(b.name) * dir);
  }

  const [{ [sortColumn]: value }] = list
  if (value === null || value === undefined) {
    return list;
  }

  return list.sort((a, b) => {
    const aSort = a[sortColumn] ?? 0;
    const bSort = b[sortColumn] ?? 0;
    return aSort > bSort ? dir : aSort < bSort ? -dir : 0;
  });
}

export const filterByName = <T extends { name: string }>(list: T[], nameFilter?: string | null) => {
  if (!nameFilter) {
    return list;
  }

  return list.filter(({ name }) => name?.toLowerCase().includes(nameFilter.toLowerCase()));
}

export const useList = <T extends NamedEntity>(sortColumn: keyof T, sortOrder: SortOrder, nameFilter?: string | null) => {

  const sort = useCallback((list: T[]) => sortList(list, sortColumn, sortOrder), [sortColumn, sortOrder]);
  const filter = useCallback((list: T[]) => filterByName(list, nameFilter), [nameFilter]);

  return { sort, filter };
}