import {Workbook} from 'exceljs';
import {SearchAnalytics, SearchTerm} from '../../schema';
import {capitalizeFirstLetter} from './ReportUtils';

export function addSearchAnalyticsReport(workbook: Workbook, searchAnalyticsData: SearchAnalytics) {
  addSearchAnalyticsSheet(workbook, searchAnalyticsData.phrases, 'phrases');
  addSearchAnalyticsSheet(workbook, searchAnalyticsData.terms, 'terms');
}

function addSearchAnalyticsSheet(workbook: Workbook, searchAnalyticsDataEntry: SearchTerm[], type: 'phrases' | 'terms') {
  const typeFormatted = capitalizeFirstLetter(type);
  const worksheet = workbook.addWorksheet(`Top searches (${typeFormatted})`);
  worksheet.columns = [
    {header: typeFormatted, width: 20},
    {header: 'Value', width: 10},
  ];

  searchAnalyticsDataEntry.forEach((entry: SearchTerm) => {
    worksheet.addRow([entry.term, entry.total]);
  });

  worksheet.autoFilter = 'A1:B1';
}
