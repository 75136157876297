import {Workbook} from 'exceljs';
import {AudienceType} from '../../charts/audience/AudienceCard';
import {Audience, AudienceEntry} from '../../schema';
import {capitalizeFirstLetter} from './ReportUtils';

export function addAudienceReport(workbook: Workbook, audienceData: Audience) {
  const audienceType = Object.getOwnPropertyNames(audienceData) as AudienceType[];
  audienceType.forEach(type => {
    const audienceEntry = audienceData[type];
    if (audienceEntry) addAudienceSheet(workbook, audienceEntry, type);
  });
}

function addAudienceSheet(workbook: Workbook, audienceEntry: AudienceEntry[], type: AudienceType) {
  const typeFormatted = capitalizeFirstLetter(type);
  const worksheet = workbook.addWorksheet(`Audience (${typeFormatted})`);
  worksheet.columns = [
    {header: typeFormatted, width: 20},
    {header: 'Value', width: 10, style: {numFmt: '0.0%'}},
  ];
  const audienceTotal = audienceEntry.reduce((sum: number, entry: AudienceEntry) => sum + entry.total, 0);

  audienceEntry.forEach((entry: AudienceEntry) => {
    worksheet.addRow([entry.name, entry.total / audienceTotal]);
  });

  worksheet.autoFilter = 'A1:B1';
}
