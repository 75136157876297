import React, {useEffect} from 'react';
import {useLocalStorage} from '../utils/useStorage';
import {v4 as uuid} from 'uuid';
import {useSettingsContext} from '../settings/SettingsContext';
import {useAuthContext} from '../auth/AuthContext';

export default function Retently() {
  const [retentlyId, setRetentlyId] = useLocalStorage<string>('retentlyId', undefined as unknown as string);
  const {tenantId, initializing} = useSettingsContext();
  const {isAuthorized} = useAuthContext();

  const retentlyScriptId = 'retently-jssdk';
  const retentlyInappModalId = 'retently-inapp-modal';
  const retentlySurveyEmbedId = 'retently-survey-embed';

  useEffect(() => {
    if (!initializing && isAuthorized) {
      if (!retentlyId) {
        const retentlyUUID = uuid();
        setRetentlyId(retentlyUUID);
        createRetentlyBox(retentlySurveyEmbedId, tenantId, retentlyUUID);
      } else {
        createRetentlyBox(retentlySurveyEmbedId, tenantId, retentlyId);
      }

      attachRetentlyScript(retentlyScriptId);
    } else {
      document.getElementById(retentlyScriptId)?.remove();
      document.getElementById(retentlyInappModalId)?.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, initializing]);

  return (<React.Fragment/>);
}

function createRetentlyBox(retentlySurveyEmbedId: string, tenantId: string, retentlyId: string): void {
  const retBox = document.createElement('div');
  retBox.id = retentlySurveyEmbedId;
  retBox.setAttribute('data-href', 'https://app.retently.com/api/remote/tracking/5b0cfb5ed0e043843c735205');
  retBox.setAttribute('data-rel', 'dialog');
  retBox.setAttribute('data-campaign', 'regular');
  retBox.setAttribute('data-email', `${retentlyId}@${tenantId}.com`);
  retBox.setAttribute('data-tags', 'in-app, haiilo-analytics');
  document.getElementById('root')?.appendChild(retBox);
}

function attachRetentlyScript(retentlyScriptId: string): void {
  const firstScriptElement = document.getElementsByTagName('script')[0];
  const script = document.createElement('script');
  script.id = retentlyScriptId;
  script.setAttribute('src', 'https://cdn.retently.com/public/components/embed/sdk.min.js');
  script.async = false;
  firstScriptElement?.parentNode?.insertBefore(script, firstScriptElement);
  document.body.appendChild(script);
}
