import React, {useState} from 'react';
import {CognitoUser} from 'amazon-cognito-identity-js';
import Typography from '@material-ui/core/Typography';
import {Button, FormControl} from '@material-ui/core';
import CoyoInputLabel from '../../coyo-controls/input/CoyoInputLabel';
import CoyoInputField from '../../coyo-controls/input/CoyoInputField';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useAuthContext} from '../../auth/AuthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center'
    },
    item: {
      alignSelf: 'center',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      margin: theme.spacing(5),
    },
    textField: {
      margin: theme.spacing(1, 0),
      '& .MuiInputBase-root': {
        height: 40,
        width: '100%',
        alignSelf: 'stretch',
        flexGrow: 0,
        padding: '10px 12px',
        borderRadius: 4,
        border: 'solid 1px #d7dbe0',
      },
      '& .MuiInputLabel-root': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 0.71,
        transform: 'none',
      },
    },
    button: {
      width: '100%',
      height: theme.spacing(5),
      margin: theme.spacing(7, 0),
      padding: '10px 16px',
      boxShadow: 'none',
      fontSize: 15,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
    hint: {
      color: '#FF5630',
      margin: theme.spacing(1, 0),
      textAlign: 'center'
    },
  }),
);

interface Props {
  user: CognitoUser;
}

export default function EnterMfaForm({user}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();
  const authentication = useAuthContext();

  const [code, setCode] = useState('');

  async function submitEnterCode(event: any) {
    event.preventDefault();
    if (code.length === 6)
      await handleCodeSubmit(code);
    else
      enqueueSnackbar('The code must be at least 6 characters long.');
  }

  async function handleCodeSubmit(code: string) {
    try {
      if (authentication.type === 'cognito') {
        const result = await authentication.enterMfa(user, code);
        if (result.result === 'SUCCESS') {
          history.push('/dashboard');
        }
      }
    } catch (err) {
      enqueueSnackbar((err as Record<string, string>).message);
    }
  }

  return (
    <form onSubmit={submitEnterCode} noValidate autoComplete="off" data-testid={'enter-code-form'}>
      <Typography variant="h5" className={`${classes.title} ${classes.item}`}>
        Enter the code you received via email
      </Typography>

      <FormControl fullWidth className={`${classes.textField} ${classes.item}`} variant="outlined">
        <CoyoInputLabel htmlFor="standard-adornment-code">Code</CoyoInputLabel>
        <CoyoInputField fullWidth
                        type="text"
                        inputProps={{maxLength: 6}}
                        value={code}
                        onChange={change => setCode(change.target.value)}
                        id="code"
                        data-testid={'code-input'}
                        labelWidth={60}/>
      </FormControl>

      <Button type="submit" className={`${classes.button} ${classes.item}`} variant="contained"
              color="primary" data-testid={'mfa-code-submit'}>Submit code</Button>
    </form>
  );
}
