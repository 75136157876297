import { useState } from 'react';

function useStorage<T>(storage: Storage, key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue] as [T, (value: T) => void];
}

export function useSessionStorage<T>(key: string, initialValue: T) {
  return useStorage<T>(window.sessionStorage, key, initialValue);
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  return useStorage<T>(window.localStorage, key, initialValue);
}