import { Box } from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import { Chart, ChartData, ChartDataset, ChartEvent, DefaultDataPoint, FontSpec, Tooltip, TooltipPositionerFunction } from 'chart.js';
import annotationPlugin, { AnnotationOptions, EventContext, LineAnnotationOptions } from 'chartjs-plugin-annotation';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { compareIsoStrings, formatDateLong, generateIsoDates, toIsoFormatLuxon } from '../../charts/DateUtils';
import { useDateRangeContext } from '../../date-range-selection/DateRangeContext';
import { TimeValue } from '../../schema';
import { FallbackTable } from '../fallback-table/FallbackTable';
import { useSettingsContext } from '../../settings/SettingsContext';
import { useAppContext } from '../../CoyoAnalyticsTheme';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import { getLineChartOptions } from './LineChartOptions';

declare module 'chart.js' {
  // Extend tooltip positioner map
  interface TooltipPositionerMap {
    top: TooltipPositionerFunction<ChartType>;
  }
}

export type Group = 'day' | 'week' | 'month'

interface Props {
  series: Series[];
  group: Group;
  loading: boolean;
  threshold?: boolean;
}

export const EMPTY_SERIES = { day: [], week: [], month: [] };

export interface Series {
  name: string;
  dataByRange?: {
    day: TimeValue[];
    week: TimeValue[];
    month: TimeValue[];
  };
}

interface ExtendedChartData extends ChartData {
  datasets: ExtendedChartDataset[];
}

export interface ExtendedChartDataset extends ChartDataset<'line'> {
  label: string,
  data: ExtendedLineDataPoint[];
  loading?: boolean;
}

export interface ExtendedLineDataPoint extends DefaultDataPoint<'line'> {
  x: number;
  y: number;
  dateLong: string;
  dotted: boolean;
}

export const notAvailablePlaceHolder = 'N/A';

export default function LineChart(props: Readonly<Props>) {
  const ref = useRef<Chart>() as MutableRefObject<Chart>;
  const theme: Theme = useTheme();
  const { startDate, endDate } = useDateRangeContext();
  const { darkMode } = useAppContext();
  const { minGroupSize } = useSettingsContext();
  const { threshold, series, group, loading } = props;
  const labels = generateIsoDates(startDate, endDate, group);
  const [chartDataset, setChartDataset] = useState<ExtendedChartDataset[]>(series.map(series => toChartDataset(series, labels, group, loading)));

  useEffect(() => {
    setChartDataset(series.map(series => toChartDataset(series, labels, group, loading)));
    // eslint-disable-next-line
  }, [loading, group, series]);

  const data: ExtendedChartData = {
    datasets: chartDataset,
  };

  useEffect(() => {
    applyChartTheme(ref, data, minGroupSize, darkMode, threshold);
    // eslint-disable-next-line
  }, [loading, darkMode, group, ref]);

  const fallbackContent = <FallbackTable
    headerRowCount={1}
    headerRow={data.datasets.some(dataset => !!dataset.data.length) ?
      [group, ...data.datasets.filter(dataset => !!dataset.data.length)
        .map(dataset =>
          dataset.label
        )
      ] : []
    }
    contentRows={data.datasets.some(dataset => !!dataset.data.length) ?
      data.datasets
        .filter(dataset => !!dataset.data.length)[0].data
        .map((datasetData, index) =>
          [datasetData.dateLong, ...data.datasets
            .filter(dataset => !!dataset.data.length)
            .map(dataset => {
              const value = dataset.data[index].y;
              if (threshold && !value) return notAvailablePlaceHolder;
              return value.toString();
            })
          ]
        ) :
      [[]]
    } />;

  const minGroupSizeConfig: AnnotationOptions = {
    type: 'line',
    yMin: minGroupSize,
    yMax: minGroupSize,
    borderColor: theme.palette.text.primary,
    borderWidth: 2,
    borderDash: [10, 5],
    label: {
      content: `Values below this line will not be shown`,
      enabled: false,
      position: 'end',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      width: 5,
      font: { weight: 'normal' } as FontSpec,
      borderWidth: 1,
    },
    enter(ctx, event) {
      toggleLabel(ctx, event);
    },
    leave(ctx, event) {
      toggleLabel(ctx, event);
    },
  };

  const updateChartOnLabelToggle = () => {
    if (!loading) {
      ref.current?.update();
    }
  };

  const options = getLineChartOptions(theme, threshold, group, minGroupSize, minGroupSizeConfig, notAvailablePlaceHolder, updateChartOnLabelToggle);

  Chart.register(annotationPlugin);

  registerTooltipPositioners(ref);

  return (
    <Box>
      <Line ref={ref as MutableRefObject<Chart<'line'>>}
        data={data}
        options={options}
        height={350}
        aria-hidden={true}
        data-testid={'line-chart'} />
      {fallbackContent}
    </Box>
  );
}

export function toChartDataset(series: Series, labels: string[], group: Group, loading: boolean): ExtendedChartDataset {
  const data = series.dataByRange?.[group]
  if (!data) {
    return {
      label: series.name,
      data: [],
      loading,
    };
  }

  const seriesData: TimeValue[] = [...data];
  const transformedSeries: ExtendedLineDataPoint[] = labels
    .map(label => {
      const labelIndex = seriesData.findIndex(series =>
        compareIsoStrings(label, series.isoDate)
      );

      return {
        x: new Date(label).getTime(),
        y: !!data.length && !!(labelIndex + 1) ? seriesData.splice(labelIndex, 1)[0].value : 0,
        dateLong: formatDateLong(label, group),
        dotted: toIsoFormatLuxon(DateTime.now(), group) === label,
      } as ExtendedLineDataPoint
    }
    );
  return {
    label: series.name,
    data: transformedSeries,
    loading: loading || !data.length,
  };
}

function applyChartTheme(chart: MutableRefObject<Chart>, data: ChartData, minGroupSize: number, darkMode: boolean, threshold = false): void {
  const colors = darkMode
    ? ['#2693FF', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A'] // dark
    : ['#0073E6', '#FF8F73', '#B366FF', '#73B9FF', '#775DD0']; // light
  const datasets = data.datasets.map((element, index) => {
    const pointRadius = element.data.map((dataElement) =>
      threshold && ((dataElement as ExtendedLineDataPoint).y < minGroupSize) ? 0 : 2
    );

    const pointHoverRadius = element.data.map((dataElement) =>
      threshold && ((dataElement as ExtendedLineDataPoint).y < minGroupSize) ? 0 : 3
    );

    return {
      ...element,
      backgroundColor: colors[index],
      borderColor: colors[index],
      pointRadius,
      pointHoverRadius,
    };
  });

  if (!chart.current)
    return;

  chart.current.data.datasets = datasets;
  if (chart.current.options.plugins?.legend?.onClick)
    chart.current.options.plugins.legend.onClick = () => {
    };

  chart.current.update();
}

function toggleLabel(ctx: EventContext, event: ChartEvent) {
  const chart: Chart = ctx.chart;
  const annotationOpts: LineAnnotationOptions = (chart?.options?.plugins?.annotation?.annotations as Record<string, AnnotationOptions<'line'>>)['minGroupSize'];
  if (annotationOpts.label) {
    annotationOpts.label.enabled = !annotationOpts.label.enabled;
    annotationOpts.label.position = ((event?.x || 0) / ctx.chart.chartArea.width * 100) > 50 ? 'start' : 'end';
    chart.update();
  }
}

function registerTooltipPositioners(ref: MutableRefObject<Chart>) {
  Tooltip.positioners.top = (items, currentPosition) => {
    // @ts-ignore
    const averagePosition = Tooltip.positioners.average(items, currentPosition);
    const chart = ref.current;

    const isHeightAbove50Percent = chart?.chartArea?.height ? ((currentPosition.y / chart.chartArea.height * 100) > 50) : 0;
    return !!averagePosition && chart?.chartArea
      ? {
        x: averagePosition.x,
        y: isHeightAbove50Percent ? chart.chartArea.top : chart.chartArea.bottom,
        yAlign: isHeightAbove50Percent ? 'top' : 'bottom',
      }
      : false;
  };
}
